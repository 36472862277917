import React, { Component } from "react";
import Form from "../../common/form";

class MedGroupList extends Form {
  constructor(props) {
    super(props);
    this.state = {
      dropDownToggled: false,
      selectedGroup: "Others",
    };
  }

  handleselectedGroup = () => {
    const data = this.state.data;
    this.props.selectedGroup(data.dropDownList);
  };

  handleClick = (e) => {
    this.toggleDropdown();
    this.setState({ selectedGroup: e.name });
    this.props.selectedGroup(e.name);
  };

  toggleDropdown = () => {
    this.setState({
      dropDownToggled: this.state.dropDownToggled ? false : true,
    });
  };

  render() {
    const { dropDownList, defaultValue, selectedGroup } = this.props;
    // const data = this.state.data;
    // if (typeof data !== "undefined") {
    //   this.handleselectedGroup();
    // }
    return (
      <div className="dropdown">
        <button
          className={
            this.state.selectedGroup == "Others"
              ? "btn btn-secondary dropdown-toggle"
              : "btn btn-secondary dropdown-toggle"
          }
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          onClick={this.toggleDropdown}
          style={{ padding: "0.75rem 1.25rem" }}
        >
          {this.state.selectedGroup}
        </button>
        <div
          className={
            this.state.dropDownToggled ? "dropdown-menu show" : "dropdown-menu"
          }
          style={{
            minWidth: "fit-content",
          }}
          aria-labelledby="dropdownMenuButton"
        >
          {dropDownList.map((i) => (
            <a
              key={i._id}
              className="dropdown-item"
              href="#"
              onClick={() => this.handleClick(i)}
              style={{
                padding: "0.75rem 1.25rem",
              }}
            >
              {i.name}
            </a>
          ))}
        </div>
      </div>
    );
  }
}

export default React.memo(MedGroupList);
