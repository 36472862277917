import React, { Component } from "react";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorMsg: "" };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, errorMsg: error.message };
  }

  componentDidCatch(error, errorInfo) {
    // You rcan also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    console.log("Error Boundry Error Log: ");
    console.log(error);
    console.log(errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div
          style={{
            display: "flex",
            backgroundColor: "tomato",
            overflow: "scroll",
          }}
        >
          <h5>Error: {this.state.errorMsg}</h5>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
