import React, { Component } from "react";
import { ToastContainer } from "react-toastify";
import Navbar from "./components/Navbars/navbar";
import { Switch, Route, Redirect } from "react-router-dom";
import auth from "./services/authService";
import Logout from "./components/User/Logout";
import LoginPage from "./components/User/loginPage";
import RegisterPage from "./components/User/registerPage";
import USBPrint from "./components/BillPrints/usbPrint";
import BLEPrint from "./components/BillPrints/blePrint";
import awaitingAuthorization from "./components/User/awaitingAuthorization";
import Patients from "./components/Patients/patients";
import LoadingSpinner from "./components/common/loadingSpinner";

// import DashBoard from "./components/medicalDashboard/dashboardMedical";
// import GridDashboard from "./components/GridDashboard";
import NewDashboard from "./components/Dashboard/NewDashboard";
import "react-toastify/dist/ReactToastify.css";
import "./assets/css/mednetStyles.css";

class App extends Component {
  constructor() {
    super();
    this.AppRef = React.createRef();
  }
  state = {
    rolesToMedical: ["Admin", "Pharmasist"],
    loading: false,
  };

  getUser() {
    return auth.getCurrentUser();
  }
  // const [loading, setLoading] = useState(true);
  // useEffect(() => {
  //   // Loading function to load data or
  //   // fake it using setTimeout;
  //   const loadData = async () => {
  //     // Wait for two second
  //     await new Promise((r) => setTimeout(r, 300));
  //     // Toggle loading state
  //     setLoading((loading) => !loading);
  //   };
  //   loadData();
  // }, []);

  render() {
    const user = this.getUser();
    return (
      <React.Fragment>
        <ToastContainer />
        <LoadingSpinner loading={this.state.loading} />

        <div className="containerOfPage" ref={this.AppRef}>
          <main className="container-fluid pb-2">
            <Navbar user={user} />
          </main>
          <div className="containerOfBody" id="BodyContainer">
            {user === null ? (
              <Switch>
                <Route path="/login" component={LoginPage} />
                <Route path="/register" component={RegisterPage} />
                <Redirect from="/" exact to="/login" />
                <Redirect to="/login" />
              </Switch>
            ) : user.role === undefined || user.role === null ? (
              <Switch>
                <Route path="/logout" component={Logout} />
                <Route
                  path="/awaitingAuthorization"
                  component={awaitingAuthorization}
                />
                <Redirect from="/" exact to="/login" />
                <Redirect to="/awaitingAuthorization" />
              </Switch>
            ) : this.state.rolesToMedical.includes(user.role) ? (
              <Switch>
                {/* <Route path="/dashboard" component={DashBoard} />
                <Route path="/gridDashboard" component={GridDashboard} /> */}
                <Route path="/newDashboard" component={NewDashboard} />
                <Route path="/login" component={LoginPage} />
                <Route path="/logout" component={Logout} />
                <Route path="/register" component={RegisterPage} />
                <Route path="/USBPrint" component={USBPrint} />
                <Route path="/BLEPrint" component={BLEPrint} />
                {/* <Route path="/takePhoto" component={TakePhoto} /> */}
                {/* <Route path="/photoScan" component={PhotoScan} /> */}
                <Redirect from="/" exact to="/newDashboard" />
                <Redirect to="/newDashboard" />
              </Switch>
            ) : (
              <Switch>
                <Route path="/patients" component={Patients} />
                <Route path="/login" component={LoginPage} />
                <Route path="/logout" component={Logout} />
                <Route path="/register" component={RegisterPage} />
                <Redirect from="/" exact to="/login" />
                <Redirect to="/patients" />
              </Switch>
            )}
          </div>
          <div style={{ position: "absolute", right: 10, bottom: 0 }}>
            {process.env.REACT_APP_ENV} v{process.env.REACT_APP_VERSION}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default App;
