import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getPatientsList,
  mapToViewModelOutwards,
  mapToViewModelInwards,
} from "../../services/fakePatients";
import Pagination from "../common/pagination";
import { paginate } from "../../utils/paginate";
import AddPatient from "./addPatientForm";
import PatientsTable from "./patientsTable";
import StatusBadges from "./statusBadges";
import { toast } from "react-toastify";
import auth from "../../services/authService";
import ErrorBoundary from "../../ErrorBoundary";
import Form from "../common/form";
import {
  getPatientsInQueue,
  updatePatientStatus,
  addNewPatient,
} from "../../store/patientsDux";
import { getTodaysOrders, updateTotalCollection } from "../../store/ordersDux";
import { getFormattedDate } from "../../utils/myDateTime";
import AuthSSE from "../../services/SSE/AuthSSE";

let totalFilteredPatients = 0;
class Patients extends Form {
  constructor(props) {
    super();
    const d = new Date();
    setTimeout(() => {
      this.props.getPatientsInQueue(getFormattedDate(d));
      this.props.getTodaysOrders(getFormattedDate(d));
    }, 5000);
  }
  state = {
    allPatients: [], // use what you learn from component lifecycle here
    allPatientsSeq: [], //to maintain same seq number on filtered list
    pageSize: 5,
    currentPage: 1,
    user: auth.getCurrentUser(),
    // new filter selction option on patient queue.
    data: {
      filterByList: "Status",
      filterList: "",
    },
    filterByList: [{ _id: 1, name: "Type" }],
    defaultFilterType: { _id: 0, name: "Status" },
    statusFilterList: [
      { _id: 1, name: "InReception" },
      { _id: 2, name: "InConsulting" },
      { _id: 3, name: "Consulted" },
      { _id: 4, name: "InMedical" },
      { _id: 5, name: "OrderCreated" },
    ],
    statusDefaultFilter: { _id: 0, name: "" },
    typeFilterList: [
      { _id: 1, name: "Regular" },
      { _id: 2, name: "Emergency" },
      { _id: 3, name: "OTC" },
    ],
    typeDefaultFilter: { _id: 0, name: "" },
    renderSSE: false,
  };

  componentWillReceiveProps(props) {
    this.setState({ allPatients: props.allPatients });
    this.updatePatientSeq(props.allPatients);
    if (props.orders.list.length > 0) {
      let totalCollection = props.orders.list.reduce((cartTotal, order) => {
        return cartTotal + order.paid_amount;
      }, 0);
      props.updateTotalCollection(totalCollection);
    }
  }
  // right place to call the server and get  data
  async componentDidMount() {
    this.setState({ renderSSE: true });

    // set  default filer based on role
    let ldata = { ...this.state.data };
    switch (this.state.user.role) {
      case "Doctor":
        ldata.filterList = "InConsulting";
        this.setState({
          data: ldata,
        });
        break;
      case "Receptionist":
        ldata.filterList = "InReception";
        this.setState({
          data: ldata,
        });
        break;
    }

    // this.throwError();
    const d = new Date();
    const fdate = getFormattedDate(d);
    this.props.getPatientsInQueue(fdate);
    setTimeout(() => {
      this.props.getPatientsInQueue(fdate);
      this.props.getTodaysOrders(fdate);
    }, 10000);
  }

  updatePatientSeq(allPatients) {
    const seq = allPatients.map((p) => ({
      id: p.patient_id,
      seqNo: allPatients.indexOf(p) + 1,
    }));
    this.setState({ allPatientsSeq: seq });
  }

  handleDelete = (item) => {
    const allPatients = this.state.allPatients.filter(
      (p) => p._id !== item._id
    );
    this.setState({ allPatients }); // since key = value
  };

  addNewPatient = async (newPatient) => {
    try {
      newPatient = mapToViewModelInwards(newPatient);
      console.log("Newly added patient");
      console.log(newPatient);
      this.props.addNewPatient(newPatient);
    } catch (e) {
      toast.error("Something went wrong. Please Try Again.");
      console.log(e);
    }
  };

  handlePageChange = (page) => {
    const { pageSize, currentPage, allPatients } = this.state;

    this.setState({
      currentPage:
        page === "Next"
          ? currentPage !== Math.ceil(totalFilteredPatients / pageSize)
            ? (page = currentPage + 1)
            : (page = currentPage)
          : page === "Prev"
          ? currentPage !== 1
            ? (page = currentPage - 1)
            : (page = currentPage)
          : page,
    });
  };

  getPagedData = () => {
    const { pageSize, allPatients } = this.state;
    let currentPage = this.state.currentPage;

    //do All the filtering/sorting here
    let filteredPatients = [];
    console.log("this.state.data.filterByList ");
    console.log(this.state.data.filterByList);
    console.log("this.state.data.filterList");
    console.log(this.state.data.filterList);

    if (
      this.state.data.filterByList === "Status" &&
      this.state.statusFilterList
        .map((e) => e.name)
        .includes(this.state.data.filterList)
    )
      filteredPatients = allPatients.filter(
        (p) => p["status"] === this.state.data.filterList
      );
    else if (
      this.state.data.filterByList === "Type" &&
      this.state.typeFilterList
        .map((e) => e.name)
        .includes(this.state.data.filterList)
    )
      filteredPatients = allPatients.filter(
        (p) => p["type"] === this.state.data.filterList
      );
    else {
      filteredPatients = allPatients;
    }

    if (this.state.data.filterList === "") filteredPatients = allPatients;
    console.log(filteredPatients);

    // when selected currentPage exceeds number of pages available on filter change, go to page 1
    if (currentPage > Math.ceil(filteredPatients.length / pageSize)) {
      currentPage = 1;
    }

    const patientsOnPage = paginate(filteredPatients, currentPage, pageSize);

    return {
      filteredPatients,
      allPatients,
      currentPage,
      pageSize,
      patientsOnPage,
    };
  };
  render() {
    //Object Destructuring
    const {
      filteredPatients,
      allPatients,
      currentPage,
      pageSize,
      patientsOnPage,
    } = this.getPagedData();
    const totalPatients = allPatients.length;
    totalFilteredPatients = filteredPatients.length;
    console.log("totalPatients :");
    console.log(totalPatients);

    return (
      <div>
        <div
          style={{
            display: "flex",
            overflow: "scroll",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              overflowX: "scroll",
              width: "fit-content",
            }}
          >
            <ErrorBoundary>
              <StatusBadges />
            </ErrorBoundary>
            {/* //container-patients */}
            {this.state.user !== null ? (
              this.state.user.role === "Receptionist" ||
              this.state.user.role === "Doctor" ? (
                <AddPatient addNewPatient={this.addNewPatient} />
              ) : (
                ""
              )
            ) : (
              "Role is null"
            )}
          </div>
        </div>
        <div style={{ overflowX: "scroll" }}>
          {totalPatients === 0 ? (
            <div
              className="pt-4"
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <p>There are No patients in the list.</p>
            </div>
          ) : (
            <div>
              <div className="filterDropDown">
                {this.renderSelect(
                  "filterByList",
                  "filterWith",
                  this.state.filterByList,
                  this.state.defaultFilterType
                )}

                {this.state.data.filterByList === "Type"
                  ? this.renderSelect(
                      "filterList",
                      this.state.data.filterByList.length > 0
                        ? this.state.data.filterByList
                        : this.state.defaultFilterType.name,
                      this.state.typeFilterList,
                      this.state.typeDefaultFilter
                    )
                  : this.renderSelect(
                      "filterList",
                      this.state.data.filterByList.length > 0
                        ? this.state.data.filterByList
                        : this.state.defaultFilterType.name,
                      this.state.statusFilterList,
                      this.state.statusDefaultFilter
                    )}
              </div>
              {totalFilteredPatients === 0 ? (
                <div
                  className="pt-4"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <p>There are No Filtered patients, update your filter.</p>
                </div>
              ) : (
                <>
                  <div className="table-responsive">
                    <ErrorBoundary>
                      <PatientsTable
                        items={patientsOnPage}
                        currentPage={currentPage}
                        pageSize={pageSize}
                        allPatientsSeq={this.state.allPatientsSeq}
                      />
                    </ErrorBoundary>
                  </div>
                  <div className="paginationBlock">
                    <ErrorBoundary>
                      <Pagination
                        itemsCount={totalFilteredPatients}
                        pageSize={pageSize}
                        currentPage={currentPage}
                        onPageChange={this.handlePageChange} // Handling an event
                      />
                    </ErrorBoundary>
                  </div>
                </>
              )}
              {this.state.user.role === "Doctor" ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "40px",
                  }}
                >
                  <p>Total Collection: ₹{this.props.totalCollection}</p>
                </div>
              ) : (
                ""
              )}
            </div>
          )}
        </div>
        {this.state.renderSSE ? <AuthSSE /> : ""}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  console.log("List at map:");
  console.log(state.entities.patients.list);
  return {
    allPatients: state.entities.patients.list,
    totalCollection: state.entities.orders.totalCollection,
    orders: state.entities.orders,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addNewPatient: (patient) => dispatch(addNewPatient(patient)),
    updatePatientStatus: (updatePatient) =>
      dispatch(updatePatientStatus(updatePatient)),
    getPatientsInQueue: (date) => dispatch(getPatientsInQueue(date)),
    updateTotalCollection: (total) => dispatch(updateTotalCollection(total)),
    getTodaysOrders: (date) => dispatch(getTodaysOrders(date)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Patients);
