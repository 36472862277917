import React from "react"; // compiled code [generated by BABEL] has reference to React
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import SentryLogger from "./services/sentryLogger";
import { history } from "./store/reducer";
import { ConnectedRouter } from "connected-react-router";
import httpService from "../src/services/httpService";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

//Redux Imports
import { Provider } from "react-redux"; //it connects store to our App
import { PersistGate } from "redux-persist/integration/react";
import configureStore from "./store/configureStore";
import { persistStore } from "redux-persist";

import "./assets/css/index.css";
import "bootstrap/dist/css/bootstrap.css";
import "font-awesome/css/font-awesome.css";
//import { initStateWithPrevTab } from "redux-state-sync";
const store = configureStore();
const persistor = persistStore(store);

//to sync state across tabs
//initStateWithPrevTab(store);
httpService.getIp();
SentryLogger(); // External service to log allunexpected crashes
console.log(process.env.REACT_APP_ENV);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      {/* // this will add history object to our App */}
      <BrowserRouter>
        {/* <ConnectedRouter history={history}> */}
        <main>
          <App />
        </main>
        {/* </ConnectedRouter> */}
      </BrowserRouter>
    </PersistGate>
    ,
  </Provider>,
  document.getElementById("root")
); // first argument is an entry point of our app, second one places our app inside the root element of index.html

serviceWorkerRegistration.register();
