import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { ReactComponent as PrinterError } from "../../../images/printReceiptDisabled.svg";
import { ReactComponent as PrinterOk } from "../../../images/printReceipt.svg";
import { printedOrder, updatePrinterDevice } from "../../../store/ordersDux";
import { getCurrentDateWithSlash } from "../../../utils/myDateTime";
import { confirm } from "../../common/confirmationAlert";

let retryCount = 0;
class BLEPrintOrder extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    // connected: false,
    supportsBluetooth: false,
    // isDisconnected: true,
    // device: null,
    currentPatientToPrintDebt: "",
    otp: null,
  };

  //check if browser supports web bluetooth
  componentDidMount() {
    retryCount = 0;
    if (navigator.bluetooth) {
      this.setState({ supportsBluetooth: true });
    } else {
      this.setState({ supportsBluetooth: false });
    }
  }

  testPrint = (str) => {
    const strArry = str.split("#");
    //console.log(strArry);
    let printError = 0;
    for (let n = 0; n < strArry.length; n++) {
      // console.log(strArry[n]);
      // if (strArry[n] === "") strArry[n] = "\n\n";
      setTimeout(() => {
        var buffer = new ArrayBuffer(strArry[n].length);
        var dataView = new DataView(buffer);

        for (var i = 0; i < strArry[n].length; i++) {
          dataView.setUint8(i, strArry[n].charAt(i).charCodeAt());
        }
        // console.log(strArry[n]);
        // console.log(buffer);
        // console.log(dataView);
      }, 100 + n * 100);
    }
  };

  //searches for printer nearby
  createDevice = async () => {
    let len = this.props.orders.billReceipts.length;
    if (this.props.orders.billReceipts.length < 1) return;
    console.log("PRINT DATA");
    if (this.props.printOrderData === null) {
      retryCount = retryCount + 1;
      if (retryCount < 3)
        return setTimeout(() => {
          this.createDevice();
        }, [2000]);
      else return toast.error("No Order to print");
    }
    const orderToPrint = this.props.printOrderData;
    console.log(orderToPrint);
    const billObject = this.props.orders.billReceipts.find(
      (br) => br.orderID === orderToPrint.ID
    );

    console.log(billObject.itemsBill);
    console.log(billObject.regularBill);
    this.testPrint(billObject.itemsBill);
    const primary_service_uuid = 0x18f0;
    let device;
    let ldevice = this.props.device(); //this.state.device; //JSON.parse(localStorage.getItem("printerDevice"));
    // console.log("ldevice : ");
    // console.log(ldevice);
    try {
      if (ldevice === null) {
        device = await navigator.bluetooth.requestDevice({
          filters: [{ name: "BlueTooth Printer" }], //acceptAllDevices: true, //
          optionalServices: [primary_service_uuid],
        });
        //this.props.updatePrinterDevice(device);
        //localStorage.setItem("printerDevice", device[0].toString());

        //Event Listeners
        try {
          //setTimeout(() => this.clickButton(), 5000);
          device.addEventListener("connect", (event) =>
            this.onConnected(event)
          );

          device.addEventListener("disconnect", (event) =>
            this.onDisconnected(event)
          );
          this.setState({ supportsBluetooth: true });
        } catch (e) {
          toast.error("BLE Error line 103: " + e.message);
          this.setState({ supportsBluetooth: false });
        }
        // this.setState({ isDisconnected: false }); //,device
        this.props.setIsDisconnected(false);

        this.props.setDevice(device);
      } else {
        device = ldevice; //this.state.device;

        // console.log("Device Exist");
        // console.log(ldevice);
      }

      // Add an event listener to detect when a device disconnects
      try {
        device.addEventListener("gattserverdisconnected", this.onDisconnected);
      } catch (e) {
        toast.error("BLE Error line 121" + e.message);
      }
      this.connectToDeviceAndSubscribeToUpdates(device);
    } catch (error) {
      console.log(`There was an error while creating device: ${error}`);
      // this.setState({ isDisconnected: true });
      this.props.setIsDisconnected(true);

      //toast.error(JSON.stringify(error.message));
      if (error.message === "Bluetooth Low Energy not available.")
        this.setState({ supportsBluetooth: false });
    }
  };
  //connect and read data
  connectToDeviceAndSubscribeToUpdates = async (device) => {
    try {
      // this.setState({ isDisconnected: false });
      // this.props.setIsDisconnected(false);

      // Search for Bluetooth devices that advertise a battery service
      const primary_service_uuid = 0x18f0;
      const char_uuid = 0x2af1;

      const BLUETOOTH_SPP_UUID = "00001101-0000-1000-8000-00805f9b34fb";
      //let device = this.state.device;

      // Try to connect to the remote GATT Server running on the Bluetooth device
      const server = await device.gatt.connect();

      // Get the battery service from the Bluetooth device
      const service = await server.getPrimaryService(primary_service_uuid);
      // Get the battery level characteristic from the Bluetooth device
      const characteristic = await service.getCharacteristic(char_uuid);
      console.log("at connect this.props.isDisconnected");
      console.log(this.props.isDisconnected());

      setTimeout(() => this.printData(characteristic), 500);
    } catch (error) {
      this.props.setIsDisconnected(true);
      this.props.setDevice(null);

      console.log(`BLE Error line 160: ${error}`);

      toast.error("Printer Disconnected, Check Bluetooth: ");
    }
  };

  onDisconnected = (event) => {
    //alert(`The device ${JSON.stringify(event)} is disconnected`);
    console.log("this.state line 156");
    console.log(JSON.stringify(this.state));
    toast.error("printer disconnected");
    // this.setState({ isDisconnected: true });
    this.props.setIsDisconnected(true);
    this.props.printedOrder();
    this.props.orderPrinted();
  };

  onConnected = (event) => {
    toast.success(`printer connected`);
    // this.setState({ isDisconnected: false });
    this.props.setIsDisconnected(false);
  };

  printData = (characteristic) => {
    if (this.props.printOrderData === null)
      return toast.error("No Order to print");
    const orderToPrint = this.props.printOrderData;
    let len = this.props.orders.billReceipts.length;
    const billObject = this.props.orders.billReceipts.find(
      (br) => br.orderID === orderToPrint.ID
    );
    //console.log("PRINT DATA");
    //console.log(this.props.orders.billReceipts[len - 1].itemsBill);
    // console.log(this.props.orders.billReceipts[0].regularBill);
    if (len > 0) {
      this.testPrint(billObject.itemsBill);
      //let str = "a\n"; //`CaseNumber  Date  Time\n___________________\nName |      Amount|\nitem |       $50.0|\n\n`;
      // toast.info(
      //   `Total bill receipts: ${this.props.orders.billReceipts.length}`
      // );
      console.log("this.props.isDisconnected");
      console.log(this.props.isDisconnected());

      if (
        characteristic !== null &&
        characteristic !== undefined &&
        this.props.isDisconnected() !== true
      ) {
        if (orderToPrint.ID === undefined)
          return toast.error("No Order to print");

        if (billObject === undefined)
          return toast.info("Please Try Again, BillObject Undefined!!");
        //console.log(billObject);
        let str = "EMPTY\n\n";
        //check which format to print (default cartTotal charge format, select for bill of items format)
        //    var r = window.confirm(`print bill of items for orderNo: ${orderId}?`);
        //console.log("str :");
        this.props.orderPrinted();
        let isCancelled = null;

        confirm({
          message: "Choose Print Options.",
          buttons: ["RegularBill", "ItemsBill"],
        }).then(
          ({ button }) => {
            isCancelled = false;
            console.log("Button Pressed: " + button);
            if (button === "RegularBill") str = "\n\n" + billObject.regularBill;
            if (button === "ItemsBill") str = "\n\n" + billObject.itemsBill;

            if (isCancelled === false) {
              const strArry = str.split("#");
              //console.log(strArry);
              let printError = 0;

              const print = (n) => {
                var buffer = new ArrayBuffer(strArry[n].length);
                var dataView = new DataView(buffer);
                console.log(strArry[n]);
                console.log(buffer);

                for (var i = 0; i < strArry[n].length; i++) {
                  dataView.setUint8(i, strArry[n].charAt(i).charCodeAt());
                }

                return characteristic.writeValueWithResponse(buffer);
              };

              (function loop(n) {
                if (n >= strArry.length) return; // all done
                print(n)
                  .then(() => {
                    loop(n + 1);
                  })
                  .catch((e) => {
                    printError = 1;
                    toast.error("BLE Error line 255: " + e.message);
                  });
              })(0);

              if (printError === 0) {
                //open cash drawer
                
                this.props.printedOrder();
                this.props.orderPrinted();
                // try{
                //   var opendrawercode = new Uint8Array([0x1b, 0x70, 0x00, 0x32, 0xfa])
                //   characteristic.writeValueWithResponse(opendrawercode)
                // }catch(e){
                //   console.error("Error while opening drawer")
                //   console.error(e)
                // }
              } else {
                console.log("this.state line 248");
                console.log(JSON.stringify(this.state));
                toast.error("printer disconnected");
                // this.setState({ isDisconnected: true });
                this.props.setIsDisconnected(true);

                this.props.printedOrder();
                this.props.orderPrinted();
              }
            }
          },
          () => {
            //cancel pressed
            isCancelled = true;
            toast.info("print cancelled");
            this.props.printedOrder();
            this.props.orderPrinted();
          }
        );
      } else {
        console.log("this.state line 266");
        console.log(JSON.stringify(this.state));
        console.log(this.props.isDisconnected());

        toast.error("printer disconnected");
        // this.setState({ device: null });
        this.props.setDevice(null);
        this.props.setIsDisconnected(true);

        navigator.usb.removeEventListener("connect");
        navigator.usb.removeEventListener("disconnect");
        this.props.printedOrder();
        this.props.orderPrinted();
      }
    } else {
      toast.info("Please Try Again!! No bills to print");
    }
  };
  getDevices = () => {
    let ldevice = this.props.device(); //this.state.device; //JSON.parse(localStorage.getItem("printerDevice"));
    console.log("ldevice at get device: ");
    console.log(ldevice);
    // console.log(typeof ldevice);
    if (ldevice === null) this.createDevice();
    else this.connectToDeviceAndSubscribeToUpdates(ldevice);
  };
  handleNotSupported = () => {
    toast.error("This device not support Bluetooth");
  };

  render() {
    let ldevice = this.props.device(); //JSON.parse(localStorage.getItem("printerDevice"));

    return (
      <div style={{ display: "flex" }}>
        {this.state.supportsBluetooth ? (
          this.props.isDisconnected() ? (
            <PrinterError
              onClick={(e) =>
                setTimeout(() => {
                  ldevice === null || ldevice === undefined
                    ? this.createDevice()
                    : this.getDevices();
                }, [1500])
              }
              className="cartIcons"
            />
          ) : (
            <PrinterOk
              onClick={(e) =>
                setTimeout(() => {
                  ldevice === null || ldevice === undefined
                    ? this.createDevice()
                    : this.getDevices();
                }, [1500])
              }
              className="cartIcons"
            />
          )
        ) : (
          <PrinterError
            onClick={(e) => this.handleNotSupported()}
            className="cartIcons unsupported"
          />
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    //device: state.entities.orders.printerDevice,
    orders: state.entities.orders,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    printedOrder: () => dispatch(printedOrder()),
    //updatePrinterDevice: (device) => dispatch(updatePrinterDevice(device)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BLEPrintOrder);
