// orderId, name, caseNumber, doctorsBill, cartTotal, previousDebit, currentDebit;
// orderPrintString: allItems, orderItems

//In Cart.jsx before creating order
// orderItems,
// patientID,
// previousDebit,
// cartTotal,
// paidAmount = cartTotal + doctorsBill + previousDebit - currentDebit

const getRegularBill = (
  date,
  orderId,
  name,
  caseNumber,
  doctorsBill,
  cartTotal,
  previousDebit,
  currentDebit
) => {
  let regularBill = "";
  if (previousDebit == 0 && currentDebit == 0) {
    regularBill = `Date:${date}  OrderId:${orderId}#\nName: ${name}#\nCaseNumber: ${caseNumber}#\n\nItem                      AMOUNT#\n\nDoctor's Bill             ${doctorsBill
      .toString()
      .padStart(
        6
      )}#\nMedicine Bill              ${cartTotal
      .toString()
      .padStart(5)}#\n\nTotal Bill Amount          ${(
      doctorsBill +
      previousDebit -
      currentDebit +
      cartTotal
    )
      .toString()
      .padStart(5)}#\n\n\n\n`;
  } else {
    regularBill = `Date:${date}  OrderId:${orderId}#\nName: ${name}#\nCaseNumber: ${caseNumber}#\n\nItem                      AMOUNT#\n\nDoctor's Bill             ${doctorsBill
      .toString()
      .padStart(
        6
      )}#\nMedicine Bill              ${cartTotal
      .toString()
      .padStart(
        5
      )}#\nPrevious Debit             ${previousDebit
      .toString()
      .padStart(
        5
      )}#\nCurrent Debit              ${currentDebit
      .toString()
      .padStart(5)}#\n\nTotal Bill Amount          ${(
      doctorsBill +
      previousDebit -
      currentDebit +
      cartTotal
    )
      .toString()
      .padStart(5)}#\n\n\n\n`;
  }

  return regularBill;
};

const getItemsBill = (
  date,
  orderId,
  name,
  orderPrintString,
  totalItems,
  cartTotal,
  doctorsBill,
  previousDebit,
  currentDebit
) => {
  let dateOrderIdStr = `Date:${date}  OrderId:${orderId}`.padEnd(32, " ");
  let nameStr = `Name: ${name}`.padEnd(32, " ");

  let totalStr = `Total Bill For ${totalItems
    .toString()
    .padStart(2)} Items:   ${cartTotal.toString().padStart(5)}`;
  let docBillStr = `Doctors Bill               ${doctorsBill
    .toString()
    .padStart(5)}`;
  let prevDebtStr = `Previous Debit             ${previousDebit
    .toString()
    .padStart(5)}`;
  let currDebtStr = `Current Debit              ${currentDebit
    .toString()
    .padStart(5)}`;
  let totalAmountStr = `Total Bill Amount          ${(
    doctorsBill +
    previousDebit -
    currentDebit +
    cartTotal
  )
    .toString()
    .padStart(5)}`;

  let itemsBill = "";
  if (previousDebit == 0 && currentDebit == 0) {
    itemsBill = `      Shri Vishnu Medical       #\n\n${dateOrderIdStr}#\n${nameStr}#\n\nItems                QTY  AMOUNT#\n${orderPrintString}#\n${totalStr}#\n${docBillStr}#\n${totalAmountStr}#\n\n\n\n`;
  } else {
    itemsBill = `      Shri Vishnu Medical       #\n\n${dateOrderIdStr}#\n${nameStr}#\n\nItems                QTY  AMOUNT#\n${orderPrintString}#\n${totalStr}#\n${docBillStr}#\n${prevDebtStr}#\n${currDebtStr}#\n${totalAmountStr}#\n\n\n\n`;
  }
  return itemsBill;
};

export default {
  getItemsBill,
  getRegularBill,
};
