import React, { Component } from "react";
import NewMedicines from "./Medicines/NewMedicines";
import NewCart from "./Cart/NewCart";
import Orders from "./Orders/Orders";
import UpcomingPatients from "./UpcomingPatients/UpcomingPatients";

import { ReactComponent as Right } from "../../images/slide_right.svg";
import { ReactComponent as Left } from "../../images/slide_left.svg";
import { ReactComponent as Up } from "../../images/drop_up.svg";
import { ReactComponent as Down } from "../../images/drop_down.svg";
import { ReactCNewCartomponent as Middle } from "../../images/middle.svg";
import ErrorBoundary from "../../ErrorBoundary";
class dashboardMedical extends Component {
  state = {
    showSlidingArrows: false,
  };
  slideDown = () => {
    console.log("Sliding...");
  };
  slideleft = () => {
    document.getElementById("BodyContainer").scrollTo({
      left: 0,
    });
  };
  slideRight = () => {
    const width =
      document.getElementById("BodyContainer").clientWidth +
      document.getElementById("ordersRef").clientWidth;
    document.getElementById("BodyContainer").scrollTo({
      left: width + 1000,
    });
  };
  alignToCenter = () => {
    document.getElementById("SearchBox").focus();
  };
  slideUp = () => {
    document.getElementById("BodyContainer").scrollTo({
      top: 0,
    });
  };
  slideDown = () => {
    const height =
      document.getElementById("BodyContainer").clientHeight +
      document.getElementById("addNewItemRef").clientWidth;
    document.getElementById("BodyContainer").scrollTo({
      top: height + 1000,
    });
  };
  render() {
    return (
      <div className="containerOfDashboard" id="DashboardRef">
        <div className="box blue">
          <NewMedicines />
          {/* <MedicinesBT /> */}
        </div>
        <div className="containerOfDashboardRightPanel">
          <div className="box green">
            <ErrorBoundary>
              <UpcomingPatients />
            </ErrorBoundary>
          </div>
          <div className="box bluish">
            <ErrorBoundary>
              <NewCart />
            </ErrorBoundary>
          </div>
          <div className="box greenish" id="ordersRef">
            <ErrorBoundary>
              <Orders />
            </ErrorBoundary>
          </div>
        </div>
        {this.state.showSlidingArrows ? (
          <div className="SlidingArrows">
            <div className="row">
              <Up onClick={this.slideUp} style={{ margin: 0 }} />
            </div>
            <div className="row">
              <Left onClick={this.slideleft} />
              <Middle onClick={this.alignToCenter} style={{ margin: 5 }} />
              <Right onClick={this.slideRight} />
            </div>
            <div className="row">
              <Down onClick={this.slideDown} />
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default dashboardMedical;
