import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button } from "react-bootstrap";

import MedGroup from "./MedGroup";
import AddRackDefinition from "./AddRackDefinition";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import { ReactComponent as DefineRacks } from "../../../images/define_rack.svg";
import { ReactComponent as AddNewRack } from "../../../images/add_new_rack.svg";

import {
  getRackDefinitions,
  getRackDefinitionsList,
} from "../../../store/racksDux";

const RackDefinitions = () => {
  //State definitions
  const [isRacksVisible, setIsRacksVisible] = useState(false);
  const [rackNames, setRackNames] = useState([]);
  const [selectedRack, setSelectedRack] = useState(null);
  const [addNewRackVisible, setAddNewRackVisible] = useState(false);
  // const [table, setTable] = useState([]);
  // const [columns, setColumns] = useState([]);
  const [rackTables, setRackTables] = useState({});

  //Hooks
  const dispatch = useDispatch();
  // const rackDefinitions = useSelector(state=>state.entities.racks.list);
  const rackDefinitions = useSelector(getRackDefinitionsList);
  //lifecycle methods like component did mount are all called with useEffect
  useEffect(() => {
    console.log("INSIDE USE EFFECT:  ");

    if (rackDefinitions.length > 0) transformTable();
    else {
      setTimeout(() => dispatch(getRackDefinitions()), 5000);
      setTimeout(() => transformTable(), 15000);
    }
  }, [rackDefinitions]);

  //functions
  const showRacks = () => {
    //console.log("Showing Racks");
    //console.log(rackDefinitions)
    setIsRacksVisible(true);
    if (rackDefinitions.length > 0) transformTable();
    else {
      dispatch(getRackDefinitions());
      setTimeout(() => transformTable(), 5000);
    }

    // getRackDefinitions();
  };
  const hideRacks = () => {
    console.log("Hidden Racks");
    setIsRacksVisible(false);
  };
  const showAddNewRackOption = () => {
    setAddNewRackVisible(addNewRackVisible ? false : true);
  };
  const handleGroupSelect = (item) => {
    setSelectedRack(item);
  };

  const transformTable = () => {
    //console.log("rackDefinitions : ");
    //console.log(rackDefinitions);
    let rack_names = rackDefinitions.map((rd) => rd.rack_name);
    let rackNames = [...new Set(rack_names)];
    if (rackNames.length > 0) setRackNames(rackNames);

    //Do this for Every Rack and store in state
    rackNames.forEach((rn) => {
      let rack_data = rackDefinitions.filter((rd) => rd.rack_name === rn);
      // let rack_data= rackDefinitions
      let table = [];
      let rack_rows = rack_data.map((rd) => rd.rows_name);
      rack_rows = [...new Set(rack_rows)];
      //console.log(rack_rows);
      let rack_columns = rack_data.map((rd) => rd.columns_name);
      rack_columns = [...new Set(rack_columns)];
      //console.log(rack_columns);

      for (let i = 0; i < rack_rows.length; i++) {
        let tr = [];
        const rows = rack_data.filter((rd) => rd.rows_name === rack_rows[i]);
        //console.log(rows);
        let obj = {};
        for (let j = 0; j < rows.length; j++) {
          obj[rack_columns[j]] = rows[j].box_name;
        }
        table.push({
          ROW: rack_rows[i],
          ...obj,
        });
      }

      rack_columns = ["ROW", ...rack_columns];
      let columns = [
        ...rack_columns.map((c) => ({
          Header: () => <b>{c}</b>,
          accessor: c,
          show: true,
          style: { textAlign: "center" },
          //width: getColumnWidth(filteredPatients, `${c}`, `${c}`),
        })),
      ];
      //   let rackTables= rackTables;
      // ...rackTables,[rn]:{table,columns}]
      rackTables[rn] = { ["table"]: table, ["columns"]: columns };
      setRackTables(rackTables);
      setSelectedRack(selectedRack === null ? rackNames[0] : selectedRack);
    });
  };

  return (
    <div>
      <DefineRacks
        onClick={showRacks}
        style={{
          cursor: "pointer",
          onMouseOver: "this.style.color = 'red'",
        }}
      />
      <div className="static-modal">
        <Modal
          show={isRacksVisible}
          keyboard={true}
          onHide={hideRacks}
          centered
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Rack Definitions
            </Modal.Title>
            <AddNewRack
              width="40px"
              height="40px"
              style={{ marginLeft: "10px" }}
              onClick={showAddNewRackOption}
            />
          </Modal.Header>
          <Modal.Body>
            {addNewRackVisible ? <AddRackDefinition /> : ""}
            {rackDefinitions.length > 0 ? (
              <div>
                {rackNames.length > 0 ? (
                  <MedGroup
                    items={rackNames}
                    selectedItem={
                      selectedRack === null ? rackNames[0] : selectedRack
                    }
                    onGroupSelect={handleGroupSelect}
                    id="MedGroupRef"
                  />
                ) : (
                  ""
                )}
                <ReactTable
                  data={
                    rackTables[selectedRack] !== undefined
                      ? rackTables[selectedRack].table
                      : []
                  }
                  columns={
                    rackTables[selectedRack] !== undefined
                      ? rackTables[selectedRack].columns
                      : []
                  }
                  className="-striped -highlight"
                  showPagination={false}
                  minRows={0}
                  getTdProps={(state, rowInfo, column, instance) => {
                    return {
                      onClick: (e, handleOriginal) => {
                        if (rowInfo !== undefined) {
                          //console.log(rowInfo.original);
                        }
                        if (handleOriginal) {
                          handleOriginal();
                        }
                      },
                    };
                  }}
                />
              </div>
            ) : (
              <p>No Rack Definitions to show</p>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={hideRacks}>Cancel</Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default React.memo(RackDefinitions);
