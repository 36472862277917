import React, { Component } from "react";

class awaitingAuthorization extends Component {
  state = {};
  componentDidMount() {}
  render() {
    return (
      <div>
        <p>Awaiting Authorization...</p>
      </div>
    );
  }
}

export default awaitingAuthorization;
