import React, { Component } from "react";
import { connect } from "react-redux";
import Counter from "../common/counter";
import { getCurrentDate } from "../../utils/myDateTime";
class StatusBadges extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    todaysDate: "",
    allPatients: [],
    isShowing: 0,
    eRegular: 0,
    eEmergency: 0,
    eOTC: 0,
    eNew: 0,
    uRegular: 0,
    uEmergency: 0,
    uOTC: 0,
    uNew: 0,
    newUpcoming: 0,
    oldUpcoming: 0,
    examined: 0,

    //0: Upcoming
    //1: Examined
    //2: All
  };

  // throwError = () => {
  //   throw new Error("Chcking Error Boundary");
  // };
  componentDidMount = () => {
    // this.throwError();
    let currentDate = getCurrentDate();
    const todaysDate = currentDate.slice(0, 10);
    this.setState({ todaysDate });
    setInterval(
      () =>
        this.setState({ allPatients: this.props.allPatientsList }, () =>
          this.updateData()
        ),
      5000
    );
  };

  updateData = () => {
    let t = new Date().toTimeString();
    //console.log("InComponent Will Receive props at: " + t);
    const { allPatients, todaysDate } = this.state;
    let uRegular = 0,
      uEmergency = 0,
      uOTC = 0,
      newUpcoming = 0,
      oldUpcoming = 0,
      eRegular = 0,
      eEmergency = 0,
      eOTC = 0,
      eNew = 0,
      uNew = 0,
      examined = 0;

    let newCases = 0,
      oldCases = 0;
    //console.log(todaysDate);
    allPatients.forEach((p) => {
      let tcase = "";
      switch (
        p.creation_date //creation_date(pin):"20210103"
      ) {
        case todaysDate:
          switch (p.status) {
            //Upcoming
            case "InReception":
              switch (p.type) {
                case "Regular":
                  uRegular++;
                  break;
                case "Emergency":
                  uEmergency++;
                  break;
                case "OTC":
                  break;
              }
              //console.log("InReception: " + p.type);
              newUpcoming++;
              uNew = newUpcoming;
              //console.log("newUpcoming: " + newUpcoming);
              break;

            case "Consulted":
              switch (p.type) {
                case "Regular":
                  uRegular++;
                  break;
                case "Emergency":
                  uEmergency++;
                  break;
                case "OTC":
                  uOTC++;
                  break;
              }
              //console.log("WithDoctor: " + p.type);
              newUpcoming++;
              uNew = newUpcoming;
              //console.log("newUpcoming: " + newUpcoming);
              break;
            //Examined
            case "OrderCreated":
              switch (p.type) {
                case "Regular":
                  eRegular++;
                  break;
                case "Emergency":
                  eEmergency++;
                  break;
                case "OTC":
                  eOTC++;
                  break;
              }
              //console.log("OrderCreated : " + p.type);
              examined++;
              break;
            case "InMedical":
              switch (p.type) {
                case "Regular":
                  eRegular++;
                  break;
                case "Emergency":
                  eEmergency++;
                  break;
                case "OTC":
                  eOTC++;
                  break;
              }
              //console.log("InMedical : " + p.type);
              examined++;
              break;
          }

          newCases++;
          break;
        default:
          switch (p.status) {
            //Upcoming
            case "InReception":
              switch (p.type) {
                case "Regular":
                  uRegular++;
                  break;
                case "Emergency":
                  uEmergency++;
                  break;
                case "OTC":
                  break;
              }
              ////console.log("InReception: " + p.type);
              oldUpcoming++;
              break;

            case "Consulted":
              switch (p.type) {
                case "Regular":
                  uRegular++;
                  break;
                case "Emergency":
                  uEmergency++;
                  break;
                case "OTC":
                  uOTC++;
                  break;
              }
              ////console.log("WithDoctor: " + p.type);
              oldUpcoming++;
              break;
            //Examined
            case "OrderCreated":
              switch (p.type) {
                case "Regular":
                  eRegular++;
                  break;
                case "Emergency":
                  eEmergency++;
                  break;
                case "OTC":
                  eOTC++;
                  break;
              }
              ////console.log("OrderCreated : " + p.type);
              examined++;
              break;
            case "InMedical":
              switch (p.type) {
                case "Regular":
                  eRegular++;
                  break;
                case "Emergency":
                  eEmergency++;
                  break;
                case "OTC":
                  eOTC++;
                  break;
              }
              ////console.log("InMedical : " + p.type);
              examined++;
              break;
          }
          oldCases++;
          //console.log("OldCases:" + oldCases);
          break;
      }
    });
    this.setState({
      newCases,
      oldCases,
      eRegular,
      eEmergency,
      eOTC,
      eNew: newCases - uNew,
      uRegular,
      uEmergency,
      uOTC,
      uNew,
      newUpcoming,
      oldUpcoming,
      examined,
    });
  };

  onDisplay = ["Upcoming", "Examined", "All"];

  handleclick = () => {
    let { isShowing } = this.state;
    isShowing = isShowing + 1;
    if (isShowing === 3) isShowing = 0;
    this.setState({ isShowing });
  };

  getCount = (countOf) => {
    const { isShowing } = this.state;
    const { allPatientsList } = this.props;

    switch (countOf) {
      case "Total":
        return this.fromCategory(countOf, this.onDisplay[isShowing]);
      case "Regular":
        return this.fromCategory(countOf, this.onDisplay[isShowing]);
      case "Emergency":
        return this.fromCategory(countOf, this.onDisplay[isShowing]);
      case "OTC":
        return this.fromCategory(countOf, this.onDisplay[isShowing]);
      case "New":
        return this.fromCategory(countOf, this.onDisplay[isShowing]);
    }
  };

  fromCategory = (countOf, category) => {
    const { allPatientsList } = this.props;
    const {
      uRegular,
      uEmergency,
      uNew,
      uOTC,
      eRegular,
      eEmergency,
      eNew,
      eOTC,
      newUpcoming,
      oldUpcoming,
      examined,
      newCases,
    } = this.state;
    switch (countOf) {
      case "Total":
        switch (category) {
          case "Upcoming":
            return oldUpcoming + newUpcoming;
          case "Examined":
            return examined;
          case "All":
            return allPatientsList.length;
        }

      case "Regular":
        switch (category) {
          case "Upcoming":
            return uRegular;
          case "Examined":
            return eRegular;
          case "All":
            return uRegular + eRegular;
        }

      case "Emergency":
        switch (category) {
          case "Upcoming":
            return uEmergency;
          case "Examined":
            return eEmergency;
          case "All":
            return uEmergency + eEmergency;
        }

      case "OTC":
        switch (category) {
          case "Upcoming":
            return uOTC;
          case "Examined":
            return eOTC;
          case "All":
            return uOTC + eOTC;
        }
      case "New":
        switch (category) {
          case "Upcoming":
            return uNew;
          case "Examined":
            return eNew;
          case "All":
            return newCases;
        }
    }
  };

  render() {
    const { isShowing } = this.state;

    return (
      <div
        style={{
          justifyContent: "center",
          display: "flex",
          overflow: "scroll",
          width: "fit-content",
        }}
      >
        <div className="statusBadge pl-5 ml-5">
          {/*  style={{ minWidth: "485px" }} */}
          {/* row m-1 pl-2  */}
          <span onClick={this.handleclick} className="mr-2">
            <Counter
              //count={this.getCount()}
              title="Showing"
              count={this.onDisplay[isShowing]}
              classType="info"
              width="100px"
            />
          </span>

          <Counter
            count={this.getCount("Total")}
            title="Total"
            classType="warning mr-2"
          />

          <Counter
            count={this.getCount("Regular")}
            title="Regular"
            classType="dark"
          />

          <Counter
            count={this.getCount("Emergency")}
            title="Emerg."
            classType="danger"
          />

          <Counter count={this.getCount("OTC")} title="OTC" classType="info" />
          <Counter
            count={this.getCount("New")}
            title="New"
            classType="success"
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    allPatientsList: state.entities.patients.list,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(StatusBadges);
