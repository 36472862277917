import { combineReducers } from "redux";
import entitiesReducers from "./entities";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";

export const history = createBrowserHistory();

export default combineReducers({
  entities: entitiesReducers,
  // router: connectRouter(history),
});
