import React, { Component } from "react";

class ElemComponent extends Component {
  //   constructor(props) {
  //     super(props);
  //   }
  render() {
    let currentDate = this.props.selectedDate;

    return (
      <button
        className="form-control"
        onClick={this.props.handleCalenderClick}
        ref={this.props.innerRef}
      >
        {currentDate}
      </button>
    );
  }
}

export default React.forwardRef((props, ref) => (
  <ElemComponent innerRef={ref} {...props} />
));
