import React, { Component } from "react";
import { connect } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import {
  getFormattedDate,
  getCurrentDate,
  extractTime,
  getCurrentDateWithDash,
} from "../../utils/myDateTime";
import { getOrderItems, empty_orders_list } from "../../store/ordersDux";
import {
  getPatientsInQueue,
  updatePatientStatus,
} from "../../store/patientsDux";
import auth from "../../services/authService";

class PatientsTable extends Component {
  constructor(props) {
    super();
  }
  state = {
    columns: [""],
    allOrders: [],
    doctorFees: "",
    user: auth.getCurrentUser(),
  };

  componentWillReceiveProps(props) {
    if (props.orders.list.length > 1) {
      this.setState({ allOrders: props.orders.list.map((ao) => ao.name) });
    }
  }
  // right place to call the server and get  data
  async componentDidMount() {
    const d = new Date();
    if (this.props.orders.list.length > 0)
      if (
        getFormattedDate(this.props.orders.list[0].order_date) !==
        getFormattedDate(d)
      )
        this.props.empty_orders_list();
  }
  getOrderData = (row) => {
    console.log(row);
    const orderList = this.props.orders.list;
    if (orderList.length > 0) {
      const index = orderList.findIndex((ol) => ol.name == row.name);
      if (index > -1)
        return [orderList[index].order_id, orderList[index].paid_amount];
      else return [0, 0];
    } else return [0, 0];
  };

  fetchOrderItems = (row) => {
    const orderID = this.getOrderData(row)[0];
    this.props.getOrderItems(orderID);
  };

  showOrderItems = (row) => {
    const columns = [
      {
        dataField: "item_name",
        text: "Item",
        headerStyle: { width: "100px" },
      },
      {
        dataField: "order_qty",
        text: "QTY",
        headerStyle: { width: "50px" },
      },
      {
        dataField: "unit_price",
        text: "PPT",
        headerStyle: { width: "50px" },
      },
    ];

    if (this.props.orderItems !== undefined) {
      if (
        this.props.orderItems.findIndex(
          (oi) => oi.order_id === this.getOrderData(row)[0]
        ) > -1
      )
        return (
          <BootstrapTable
            className="table"
            keyField="item_name"
            data={this.props.orderItems}
            columns={columns}
          />
        );
    }
  };

  renderHandleWithMe = (row) => {
    return (
      <button
        className="btn btn-info btn-lg btn-block"
        onClick={() => this.props.handleWithMeClick(row)}
      >
        {row.name} is With Me!!
      </button>
    );
  };

  renderAddDoctorConsultingFees = (row) => {
    let { doctorFees, doctorFeesError } = this.state;
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          // justifyContent: "space-around",
          // justifyItems: "space-around",
        }}
      >
        <label htmlFor={"doctorFees"} style={{ marginRight: "5px" }}>
          <b>{"Doctor Fees:"}</b>
        </label>
        <input
          type={"text"} // e.g: text/ password
          name={"doctorFees"} // e.g: username/ password
          onChange={(e) => this.handleDoctorFeesChange(e)} // tracks changes goes with value property
          value={doctorFees} //to store data object
          autoFocus={true}
          style={{ width: "100px", marginRight: "15px" }}
        ></input>
        <button
          className="btn btn-info"
          //style={{ height: "fit-content" }}
          onClick={() => this.handleConsultingFeeAddClick(row)}
        >
          Add Consulting Fees
        </button>
      </div>
    );
  };

  sendForDoctorConsulting = (row) => {
    // let { doctorFees, doctorFeesError } = this.state;
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          // justifyItems: "space-around",
        }}
      >
        {/* below commented adds doctor fees */}
        {/* <label htmlFor={"doctorFees"} style={{ marginRight: "5px" }}>
          <b>{"Doctor Fees:"}</b>
        </label>
        <input
          type={"text"} // e.g: text/ password
          name={"doctorFees"} // e.g: username/ password
          onChange={(e) => this.handleDoctorFeesChange(e)} // tracks changes goes with value property
          value={doctorFees} //to store data object
          autoFocus={true}
          style={{ width: "100px", marginRight: "15px" }}
        ></input> */}
        <button
          className="btn btn-info"
          //style={{ height: "fit-content" }}
          onClick={() => this.handleSendToConsultingClick(row)}
        >
          Send For Doctor Consulting
        </button>
      </div>
    );
  };

  handleDoctorFeesChange = (e) => {
    this.setState({ doctorFees: e.currentTarget.value });
  };
  handleConsultingFeeAddClick = (row) => {
    // console.log("row :");
    // console.log(row);
    const case_number = row.case_number.props.children.props.children; //due to added badge around caseNumber
    const doctor_fees = this.state.doctorFees;
    let newRow = { ...row, case_number, doctor_fees };
    delete newRow.seqNo;
    newRow.status = "Consulted"; //"WithDoctor";
    newRow.arrival_time = getCurrentDateWithDash() + " " + newRow.arrival_time;

    this.props.updatePatientStatus(newRow);
    this.setState({ doctorFees: "" });

    console.log("updated Patient on handleConsultingFeeAddClick");
    console.log(newRow);
  };

  handleSendToConsultingClick = (row) => {
    // console.log("row :");
    // console.log(row);
    const case_number = row.case_number.props.children.props.children; //due to added badge around caseNumber
    // const doctor_fees = this.state.doctorFees;
    let newRow = { ...row, case_number };
    delete newRow.seqNo;
    newRow.status = "InConsulting"; //"WithDoctor";
    newRow.arrival_time = getCurrentDateWithDash() + " " + newRow.arrival_time;

    this.props.updatePatientStatus(newRow);
    // this.setState({ doctorFees: "" });

    console.log("updated Patient on handleSendToConsultingClick");
    console.log(newRow);
  };

  renderOrderDetails = (row) => {
    return (
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            justifyItems: "space-around",
          }}
        >
          <button
            className="btn btn-info btn-lg"
            onClick={() => this.fetchOrderItems(row)}
          >
            OrderId:{this.getOrderData(row)[0]}
          </button>
          <button className="btn btn-success btn-lg">
            Amount:
            {this.getOrderData(row)[1]}
          </button>
        </div>
        {this.showOrderItems(row)}
      </div>
    );
  };
  render() {
    console.log(this.props.orderItems);
    const { currentPage, pageSize } = this.props;
    const items = this.props.items.map((item) => ({
      ["seqNo"]: (
        <h5>
          <span className={this.getBadgeClasses(item)}>
            {/* {this.props.items.indexOf(item) + 1 + (currentPage - 1) * pageSize} */}
            {
              this.props.allPatientsSeq.find((ps) => ps.id === item.patient_id)[
                "seqNo"
              ]
            }
          </span>
        </h5>
      ),
      ...item,
      ["case_number"]: (
        <h5>
          <span className={this.getCaseBadgeClasses(item)}>
            {item.case_number}
          </span>
        </h5>
      ),
      ["arrival_time"]: extractTime(item.arrival_time),
    }));
    const products = items;
    const columns = [
      {
        dataField: "seqNo",
        text: "Seq",
        headerStyle: { width: "30px" },
      },
      {
        dataField: "case_number",
        text: "CaseNumber",
        headerStyle: { width: "150px" },
      },
      {
        dataField: "name",
        text: "Name",
        headerStyle: { width: "auto" },
      },
      {
        dataField: "age",
        text: "Age",
        hidden: true,
        headerStyle: { width: "30px" },
      },
      {
        dataField: "weight",
        text: "Weight",
        hidden: true,
        headerStyle: { width: "50px" },
      },
      {
        dataField: "mobile_number",
        text: "ContactNum",
        hidden: true,
        headerStyle: { width: "100px" },
      },
      {
        dataField: "status",
        text: "Status",
        headerStyle: { width: "auto" },
      },
      {
        dataField: "doctor_fees",
        text: "DoctorFees",
        hidden: this.state.user.role === "Doctor" ? false : true,
        headerStyle: { width: "50px" },
      },
      {
        dataField: "arrival_time",
        text: "ArrivalTime",
        headerStyle: { width: "150px" },
        hidden: false,
      },
    ];

    const expandRow = {
      onlyOneExpanding: true,
      renderer: (row) => (
        <div>
          {row.status === "OrderCreated" ? (
            this.state.user.role === "Doctor" ? (
              this.renderOrderDetails(row)
            ) : (
              <></>
            )
          ) : this.state.user.role === "Doctor" ? (
            row.status === "InConsulting" ? (
              this.renderAddDoctorConsultingFees(row)
            ) : (
              <></>
            )
          ) : this.state.user.role === "Receptionist" ? (
            row.status === "InReception" ? (
              this.sendForDoctorConsulting(row)
            ) : (
              <></>
            )
          ) : (
            this.renderHandleWithMe(row)
          )}
        </div>
      ),
    };
    return (
      <BootstrapTable
        className="table"
        keyField="patient_id"
        data={products}
        columns={columns}
        expandRow={expandRow}
        bordered={false}
      />
    );
  }

  getBadgeClasses = (item) => {
    let classType = "badge badge-";
    let addOn = "dark";
    if (item.type === "Emergency") addOn = "danger";
    classType += addOn;
    return classType;
  };
  getCaseBadgeClasses = (item) => {
    let classType = "badge badge-";
    let addOn = "Light";
    if (item.creation_date === getCurrentDate()) addOn = "success";
    if (item.type === "OTC") addOn = "info";
    classType += addOn;
    return classType;
  };
}

const mapStateToProps = (state) => {
  return {
    orders: state.entities.orders,
    orderItems: state.entities.orders.doctorOrderItems,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getOrderItems: (orderID) => dispatch(getOrderItems(orderID)),
    empty_orders_list: () => dispatch(empty_orders_list()),
    getPatientsInQueue: (date) => dispatch(getPatientsInQueue(date)),
    updatePatientStatus: (updatePatient) =>
      dispatch(updatePatientStatus(updatePatient)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientsTable);
