import {
  getCurrentDateWithSlash,
  getCurrentTime,
} from "../../utils/myDateTime";
import React, { Component } from "react";

class RtClock extends Component {
  state = { time: "", date: "" };

  componentDidMount() {
    this.intervalID = setInterval(() => this.tick(), 1000);
  }
  componentWillUnmount() {
    clearInterval(this.intervalID);
  }
  tick() {
    this.setState({
      date: getCurrentDateWithSlash(),
      time: getCurrentTime(),
    });
    //console.log(this.state.date);
  }
  render() {
    return (
      <span>
        {this.state.date} | {this.state.time}
      </span>
    );
  }
}

export default RtClock;
