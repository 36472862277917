import React from "react";
import { connect } from "react-redux";
import Form from "../common/form";
import Joi from "joi-browser";
import auth from "../../services/authService";
import Input from "../common/input";
import ElemComponent from "./refComp";
import ReactTable from "react-table-v6";
import DatePicker from "react-datepicker";
import "react-table-v6/react-table.css";
import {
  getPatientsList,
  mapToViewModelOutwards,
  mapToViewModelInwards,
} from "../../services/fakePatients";
import {
  getCurrentDate,
  getCaseNumberFormatedDate,
  getCaseNumberFormatedDateString,
  getCurrentFormattedDateTime,
} from "../../utils/myDateTime";
import {
  checkPatientExist,
  updatePatientStatus,
  addPatientToQueue,
  removeExistedPatient,
  getFilteredPatients,
  addExistingPatient,
} from "../../store/patientsDux";
import { Button } from "react-bootstrap";
import ErrorBoundary from "../../ErrorBoundary";

class AddPatient extends Form {
  constructor(props) {
    super();
  }
  state = {
    // Initialize properties of state object either to an empty string or some value we get from the server, coz forms are controlled elements
    data: {
      caseNumber: "",
      patientName: "",
      patientType: "Regular",
      creationDate: "",
      arrivalTime: "",
      status: "",
      mobileNumber: "",
      age: "",
      weight: "",
      doctorFees: 0,
      debt: 0,
    },

    errors: {}, // simplified errors object which will map the complex JOI Object
    errorMessage: {
      patientName: "PatientName",
      caseNumber: "CaseNumber",
      patientType: "PatientType",
      creationDate: "CreationDate",
      arrivalTime: "ArrivalTime",
      status: "Status",
      mobileNumber: "MobileNumber",
      age: "Age",
      weight: "Weight",
      doctorFees: "DoctorFees",
      debt: "patientDebt",
    },
    patientTypes: [{ _id: 1, name: "Emergency" }],
    defaultType: { _id: 0, name: "Regular" },
    //this is to hide erroe msg until we hit button
    isRequiredFilled: this.state.isRequiredFilled,
    user: auth.getCurrentUser(),
    nextCaseNumber: "",
    justReceived: true,
    columnNames: ["case_number", "name", "mobile_number", "age"],
    columns: [],
    selectedPatient: null,
    firstCaseNumber: null,
    showSearchPatient: false,
    isSubmitDisabled: false,
    calenderOpen: false,
    totalPatients: 0,
    searchFilter: null,
  };
  //determine schema for validation
  schema = {
    patientName: Joi.string()
      .regex(/[a-zA-Z]/)
      .required(),
    patientType: Joi.string().required(),
    caseNumber: Joi.string().regex(/[0-9]/).min(10).max(10),
    creationDate: Joi.string(),
    arrivalTime: Joi.string(),
    status: Joi.string(),
    mobileNumber: Joi.string().min(10).max(11).allow(null, ""),
    age: Joi.string().required().min(1).max(3),
    weight: Joi.string().min(1).max(3).allow(null, ""),
    doctorFees: Joi.number(),
    debt: Joi.number().allow(null, ""),
  };
  componentDidMount(props) {
    this.setDefaultStateData(props);
    let dateString = getCaseNumberFormatedDateString(new Date());
    const firstCaseNumber = dateString + (1).toString().padStart(4, "0");
    console.log("In Componenet Did Mount");
    const caseNumberDate = new Date();
    let columns = [
      ...this.state.columnNames.map((c) => ({
        Header: c,
        accessor: c,
        show: true,
        style: { textAlign: "center" },
        //width: getColumnWidth(filteredPatients, `${c}`, `${c}`),
      })),
    ];
    this.setState({ firstCaseNumber, caseNumberDate, columns });
    // this.setDefaultStateData(props);
  }

  componentWillReceiveProps(props) {
    if (this.state.justReceived === true) {
      this.setDefaultStateData(props);
      this.setState({ justReceived: false });
      props.removeExistedPatient();
    }
    if (props.filteredPatients !== undefined) {
      this.setState({
        filteredPatients:
          props.filteredPatients === undefined ? [] : props.filteredPatients,
      });
      const filteredPatients = props.filteredPatients.length;
      this.setState({
        totalPatients:
          filteredPatients > this.state.totalPatients
            ? filteredPatients
            : this.state.totalPatients,
      });
      console.log("filteredPatients : ");
      console.log(props.filteredPatients);
    }
  }

  setDefaultStateData = (props) => {
    console.log("SettingDefault");
    let dateString = getCaseNumberFormatedDateString(new Date());
    let caseNumber = "";

    if (props === undefined || props.nextCaseNumber === undefined) {
      caseNumber = dateString + (1).toString().padStart(4, "0");
      console.log("In Set default state");
    } else {
      caseNumber =
        props.nextCaseNumber.slice(0, 6) === dateString
          ? dateString + props.nextCaseNumber.slice(6, 10)
          : dateString + (1).toString().padStart(4, "0");
    }

    // this.props.checkPatientExist(caseNumber);
    const patientType = "Regular";
    const patientName = "";
    const arrivalTime = getCurrentFormattedDateTime();
    const status = "InReception";
    const mobileNumber = "";
    const age = "";
    const weight = "";
    const creationDate = getCurrentDate().toString();
    const doctorFees = 0;
    const debt = 0;
    this.setState(
      {
        data: {
          caseNumber,
          patientName,
          patientType,
          creationDate,
          arrivalTime,
          status,
          mobileNumber,
          age,
          weight,
          doctorFees,
          debt,
        },
      },
      () => this.getFilteredPatients()
    );
  };

  handleCaseNumberDateUpdate = (date) => {
    console.log("e at handlecaseNumberUpdate : ");
    console.log(date);
    this.closeCalender();
    // e.preventDefault();
    this.setState({ caseNumberDate: date }, () => {
      console.log("caseNumberDate :");
      console.log(date);
      const data = { ...this.state.data };
      data["caseNumber"] =
        getCaseNumberFormatedDateString(this.state.caseNumberDate) +
        this.state.data.caseNumber.slice(6, 10);
      this.setState({ data }, () => this.getFilteredPatients());

      if (data["caseNumber"].length === 10) {
        //checking if patient Exist
        console.log("checking if patient Exist");
        this.props.checkPatientExist(data["caseNumber"]); //Makes ASYNC DB Call
        //check if patient exist after 5 secs

        setTimeout(() => {
          const { patientExist, existingPatient } = this.props;
          console.log("Checking for patient at form: " + patientExist);
          if (patientExist) {
            this.setState({
              data: {
                caseNumber: existingPatient.case_number,
                patientName: existingPatient.name,
                patientType: "Regular",
                creationDate: existingPatient.creation_date,
                arrivalTime: getCurrentFormattedDateTime(),
                status: "InReception",
                mobileNumber: existingPatient.mobile_number,
                age: existingPatient.age,
                weight: existingPatient.weight,
                doctorFees: existingPatient.doctorFees,
                debt: existingPatient.debt,
              },
            });
          }
        }, 2000);
      }
    });
  };
  handleCaseNumberUpdate = (e) => {
    console.log("e at handlecaseNumber Update : ");
    console.log(e);
    e.preventDefault();
    const data = { ...this.state.data };

    // //Hit Enter to pad zero at start of caseNumber
    // if (e.key === "Enter") {
    //   data["caseNumber"] =
    //     getCaseNumberFormatedDateString(this.state.caseNumberDate) +
    //     padStart(e.currentTarget.value, 4, "0");
    // }

    data["caseNumber"] =
      getCaseNumberFormatedDateString(this.state.caseNumberDate) +
      e.currentTarget.value;
    this.setState({ data }, () => this.getFilteredPatients());

    //reset other fields except caseNumber
    const caseNumber =
      getCaseNumberFormatedDateString(this.state.caseNumberDate) +
      e.currentTarget.value;
    const patientType = "Regular";
    const patientName = "";
    const arrivalTime = getCurrentFormattedDateTime();
    const status = "InReception";
    const mobileNumber = "";
    const age = "";
    const weight = "";
    const creationDate = getCurrentDate().toString();
    const doctorFees = 0;
    const debt = 0;
    this.setState({
      data: {
        caseNumber,
        patientName,
        patientType,
        creationDate,
        arrivalTime,
        status,
        mobileNumber,
        age,
        weight,
        doctorFees,
        debt,
      },
    });

    //type any letter in casenumber box to get nextCaseNumber
    var letters = /[a-zA-Z]/g;
    if (data["caseNumber"].match(letters)) {
      this.setDefaultStateData(this.props);
    }
    if (data["caseNumber"].length === 10) {
      console.log("CASENUMBER STRING");

      //checking if patient Exist

      this.props.checkPatientExist(data["caseNumber"]); //Makes ASYNC DB Call
      //check if patient exist after 5 secs

      setTimeout(() => {
        const { patientExist, existingPatient } = this.props;
        console.log("Checking for patient at form: " + patientExist);
        if (patientExist) {
          this.setState({
            data: {
              caseNumber: existingPatient.case_number,
              patientName: existingPatient.name,
              patientType: "Regular",
              creationDate: existingPatient.creation_date,
              arrivalTime: getCurrentFormattedDateTime(),
              status: "InReception",
              mobileNumber: existingPatient.mobile_number,
              age: existingPatient.age,
              weight: existingPatient.weight,
              doctorFees: existingPatient.doctorFees,
              debt: existingPatient.debt,
            },
          });
        }
      }, 2000);
    }
  };
  handlePatientNameUpdate = (e) => {
    const data = { ...this.state.data };
    data[e.currentTarget.name] = e.currentTarget.value;
    this.setState({ data }, () => this.getFilteredPatients());
  };

  handleMobileNumberUpdate = (e) => {
    const data = { ...this.state.data };
    data[e.currentTarget.name] = e.currentTarget.value;
    this.setState({ data }, () => this.getFilteredPatients());
  };

  getFilteredPatients = () => {
    let {
      caseNumber,
      patientName,
      mobileNumber,
      age,
      weight,
    } = this.state.data;

    console.log("State data to filter with>>");

    console.log(caseNumber);
    console.log(patientName);
    console.log(mobileNumber);

    let filter = {
      caseNumber:
        caseNumber === this.props.nextCaseNumber ||
        caseNumber === this.state.firstCaseNumber ||
        caseNumber.length > 10
          ? null
          : caseNumber, // if casenumber is nextCasenumber or first case of the day, send null to ignore casenumber and search with other fields
      name: patientName,
      mobileNumber: mobileNumber,
      age: null,
      weight: null,
    };
    //fetch filtered list from server, setState to filteredPatients to display
    console.log("filter>>");
    console.log(filter);

    this.setState({ searchFilter: filter });

    this.props.getFilteredPatients(filter);
  };
  handleAfterPatientAdd = (patientsInQueue) => {
    setTimeout(() => {
      this.setState({ isSubmitDisabled: false });
      if (this.props.allPatients.length > patientsInQueue) {
        console.log("Patient Added Successfully");

        const patientType = "Regular";
        const creationDate = getCurrentDate().toString();
        const blank = "";
        this.setState(
          {
            data: {
              caseNumber:
                getCaseNumberFormatedDateString(this.state.caseNumberDate) +
                this.state.data.caseNumber.slice(6, 10),
              patientType,
              creationDate,
              patientName: blank,
              mobileNumber: blank,
              age: blank,
              weight: blank,
              doctorFees: 0,
              debt: 0,
            },
          },
          () => this.props.checkPatientExist(this.props.nextCaseNumber)
        );
      }
    }, 4000);
  };
  doSubmit = () => {
    //Disable From Submit Button to make sure no duplicate entries get created
    this.setState({ isSubmitDisabled: true });
    const { patientExist, existingPatient } = this.props;
    //if yes, get the caseNumber ,if no, assign next available caseNumber
    if (!patientExist) {
      const { data } = this.state;
      //console.log(data);
      //data.arrivalTime = getCurrentTime();
      data.arrivalTime = getCurrentFormattedDateTime();
      data.patientType = this.state.data.patientType;
      data.status = "InReception";
      if (data.mobileNumber === undefined || data.mobileNumber === "")
        data.mobileNumber = "0000000000";
      if (data.weight === undefined || data.weight === "") data.weight = "0";
      this.setState({ data });
      // console.log(data);
      let newPatientData = {};
      console.log("New Patient DATA instae: ");
      console.log(this.state.data);
      newPatientData = Object.assign(newPatientData, this.state.data);
      //const newPatientData = { ...this.state.data };
      console.log("newPatientData: ");
      console.log(newPatientData);
      const patientsInQueue = this.props.allPatients.length;
      //Now add new Patient at the bottom of the List
      this.props.addNewPatient(newPatientData);
      //Increase CaseNumber Count and Set Type  back to Regular
      this.handleAfterPatientAdd(patientsInQueue);
    } else {
      console.log("patient already there in our DB"); //Need to assign data here
      let {
        caseNumber,
        patientName,
        patientType,
        creationDate,
        mobileNumber,
        age,
        weight,
      } = this.state.data;
      let status = "InReception";
      let arrival_time = getCurrentFormattedDateTime();
      let patient_id = existingPatient.patient_id;
      const updatedPatient = {
        case_number: caseNumber,
        name: patientName,
        type: patientType,
        creation_date: existingPatient.creation_date, //creationDate,
        arrival_time:
          existingPatient.creation_date === getCurrentDate().toString()
            ? existingPatient.arrival_time
            : arrival_time,
        mobile_number: mobileNumber,
        age,
        weight,
        status:
          existingPatient.creation_date === getCurrentDate().toString()
            ? existingPatient.status
            : status,
        patient_id,
        doctor_fees:
          existingPatient.creation_date === getCurrentDate().toString()
            ? existingPatient.doctor_fees
            : 0,
        debt: existingPatient.debt,
      };
      const patientsInQueue = this.props.allPatients.length;
      // {"case_number":"0401210009","name":"dvgdhjsl","type":"OTC","creation_date":"20210104","arrival_time":"2021-01-04 05:05:15","mobile_umber":"0000000000","age":"45","weight":"48","status":"WithDoctor","patient_id":67}
      this.props.updatePatientStatus(updatedPatient);
      // update_patient_inQueue('0401210008', 'fghjk', 'Emergency', '20210104', +arrivaltime'0000000000', '45', '0', +status66, -'2021-01-04 04:10:39', -'WithDoctor';

      this.props.addPatientToQueue(updatedPatient);
      this.handleAfterPatientAdd(patientsInQueue);
    }
  };
  showSearchResults = () => {
    this.setState({
      showSearchPatient: this.state.showSearchPatient ? false : true,
    });
  };
  handleCalenderClick = (e) => {
    // e.preventDefault();
    // console.log("e at handle calender click :");
    // console.log(e);
    // console.log(e.key);
    // if (!e.key)
    this.setState({ calenderOpen: !this.state.calenderOpen }); //onClick={() => this.handleCalenderClick()}
  };
  closeCalender = () => {
    this.setState({ calenderOpen: false });
  };

  render() {
    const { data, errors } = this.state;
    const filteredPatients =
      this.props.filteredPatients.length > 0 ? this.props.filteredPatients : [];
    console.log("filteredPatients>>");
    console.log(filteredPatients);

    const totalPatients =
      filteredPatients.length > this.state.totalPatients
        ? filteredPatients.length
        : this.state.totalPatients;

    return (
      <div
        style={{
          justifyContent: "center",
          // display: "flex",
          minHeight: "370px",
          position: "relative",
          minWidth: "500px",
          // display: "flex",
          // overflowX: "scroll",
          flexDirection: "column",
          width: "fit-content",
        }}
      >
        <div
          style={{
            position: "absolute",
            left: "80px",
            // top: "7px",
            width: "0px",
            height: "fit-content",
            zIndex: 100,
            // position: element("#caseNumber"),
          }}
          className="form-group"
        >
          <label htmlFor="caseNumberDate">
            <b>Date</b>
          </label>
          {/* <form onSubmit={this.handleCalenderClick}> */}

          <DatePicker
            selected={this.state.caseNumberDate}
            onChange={(date) => this.handleCaseNumberDateUpdate(date)}
            shouldCloseOnSelect={true}
            dateFormatCalendar="d LL yy"
            showYearDropdown
            showMonthDropdown
            useShortMonthInDropdown
            open={this.state.calenderOpen}
            // onKeyDown={this.handleCalenderClick}
            // openToDate={new Date()}
            onClickOutside={(e) => this.closeCalender(e)}
            todayButton="Today / ShowAllPatients"
            name="caseNumberDate"
            dateFormat="ddMMyy"
            default="today"
            className="form-control"
            customInput={
              <ElemComponent
                handleCalenderClick={this.handleCalenderClick}
                // onKeyDown={this.handleCalenderClick}
                selectedDate={getCaseNumberFormatedDate(
                  this.state.caseNumberDate
                )}
              />
            }
          />
          {/* </form> */}
        </div>
        <form
          onSubmit={this.handleSubmit}
          // onKeyDown={this.handleCalenderClick}
          style={{
            position: "absolute",
            flexDirection: "column",
            width: "min-content",
            overflowX: "scroll",
            display: "flex",
            // alignItems: "center",
          }}
        >
          <div
            className="row align-items-center"
            style={{ margin: "2px", minWidth: "485px" }}
          >
            <div className="row">
              <div
                className="col"
                style={{ textAlign: "center" }}
                // onClick={(e) => this.handleCalenderClick(e)}
              ></div>
              <div id="caseNumber" className="col">
                {/* {this.state.data.caseNumber} */}
                <Input
                  type={"number"} // e.g: text/ password
                  name={"caseNumber"} // e.g: username/ password
                  label={"CaseNumber*"} // this will be used to display errors
                  onChange={this.handleCaseNumberUpdate} // tracks changes goes with value property
                  value={data["caseNumber"].slice(6, 10)} //to store data object
                  error={errors["caseNumber"]} // has all errors if any occurs otherwise its an empty {}
                  autoFocus={false}
                />
              </div>
              {/* {this.renderInput("caseNumber", "CaseNumber*")} */}
              <div className="col">
                <div className={this.state.data.patientType}>
                  {this.renderSelect(
                    "patientType",
                    "Type*",
                    this.state.patientTypes,
                    this.state.defaultType
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row mr-5 ml-5">
            <div className="col">
              <Input
                type={"text"} // e.g: text/ password
                name={"patientName"} // e.g: username/ password
                label={"patientName*"} // this will be used to display errors
                onChange={this.handlePatientNameUpdate} // tracks changes goes with value property
                value={data["patientName"]} //to store data object
                error={errors["patientName"]} // has all errors if any occurs otherwise its an empty {}
                autoFocus={false}
              />
            </div>
            {/* {this.renderInput("patientName", "patientName*")} */}
          </div>
          <div
            className="row align-items-center mr-4 ml-4"
            style={{ margin: "2px", minWidth: "485px" }}
          >
            <div className="col">
              <Input
                type={"number"} // e.g: text/ password
                name={"mobileNumber"} // e.g: username/ password
                label={"MobileNumber"} // this will be used to display errors
                onChange={this.handleMobileNumberUpdate} // tracks changes goes with value property
                value={data["mobileNumber"]} //to store data object
                error={errors["mobileNumber"]} // has all errors if any occurs otherwise its an empty {}
                autoFocus={false}
              />
              {/* {this.renderInput("mobileNumber", "MobileNumber")} */}
            </div>
            <div className="col">
              {this.renderInput("age", "Age*", "", "number")}
            </div>
            <div className="col">
              {this.renderInput("weight", "Weight", "", "number")}
            </div>
          </div>
          <div
            // className="row"
            style={{
              padding: "0px 16px 0px 16px",
              minWidth: "485px",
            }}
          >
            <Button
              onClick={this.showSearchResults}
              className="btn btn-secondary btn-block"
              // style={{ , minWidth: "485px" }}
            >
              Search Patient
            </Button>
          </div>
          <div style={{ minWidth: "485px", overflowX: "scrll" }}>
            {this.state.showSearchPatient ? (
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    // marginBottom: "40px",
                  }}
                >
                  <ul>
                    {Object.entries(this.state.searchFilter).map((e) =>
                      e[1] ? (
                        <li>
                          <b>{e[0]}</b> : {e[1]}{" "}
                        </li>
                      ) : (
                        ""
                      )
                    )}
                  </ul>
                </div>
                {/* className="table-responsive"> */}
                {filteredPatients.length > 0 ? (
                  <ErrorBoundary>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        // marginBottom: "40px",
                      }}
                    >
                      Filtered {`${filteredPatients.length}/${totalPatients}`}{" "}
                      Patients
                    </div>
                    <ReactTable
                      data={filteredPatients}
                      columns={this.state.columns}
                      className="-striped -highlight"
                      // showPagination={false}
                      showPaginationTop={true}
                      showPaginationBottom={false}
                      pageSize={5}
                      show={this.state.showSearchPatient}
                      getTrProps={(state, rowInfo) => {
                        if (rowInfo && rowInfo.row) {
                          return {
                            onClick: (e) => {
                              console.log(rowInfo);
                              this.setState({
                                data: mapToViewModelOutwards({
                                  ...rowInfo.original,
                                  type: "Regular",
                                }),
                              });
                              this.props.addExistingPatient({
                                ...rowInfo.original,
                                type: "Regular",
                              });
                            },
                            className: "editRow",
                            style: {
                              background:
                                rowInfo.index === this.state.selected
                                  ? "rgb(128,128,128)"
                                  : "",
                              color:
                                rowInfo.index === this.state.selected
                                  ? "black"
                                  : "black",
                            },
                          };
                        } else {
                          return {};
                        }
                      }}
                    />
                  </ErrorBoundary>
                ) : (
                  <div style={{ textAlign: "center" }}>No Search Results.</div>
                )}
              </div>
            ) : (
              ""
            )}
          </div>

          <div className="column align-items-center mx-2">
            {/* {this.state.isSubmitEnabled?:} */}
            <div
              className="row"
              style={{ minWidth: "485px", paddingTop: "10px" }}
            >
              {this.renderButton(
                "AddPatient",
                "btn btn-primary btn-lg btn-block",
                this.state.isSubmitDisabled
              )}
            </div>
            <div className="row pt-1">
              {this.renderErrorMessage(this.state.errorMessage)}
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    allPatients: state.entities.patients.list,
    filteredPatients: state.entities.patients.filteredList,
    patientExist: state.entities.patients.patientExist,
    existingPatient: state.entities.patients.existingPatient,
    nextCaseNumber: state.entities.patients.nextCaseNumber,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    removeExistedPatient: () => dispatch(removeExistedPatient()),
    checkPatientExist: (cN) => dispatch(checkPatientExist(cN)),
    addExistingPatient: (patient) => dispatch(addExistingPatient(patient)),
    updatePatientStatus: (patient) => dispatch(updatePatientStatus(patient)),
    addPatientToQueue: (patient) => dispatch(addPatientToQueue(patient)),
    getFilteredPatients: (filter) => dispatch(getFilteredPatients(filter)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPatient);
