import React, { Component } from "react";
import { connect } from "react-redux";
import PatientsTable from "../../Patients/patientsTable";
import {
  getFormattedDate,
  getCurrentDateWithDash,
} from "../../../utils/myDateTime";
import { paginate } from "../../../utils/paginate";
import Pagination from "../../common/pagination";
import { toast } from "react-toastify";
import {
  updatePatientStatus,
  updateCurrentPatient,
  getPatientsInQueue,
} from "../../../store/patientsDux";
import { getpatientsLastOrder } from "../../../store/ordersDux";
import { updateCart } from "../../../store/cartDux";

class UpcomingPatients extends Component {
  state = {
    pageSize: 10,
    currentPage: 1,
    allPatients: [],
    allPatientsSeq: [], //to maintain same seq number on filtered list
  };

  // right place to call the server and get  data
  componentWillReceiveProps(props) {
    this.updatePatientSeq(props.allPatients);
    if (props.allPatients.length > 0) {
      this.setState({ allPatients: props.allPatients });

      //console.log(this.state.allItems);
    }
  }

  async componentDidMount() {
    //console.log("Getting All PATIENTS ASYNC REDUX");
    const d = new Date();
    this.props.getPatientsInQueue(getFormattedDate(d));
    setTimeout(() => this.props.getPatientsInQueue(getFormattedDate(d)), 5000);
    // this.updatePatientSeq(this.props.allPatients);
  }

  handlePageChange = (page) => {
    this.setState({ currentPage: page });

    const { pageSize, currentPage, allPatients } = this.state;

    this.setState({
      currentPage:
        page === "Next"
          ? currentPage !== Math.ceil(allPatients.length / pageSize)
            ? (page = currentPage + 1)
            : (page = currentPage)
          : page === "Prev"
          ? currentPage !== 1
            ? (page = currentPage - 1)
            : (page = currentPage)
          : page,
    });
  };

  updatePatientSeq(allPatients) {
    // console.log("allPatients");
    // console.log(allPatients);
    const seq = allPatients.map((p) => ({
      id: p.patient_id,
      seqNo: allPatients.indexOf(p) + 1,
    }));
    // console.log("allPatientsseq");
    // console.log(seq);

    this.setState({ allPatientsSeq: seq });
  }

  getPagedData = () => {
    const { pageSize, currentPage } = this.state;

    //do All the filtering/sorting here
    const patientsWithDoctor = this.props.allPatients.filter(
      (p) => p.status === "Consulted" || p.status === "InMedical"
    );
    const patientsOnPage = paginate(patientsWithDoctor, currentPage, pageSize);

    return {
      patientsWithDoctor,
      patientsOnPage,
      currentPage,
      pageSize,
    };
  };

  handleWithMeClick = (row) => {
    console.log("row :");
    console.log(row);
    const case_number = row.case_number.props.children.props.children;
    //get patients last order
    this.props.getpatientsLastOrder(case_number);

    let newRow = { ...row, case_number };
    delete newRow.seqNo;
    newRow.status = "InMedical";
    newRow.arrival_time = getCurrentDateWithDash() + " " + newRow.arrival_time;
    newRow.doctor_fees = parseInt(newRow.doctor_fees);
    console.log("newRow : ");
    console.log(newRow);

    this.props.updatePatientStatus(newRow);
    this.props.updateCurrentPatient(newRow);

    // Enable Below block to update cart with last order, add toggle in settings
    // setTimeout(() => {
    //   const patientsLastOrder = this.props.patientsLastOrder;
    //   if (patientsLastOrder && patientsLastOrder.length > 0) {
    //     this.props.updateCart(patientsLastOrder);
    //   } else {
    //     console.error("No order for patient");
    //     toast.info("Could not find order for " + case_number, {
    //       autoClose: 2000,
    //     });
    //   }
    // }, 3000);
  };

  render() {
    //Object Destructuring

    const {
      patientsWithDoctor,
      patientsOnPage,
      currentPage,
      pageSize,
    } = this.getPagedData();
    const totalPatients = patientsWithDoctor.length;
    console.log("Total Upcoming Patients: ");
    console.log(totalPatients);
    // Conditional Rendering

    return (
      <div style={{ padding: "1em", zoom: 0.9 }}>
        <div>
          <b>Total {totalPatients} Upcoming Patients.</b>
        </div>
        {totalPatients > 0 && this.state.allPatientsSeq.length > 0 ? (
          <div>
            <PatientsTable
              items={patientsOnPage}
              currentPage={currentPage}
              pageSize={pageSize}
              // showSeqNum={true}
              // showArrTime={true}
              handleWithMeClick={this.handleWithMeClick}
              allPatientsSeq={this.state.allPatientsSeq}
            />
            <div className="upcomingPatientsPaginationBlock">
              <Pagination
                itemsCount={totalPatients}
                pageSize={pageSize}
                currentPage={currentPage}
                onPageChange={this.handlePageChange} // Handling an event
              />
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    allPatients: state.entities.patients.list,
    patientsLastOrder: state.entities.orders.patientsLastOrder.list,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getPatientsInQueue: (date) => dispatch(getPatientsInQueue(date)),
    updatePatientStatus: (updatePatient) =>
      dispatch(updatePatientStatus(updatePatient)),
    updateCurrentPatient: (updatePatient) =>
      dispatch(updateCurrentPatient(updatePatient)),
    getpatientsLastOrder: (case_number) =>
      dispatch(getpatientsLastOrder(case_number)),
    updateCart: (cartItems) => dispatch(updateCart(cartItems)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpcomingPatients);
