import React, { Component, useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import "../../assets/css/loadingSpinner.css";
import { LoadingOrder } from "../../store/ordersDux";
import { LoadingPatients } from "../../store/patientsDux";

// import { LoadingUser } from "../../store/userDux";

const LoadingSpinner = ({ loading }) => {
  const loadingOrder = LoadingOrder();
  const loadUser = false; // LoadingUser();
  const loadingPatients = LoadingPatients();

  return (
    <>
      <div
        className={
          loadingOrder || loadingPatients || loadUser || loading
            ? "loadingSpinner"
            : "loadingSpinner hideLoading"
        }
      >
        <Spinner animation="border" />
      </div>
    </>
  );
};

export default LoadingSpinner;
