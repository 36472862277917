import React, { useCallback } from "react";
import Form from "../common/form";
import Joi from "joi-browser";
import auth, { getCurrentUser } from "../../services/authService";
import { toast } from "react-toastify";
import UnAuthSSE from "../../services/SSE/UnAuthSSE";

class LoginPage extends Form {
  constructor() {
    super();
    this.username = React.createRef();
  }
  //Initialize the state
  state = {
    // Initialize properties of state object either to an empty string or some value we get from the server, coz forms are controlled elements
    data: { username: "", password: "" },
    errors: {}, // simplified errors object which will map the complex JOI Object
    rolesToMedical: ["admin", "Pharmasist"],
    isSubmitDisabled: false,
  };

  schema = {
    username: Joi.string().required(),
    password: Joi.string().required(),
  };
  //determine what should happen when the form is submiited
  doSubmit = async () => {
    try {
      this.setState({ isSubmitDisabled: true });
      //window.location = "/newDashboard";
      const { data } = this.state;
      await auth.login(data.username, data.password);

      // this.props.history.push("/patients");
      // window.location = "/patients";
      const user = getCurrentUser();
      if (this.state.rolesToMedical.includes(user.role))
        window.location = "/newDashboard";
      else {
        window.location = "/patients";
      }
    } catch (e) {
      if (e.response && e.response.status == 400) {
        // const errors = {...this.stat e.errors};
        // errors.username = e.response.data;
        // this.setState({errors});
        toast.error("Invalid Username or Password");
        this.setState({ isSubmitDisabled: false });
      }
    }
  };

  componentDidMount() {
    UnAuthSSE();
  }

  //what to show when the form is rendered
  render() {
    return (
      <div className="container">
        <form onSubmit={this.handleSubmit}>
          {this.renderInput("username", "Username", "username")}
          {this.renderInput("password", "Password", "password", "password")}
          {this.renderButton(
            "LogIn",
            "btn btn-primary btn-lg btn-block",
            this.state.isSubmitDisabled
          )}
        </form>
        <div>
          <p>
            Don't Have an Account Yet?
            <span>
              <a href="/register"> Register Here.</a>
            </span>
          </p>
        </div>
      </div>
    );
  }
}

export default LoginPage;
