import React, { Component } from "react";
import Joi from "joi-browser";
import Input from "./input";
import Select from "./select";

class Form extends Component {
  state = {
    data: {},
    errors: {},
    isRequiredFilled: !false,
  };
  // validate on submit
  validate = () => {
    //console.log("at validate" + this.state.data.status);
    const options = { abortEarly: false }; // By Default Joi terminates validation as soon as it finds an error.
    const result = Joi.validate(this.state.data, this.schema, options); //pass an object to be validated, schema, object to set options
    //console.log(result);
    if (!result.error) return null;

    // mapping the complex JOI error details to our simplified errors object
    const errors = {};
    for (let item of result.error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  handleSubmit = (e) => {
    e.preventDefault(); // prevent default action of submitting a form to the server of a bootstrap form
    //before submitting the form we need to validate it.
    const errors = this.validate();
    console.log(errors);
    this.setState({ errors: errors || {} }); // errors object can not be null, so always have it point to an empty object
    if (errors) {
      this.setState({ isRequiredFilled: false });
      return;
    }
    this.setState({ isRequiredFilled: true });
    //console.log(this.state.data);
    this.doSubmit();
  };

  updateRequiredFilled = (e) => {
    console.log(this.state.errors);
  };

  handleChange = (e) => {
    const data = { ...this.state.data };
    data[e.currentTarget.name] = e.currentTarget.value;
    this.setState({ data });
  };

  renderErrorMessage(errorMessage) {
    const arry = Object.keys(this.state.errors).map((key) => errorMessage[key]);
    if (!this.state.isRequiredFilled)
      return (
        <small>
          <p style={{ color: "#DB2B39" }}>
            {"Please enter valid " + arry.join(", ")}
          </p>
        </small>
      );
  }

  renderButton(label, bsClass, disabled) {
    if (disabled)
      return (
        <button
          //   to disable button if the validation fails
          disabled
          className={bsClass}
        >
          {label}
        </button>
      );
    else return <button className={bsClass}>{label}</button>;
  }

  renderInput(name, label, placeholder, type = "text") {
    // default type is text, and we pass password which will override the type
    const { data, errors } = this.state;
    return (
      <Input
        type={type} // e.g: text/ password
        name={name} // e.g: username/ password
        label={label} // this will be used to display errors
        placeholder={placeholder}
        onChange={this.handleChange} // tracks changes goes with value property
        value={data[name]} //to store data object
        error={errors[name]} // has all errors if any occurs otherwise its an empty {}
        autoFocus={name === "patientName"}
      />
    );
  }

  renderSelect(name, label, options, defaultType = "") {
    const { data, errors } = this.state;
    return (
      <Select
        name={name}
        defaultValue={defaultType}
        label={label}
        options={options}
        onChange={this.handleChange}
        value={data[name]}
      />
    );
  }
}

export default Form;
