const data = {
  STOCK_LOCATION: [
    { _id: 0, name: "RACK_LOCATION" },
    { _id: 1, name: "A0" },
    { _id: 2, name: "AR3C1" },
    { _id: 3, name: "AR4C1" },
    { _id: 4, name: "AR5C1" },
    { _id: 5, name: "AR6C1" },
    { _id: 6, name: "AR7C1" },
    { _id: 7, name: "AR8C2" },
    { _id: 8, name: "BR5C1" },
    { _id: 12, name: "C0" },
    { _id: 13, name: "D0" },
    { _id: 14, name: "E0" },
    { _id: 15, name: "E8" },
    { _id: 16, name: "F0" },
    { _id: 19, name: "J8" },
    { _id: 20, name: "K8" },
    { _id: 29, name: "S0" },
    { _id: 30, name: "SR1C1" },
    { _id: 31, name: "SR1C2" },
    { _id: 32, name: "SR1C3" },
    { _id: 33, name: "SR2C1" },
    { _id: 34, name: "SR2C2" },
    { _id: 35, name: "SR2C3" },
    { _id: 36, name: "SR3C1" },
    { _id: 37, name: "SR3C2" },
    { _id: 38, name: "SR3C3" },
    { _id: 39, name: "X" },
  ],
  RACK_LOCATION: [
    { _id: 1, name: "A0" },
    { _id: 2, name: "A10" },
    { _id: 3, name: "AR1C1" },
    { _id: 4, name: "AR1C2" },
    { _id: 5, name: "AR2C1" },
    { _id: 6, name: "AR2C2" },
    { _id: 7, name: "AR3C1" },
    { _id: 8, name: "AR3C2" },
    { _id: 9, name: "AR4C1" },
    { _id: 10, name: "AR4C2" },
    { _id: 11, name: "AR5C1" },
    { _id: 12, name: "AR5C2" },
    { _id: 13, name: "AR6C1" },
    { _id: 14, name: "AR6C2" },
    { _id: 15, name: "AR7C2" },
    { _id: 16, name: "AR8C1" },
    { _id: 17, name: "AR8C2" },
    { _id: 18, name: "B0" },
    { _id: 20, name: "BR1C1" },
    { _id: 21, name: "BR1C2" },
    { _id: 22, name: "BR1C3" },
    { _id: 23, name: "BR1C4" },
    { _id: 24, name: "BR1C5" },
    { _id: 25, name: "BR2C1" },
    { _id: 26, name: "BR2C2" },
    { _id: 27, name: "BR2C3" },
    { _id: 28, name: "BR2C4" },
    { _id: 29, name: "BR2C5" },
    { _id: 121, name: "BR3C1" },
    { _id: 122, name: "BR3C2" },
    { _id: 123, name: "BR3C3" },
    { _id: 124, name: "BR3C4" },
    { _id: 125, name: "BR4C1" },
    { _id: 126, name: "BR4C2" },
    { _id: 127, name: "BR4C3" },
    { _id: 128, name: "BR4C4" },
    { _id: 129, name: "BR5C1" },
    { _id: 130, name: "BR5C2" },
    { _id: 131, name: "BR5C3" },
    { _id: 132, name: "BR5C4" },
    { _id: 133, name: "BR6C1" },
    { _id: 134, name: "BR6C2" },
    { _id: 135, name: "BR6C3" },
    { _id: 136, name: "BR6C4" },
    { _id: 137, name: "BR7C1" },
    { _id: 138, name: "BR7C2" },
    { _id: 139, name: "BR7C3" },
    { _id: 30, name: "C0" },
    { _id: 31, name: "CR1C1" },
    { _id: 32, name: "CR1C2" },
    { _id: 33, name: "CR2C1" },
    { _id: 34, name: "CR2C2" },
    { _id: 35, name: "CR3C1" },
    { _id: 36, name: "CR3C2" },
    { _id: 37, name: "CR4C1" },
    { _id: 38, name: "CR4C2" },
    { _id: 41, name: "CR5C1" },
    { _id: 42, name: "CR5C2" },
    { _id: 43, name: "CR5C3" },
    { _id: 44, name: "CR6C1" },
    { _id: 45, name: "CR6C2" },
    { _id: 46, name: "CR6C3" },
    { _id: 47, name: "CR7C1" },
    { _id: 48, name: "CR7C2" },
    { _id: 49, name: "CR8C2" },
    { _id: 51, name: "D0" },
    { _id: 52, name: "D1" },
    { _id: 53, name: "D2" },
    { _id: 54, name: "D3" },
    { _id: 55, name: "D4" },
    { _id: 56, name: "D5" },
    { _id: 57, name: "D6" },
    { _id: 58, name: "D7" },
    { _id: 59, name: "D8" },
    { _id: 62, name: "E1" },
    { _id: 63, name: "E2" },
    { _id: 64, name: "E3" },
    { _id: 65, name: "E4" },
    { _id: 66, name: "E5" },
    { _id: 67, name: "E6" },
    { _id: 68, name: "E7" },
    { _id: 61, name: "E10" },
    { _id: 69, name: "F" },
    { _id: 71, name: "F0" },
    { _id: 72, name: "G1" },
    { _id: 73, name: "G2" },
    { _id: 74, name: "G3" },
    { _id: 75, name: "G4" },
    { _id: 76, name: "H1" },
    { _id: 77, name: "H2" },
    { _id: 78, name: "I1" },
    { _id: 79, name: "I2" },
    { _id: 81, name: "J0" },
    { _id: 82, name: "J1" },
    { _id: 83, name: "J2" },
    { _id: 84, name: "J3" },
    { _id: 85, name: "J4" },
    { _id: 86, name: "J5" },
    { _id: 87, name: "J6" },
    { _id: 88, name: "J7" },

    { _id: 92, name: "K1" },
    { _id: 93, name: "K2" },
    { _id: 94, name: "K3" },
    { _id: 95, name: "K4" },
    { _id: 96, name: "K5" },
    { _id: 97, name: "K6" },
    { _id: 98, name: "K7" },
    { _id: 99, name: "L1" },
    { _id: 100, name: "L2" },
  ],

  TYPE: [
    { _id: 1, name: "Tab" },
    { _id: 2, name: "Cap" },
    { _id: 3, name: "Cream" },
    { _id: 4, name: "Drops" },
    { _id: 5, name: "General" },
    { _id: 6, name: "Syp" },
    { _id: 7, name: "Inj" },
    { _id: 8, name: "Oint" },
    { _id: 9, name: "Sol" },
    { _id: 10, name: "Surgical" },
    { _id: 11, name: "Soap" },
    { _id: 12, name: "Shampoo" },
    { _id: 13, name: "Spray" },
    { _id: 14, name: "Oil" },
    { _id: 15, name: "ORS" },
    { _id: 16, name: "Powder" },
  ],
  defaultType: [{ _id: 1, name: "Tab" }],
};

export function getItemData() {
  return {
    RACK_LOCATION: data.RACK_LOCATION,
    STOCK_LOCATION: data.STOCK_LOCATION,
    TYPE: data.TYPE,
    defaultType: data.defaultType,
  };
}
