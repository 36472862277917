import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import { toast } from "react-toastify";
import moment from "moment";
//For Memorization
import { createSelector } from "reselect";

//using slice is much more easier than creationg actions and reeducers saperately
const slice = createSlice({
  name: "items",
  initialState: {
    list: [],
    add_stock_list: [],
    loading: false,
    lastFetch: null,
  },
  reducers: {
    // it uses mmer internally so we can write not worry about immutable coding
    itemsRequested: (items, action) => {
      items.loading = true;
    },
    itemsRequestFailed: (items, action) => {
      items.loading = false;
    },

    itemsReceived: (items, action) => {
      items.list = action.payload;
      items.loading = false;
      items.lastFetch = Date.now(); // Current time as a timestamp
    },
    itemAdded: (items, action) => {
      items.list.push(action.payload);
      toast.success(`Item ${action.payload.item_name} Added successfully`, {
        autoClose: 2000,
      });
    },
    itemAddFailed: (items, action) => {
      toast.error(`Item ${action.payload.item_name} Add Failed, Try Again...`);
    },

    itemUpdated: (items, action) => {
      const index = items.list.findIndex(
        (i) => i.item_id === action.payload.item_id
      );
      items.list[index] = action.payload;
      toast.success(`Item ${action.payload.item_name} Updated successfully`, {
        autoClose: 2000,
      });
    },
    itemRemoved: (items, action) => {
      items.list = items.list.filter(
        (i) => i.item_id !== action.payload.item_id
      );
      toast.success("ITEM DELETED.");
    },
    itemRemoveFailed: (items, action) => {
      toast.error("ITEM DELETE FAILED.");
    },
    QTYReducedOnOrderCreate: (items, action) => {
      action.payload.forEach((oi) => {
        const index = items.list.findIndex(
          (i) => i.item_id === parseInt(oi.ID)
        );
        //console.log(index);
        let qty = parseInt(items.list[index].item_qty);
        //console.log(qty);
        items.list[index].item_qty = (qty - parseInt(oi.ORDER_QTY)).toString();
      });
    },

    clear_stock_list: (items, action) => {
      items.add_stock_list = [];
    },

    //TODO make reducers for add to stock
    add_stock_to_list: (items, action) => {
      //check if item already in list, if yes throw error
      if (items.add_stock_list?.find((i) => i.ID == action.payload.ID)) {
        toast.error(`Item ${action.payload.NAME} already in Add Stock List.`);
        return;
      }
      if (items.add_stock_list) {
        items.add_stock_list.push(action.payload);
      }

      // list.total = calculateStockTotal(list);

      toast.success(`Item ${action.payload.NAME} added to stock update list.`, {
        autoClose: 2000,
      });
    },
    remove_stock_from_list: (items, action) => {
      items.add_stock_list = items.add_stock_list.filter(
        (s) => s.ID !== action.payload
      );
      toast.success("ITEM REMOVED.");
    },
    update_stock_items_in_list: (items, action) => {
      try {
        const index = items.add_stock_list.findIndex(
          (s) => s.ID === action.payload.ID
        );
        items.add_stock_list[index] = action.payload;
      } catch (e) {
        toast.error("Error Updating Stock Item");
      }
    },
    update_stock_in_list: (list, action) => {
      try {
        const index = list.items.findIndex((c) => c.ID === action.payload.ID);
        list.items[index] = action.payload;
        list.total =
          parseInt(list.total) +
          parseInt(action.payload.SELLING_PRICE) *
            parseInt(action.payload.ORDER_QTY) -
          parseInt(list.items[index].SELLING_PRICE) *
            parseInt(list.items[index].ORDER_QTY);
        toast.success("List Updated", { autoClose: 2000 });
      } catch (e) {
        toast.error("Error Updating List Item");
      }
    },
  },
});

const calculateStockTotal = (cart) => {
  return parseInt(
    cart.items
      .reduce(function myFunc(cartTotal, num) {
        return (
          cartTotal +
          parseInt(num.SELLING_PRICE * num.ORDER_QTY) /
            parseInt(num.TABLETS_IN_ONE_STRIP)
        );
      }, 0)
      .toFixed(2)
  );
};

export const {
  itemsReceived,
  itemsRequested,
  itemsRequestFailed,
  itemAdded,
  itemAddFailed,
  itemUpdated,
  itemRemoved,
  itemRemoveFailed,
  QTYReducedOnOrderCreate,
  add_stock_to_list,
  clear_stock_list,
  remove_stock_from_list,
  update_stock_items_in_list,
  update_stock_in_list,
} = slice.actions;
export default slice.reducer;

//Action Creators
const url = "/medicines";

export const getAllItems = () => (dispatch, getState) => {
  // const { lastFetch } = getState().entities.items;
  // const diffInMinutes = moment().diff(moment(lastFetch), "minutes");
  // if (diffInMinutes < 2) {
  //   return;
  // }

  // Implement SSE handler, which will fire this action when there is a change in db. SSE event will be fired from backend If it detacts change on this API from other client

  console.log("Making API call>>>");
  dispatch(
    apiCallBegan({
      url,
      onStart: itemsRequested.type,
      onSuccess: itemsReceived.type, //"items/itemsReceived",
      onError: itemsRequestFailed.type,
    })
  );
};

export const addNewItem = (item) => {
  if (item.item_exp.split("-")[2].length == 4) {
    let itemStr = item.item_exp.split("-");
    item.item_exp = `${itemStr[2]}-${itemStr[1]}-${itemStr[0]}`;
  }
  return apiCallBegan({
    url,
    method: "post",
    data: item,
    onSuccess: itemAdded.type,
    onError: itemAddFailed.type,
  });
};

export const updateItem = (item) => {
  console.log("item :>");
  console.log(item);
  if (item.item_exp.split("-")[2].length == 4) {
    let itemStr = item.item_exp.split("-");
    item.item_exp = `${itemStr[2]}-${itemStr[1]}-${itemStr[0]}`;
  }
  return apiCallBegan({
    url: url + "/" + item.item_id,
    method: "put",
    data: item,
    onSuccess: itemUpdated.type, //"items/itemsReceived",
  });
};
export const deleteItem = (ID) =>
  apiCallBegan({
    url: url + "/" + ID,
    method: "delete",
    onSuccess: itemRemoved.type, //"items/itemsReceived",
    onError: itemRemoveFailed.type,
  });

export const reduceQTYOnOrderCreate = (orderItems) => (dispatch, getState) => {
  dispatch(QTYReducedOnOrderCreate(orderItems));
};

//Selectors

// export const getSelectedPatients = (state, status) =>
//   state.entities.items.filter((i) => i.state === status);

export const getSelectedItems = (type) =>
  createSelector(
    (state) => state.entities.items.list,
    (items) => items.filter((i) => i.item_type === type)
  );

// export const getAddStockList = () =>
//   createSelector((state) => state.entities.items.add_stock_list);

export const getAddStockList = (state) => state.entities.items.add_stock_list;
