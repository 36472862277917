import React, { Component, useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  getCurrentDate,
  getCurrentFormattedDateTime,
  getCurrentDateWithSlash,
} from "../../../utils/myDateTime";
import { toast } from "react-toastify";
import {
  createOrder,
  generateBillReceipts,
  Orders,
  clearErrors,
  // for debugging
  increaseOrderCreate,
  increaseOrderCreateCurrentPatient,
  increasePatientOrderCreatedStatus,
} from "../../../store/ordersDux";
import { update_total, empty_cart, Cart } from "../../../store/cartDux";
import {
  updatePatientStatus,
  updateCurrentPatient,
  updatePreviousPatient,
  addNewPatient,
  AllPatients,
  Patients,
  CurrentPatient,
} from "../../../store/patientsDux";
import _ from "lodash";
import { ReactComponent as AddOTCPatientIcon } from "../../../images/add_OTC_patient.svg";
import { ReactComponent as AddDebtIcon } from "../../../images/addDebit.svg";
import { ReactComponent as AlertIcon } from "../../../images/errorIcon.svg";

import EditCartItem from "./EditCartItem";

function NewCart() {
  const dispatch = useDispatch();

  const [showOTCPatient, setShowOTCPatient] = useState(false);
  const [OTCPatientName, setOTCPatientName] = useState("");
  const [showAddDebt, setShowAddDebt] = useState(false);
  const [patientDebt, setPatientDebt] = useState(0);

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  const allPatients = AllPatients();
  const currentPatient = CurrentPatient();
  const orders = Orders();
  const patients = Patients();

  const cart = Cart();

  window.onbeforeunload = function () {
    console.log("Page Refreshed clearing errors");
    // dispatch(clearErrors());

    // setShowUserError(false);
    // setShowSuccessMessage(false);
  };

  //on orderId change/on New order creation
  useEffect(() => {
    console.log("lastOrderID : ");
    const lastOrderID = orders.lastOrder.order_id;
    console.log(lastOrderID);
    console.log("propsOrderID : ");
    console.log(orders.orderID);
    if (orders.orderCreateSuccess && !orders.loadingOrderCreate) {
      let currentPatientObj = {};
      Object.assign(currentPatientObj, currentPatient);
      currentPatientObj["status"] = "OrderCreated";
      delete currentPatientObj.debt;
      currentPatientObj.debt = patientDebt;
      console.log("currentPatient>>>");
      console.log(currentPatient);
      dispatch(increaseOrderCreate());
      if (currentPatient.name) {
        dispatch(increaseOrderCreateCurrentPatient());
        dispatch(updatePatientStatus(currentPatientObj));
      }
    }
  }, [orders.list.length]);

  //on current patient status change/on status change to orderCreated
  useEffect(() => {
    if (
      patients.updatePatientStatusSuccess &&
      !patients.loadingUpdatePatient &&
      orders.orderCreateSuccess &&
      !orders.loadingOrderCreate
    ) {
      dispatch(increasePatientOrderCreatedStatus());
      dispatch(clearErrors());
      console.log("useEffect OrderCreated");
      dispatch(empty_cart());
      setPatientDebt(0);
      dispatch(generateBillReceipts());
      dispatch(updateCurrentPatient({}));
    }
  }, [patients.currentPatient.status]);

  const toggleOTCPatient = () => {
    setShowOTCPatient(!showOTCPatient);
  };
  const toggleAddDebt = () => {
    setShowAddDebt(!showAddDebt);
  };
  const handleOTCPatientNameChange = (e) => {
    setOTCPatientName(e.currentTarget.value);
  };
  const handleAddDebtChange = (e) => {
    setPatientDebt(parseInt(e.currentTarget.value));
  };
  const AddDebt = () => {
    setTimeout(() => setShowAddDebt(false), 2000);
  };
  const AddOTCPatient = () => {
    let currentDate = getCurrentDate();
    const caseNumber =
      currentDate.slice(6, 8) +
      currentDate.slice(4, 6) +
      currentDate.slice(2, 4) +
      (allPatients.length + 1).toString().padStart(4, "0"); //Not a good way to calculate new case number, need to have a single source of truth(i.e. from DB)
    const OTCPatient = {
      case_number: caseNumber,
      name: OTCPatientName,
      type: "OTC",
      creation_date: getCurrentDate().toString(),
      arrival_time: getCurrentFormattedDateTime(),
      status: "InMedical",
      mobile_number: "0000000000",
      age: "0",
      weight: "0",
      doctor_fees: 0,
      debt: 0,
    };

    dispatch(addNewPatient(OTCPatient));

    //hide OTC patient Add input box
    setTimeout(() => setShowOTCPatient(false), 2000);
  };

  //ensure cart is valid before creating order
  const handleOrderSubmit = (totalPayableAmount) => {
    if (isSubmitDisabled) return;
    if (!_.isEmpty(currentPatient)) {
      dispatch(clearErrors());
      console.log("currentpatient");
      console.log(currentPatient);
      console.log(cart); // Need to Store Order nto DB here
      let obj = {};

      //const { ID, ORDER_QTY, SELLING_PRICE } = props.cart;
      if (cart.items.length > 0) {
        //check if Order QTY is < available QTY
        let validQTY = true;
        cart.items.forEach((oi) => {
          if (parseInt(oi.ORDER_QTY) > parseInt(oi.QTY)) {
            validQTY = false;

            toast.error(
              `Not enough Item ${oi.NAME} Left in Stock. Please Add or Select New Batch.`
            );
          }
        });
        if (!validQTY) return;
        setIsSubmitDisabled(true);
        setTimeout(() => {
          setIsSubmitDisabled(false);
        }, 5000);
        submitOrder(totalPayableAmount);
      } else {
        toast.error("Plese add items to cart");
      }
    } else {
      toast.error("Please Select the Patient First");
      return;
    }
  };

  //create new order
  const submitOrder = async (totalPayableAmount) => {
    const orderItems = cart.items.reduce(
      (acc, ci) => [
        ...acc,
        {
          [`ID`]: `${ci.ID}`,
          [`ORDER_QTY`]: `${ci.ORDER_QTY}`,
          [`PPU`]: `${(ci.SELLING_PRICE / ci.TABLETS_IN_ONE_STRIP).toFixed(1)}`,
        },
      ],
      []
    );
    let order = {};
    try {
      const patientID = currentPatient.case_number;
      const previousDebit = currentPatient.debt || 0;
      const dateTime = getCurrentFormattedDateTime();
      const cartTotal = parseInt(
        cart.items.reduce(function myFunc(cartTotal, num) {
          return (
            cartTotal +
            parseFloat(
              (
                (num.SELLING_PRICE / num.TABLETS_IN_ONE_STRIP).toFixed(1) *
                num.ORDER_QTY
              ).toFixed(1)
            )
          );
        }, 0.0)
      );
      const paidAmount = totalPayableAmount;

      order = {
        orderItems,
        patientID,
        previousDebit,
        cartTotal,
        dateTime,
        paidAmount,
      };
    } catch (e) {
      toast.error("Please Select the Patient First");
      return;
    }

    dispatch(createOrder(order));
  };

  // const togglePrinter = () => {
  //   setPrintWithBLE(!printWithBLE);
  // };
  // const editCartItem = (item) => {
  //   console.log(item);
  // };

  const doctorsBill = currentPatient.doctor_fees || 0;
  const medicalBill = parseInt(
    cart.items.reduce(function myFunc(cartTotal, num) {
      return (
        cartTotal +
        parseFloat(
          (
            (num.SELLING_PRICE / num.TABLETS_IN_ONE_STRIP).toFixed(1) *
            num.ORDER_QTY
          ).toFixed(1)
        )
      );
    }, 0.0)
  );
  const currentDebit = patientDebt;
  const previousDebit = currentPatient.debt || 0;
  const totalPayableAmount = parseInt(
    medicalBill + doctorsBill + previousDebit - currentDebit
  );
  const totalPayableAmountRoundOffed =
    totalPayableAmount - (totalPayableAmount % 10);

  return (
    <div style={{ padding: "1em", zoom: 0.8 }}>
      <div>
        {showOTCPatient ? (
          <div style={{ display: "flex" }}>
            <input
              id="OTCPatientName"
              className="searchBar"
              type="text"
              width="400px"
              placeholder="Add Patient Name!!"
              onChange={(e) => handleOTCPatientNameChange(e)}
              autoFocus={true}
            ></input>
            <button name="addOTCPatient" onClick={() => AddOTCPatient()}>
              ADD
            </button>
          </div>
        ) : (
          <div></div>
        )}
        {showAddDebt ? (
          <div style={{ display: "flex" }}>
            <input
              id="AddDebt"
              className="searchBar"
              type="text"
              width="400px"
              placeholder="Add Amount to carry forward!!"
              onChange={(e) => handleAddDebtChange(e)}
              autoFocus={true}
            ></input>
            <button name="addDebt" onClick={() => AddDebt()}>
              ADD
            </button>
          </div>
        ) : (
          <div></div>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              marginTop: "10px",
              marginRight: "30px",
              marginLeft: "10px",
            }}
          >
            <b>
              {currentPatient.name
                ? "Creating Order For  " + currentPatient.name
                : "Please Select Patient First!!"}
            </b>
          </div>
          <div style={{ display: "flex" }}>
            <AddOTCPatientIcon
              onClick={(e) => toggleOTCPatient()}
              className="cartIcons"
            />
            {/* {printWithBLE ? (
                <div style={{ display: "flex" }}>
                  <BLEIcon onClick={togglePrinter} />
                  <BLEPrintReceipt
                    currentPatientToPrint={currentPatientToPrint}
                  />
                </div>
              ) : (
                <div style={{ display: "flex" }}>
                  <USBIcon onClick={togglePrinter} />
                  <USBPrintReceipt
                    currentPatientToPrint={currentPatientToPrint}
                  />
                </div>
              )} */}
            <AddDebtIcon
              onClick={(e) => toggleAddDebt()}
              className="cartIcons"
            />
          </div>
        </div>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th>EDIT</th>
            <th style={{ width: "250px" }}>NAME</th>
            <th style={{ textAlign: "right" }}>QTY</th>
            <th style={{ textAlign: "right", width: "130px" }}>PPU</th>
            <th style={{ textAlign: "right", width: "100px" }}>TOTAL</th>
          </tr>
        </thead>
        <tbody>
          {cart.items.map((i) => (
            <tr key={i.ID}>
              <td>
                {/* <EditCart
                    style={{
                      cursor: "pointer",
                      onMouseOver: "style.color = 'red'",
                      height: "1.5rem",
                    }}
                    width="30"
                    onClick={() => editCartItem(i)}
                  /> */}
                <EditCartItem item={i} />
              </td>
              <td>{i.NAME}</td>
              <td style={{ textAlign: "right" }}>{i.ORDER_QTY}</td>
              <td style={{ textAlign: "right" }}>
                {parseInt(i.ORDER_QTY) > parseInt(i.QTY) ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <AlertIcon fill="#dc3545" height="25px" width="30px" />₹
                    {(i.SELLING_PRICE / i.TABLETS_IN_ONE_STRIP).toFixed(1)}
                  </div>
                ) : (
                  "₹" + (i.SELLING_PRICE / i.TABLETS_IN_ONE_STRIP).toFixed(1)
                )}
              </td>
              <td style={{ textAlign: "right" }}>
                ₹
                {(
                  (i.SELLING_PRICE / i.TABLETS_IN_ONE_STRIP).toFixed(1) *
                  i.ORDER_QTY
                ).toFixed(1)}
              </td>
            </tr>
          ))}
          <tr>
            <td></td>
            <td>Total Items</td>
            <td>
              <b>{cart.items.length}</b>
            </td>
            <td>Medicine Bill</td>
            <td style={{ textAlign: "right" }}>
              <b>₹{medicalBill}</b>
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td>Previous Debit</td>
            <td style={{ textAlign: "right" }}>
              <b>₹{previousDebit}</b>
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td>Doctor's Bill</td>
            <td style={{ textAlign: "right" }}>
              <b>₹{doctorsBill}</b>
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td>Current Debit</td>
            <td style={{ textAlign: "right" }}>
              <b>₹{currentDebit}</b>
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td>Total Amount</td>
            <td style={{ textAlign: "right" }}>
              <b>₹{totalPayableAmount}</b>
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td>
              <b>Collection Amount</b>
            </td>
            <td style={{ textAlign: "right" }}>
              <b>₹{totalPayableAmountRoundOffed}</b>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <button
          onClick={(e) => {
            dispatch(clearErrors());
            console.log("useEffect OrderCreated");
            dispatch(empty_cart());
            setPatientDebt(0);
            dispatch(updateCurrentPatient({}));
          }}
          style={{ width: "30%" }}
        >
          Empty Cart
        </button>
        <button
          onClick={(e) => handleOrderSubmit(totalPayableAmountRoundOffed)}
          style={{ width: "50%" }}
        >
          Submit Order
        </button>
      </div>
    </div>
  );
}

export default NewCart;
