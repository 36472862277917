import React, { Component, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import authService from "../authService";
import { getAllItems } from "../../store/itemsDux";
import { getTodaysOrders } from "../../store/ordersDux";
import { getPatientsInQueue } from "../../store/patientsDux";
import { getFormattedDate } from "../../utils/myDateTime";
import axios from "axios";

const AuthSSE = () => {
  console.log("axios.defaults.baseURL>>", axios.defaults.baseURL);

  const dispatch = useDispatch();

  const userDetails = authService.getCurrentUser();
  userDetails.groupId = 0; //Need to make user groups to share SSE channel updates between them
  console.log(userDetails);

  console.log(`authSSE is mounted: `);
  let status = false;
  let intervalId = false;

  const fromCurrentUser = () => {};

  const fireActions = (event) => {
    status = true;
    console.log(event.data);
    console.log(userDetails.userId);
    const refreshList = JSON.parse(event.data);
    console.log(refreshList.sourceUserId == userDetails.userId);
    if (refreshList.sourceUserId == userDetails.userId) return;

    if (intervalId) clearInterval(intervalId);

    // Fire actions based on refresh list sent by server
    const d = new Date();
    const fdate = getFormattedDate(d);
    if (refreshList.medicines) {
      console.log("getAllItems>>>");
      dispatch(getAllItems());
    }
    if (refreshList.patientQueue) {
      console.log("getPatientsInQueue>>>");
      dispatch(getPatientsInQueue(fdate));
    }
    if (refreshList.orders) {
      console.log("getTodaysOrders>>>");
      dispatch(getTodaysOrders(fdate));
    }
  };

  useEffect(() => {
    if (status) return;

    console.log(`authSSE Registering: `);
    // let eventSource = {};
    if (intervalId) clearInterval(intervalId);
    const newSSEConnection = () => {
      const eventSource = new EventSource(
        `${axios.defaults.baseURL}/authSSE/${
          userDetails.groupId
        }?token=${authService.getJWT()}`
      );

      status = true;

      eventSource.onopen = function () {
        status = true;
        console.log(`>>>>>authSSE Registered: `);
        if (intervalId) clearInterval(intervalId);
      };

      eventSource.onmessage = (event) => fireActions(event);

      eventSource.addEventListener("test", (event) => fireActions(event));

      eventSource.addEventListener("healthcheck", (event) =>
        fireActions(event)
      );
      eventSource.addEventListener("medicines", (event) => fireActions(event));

      eventSource.addEventListener("patientQueue", (event) =>
        fireActions(event)
      );

      eventSource.addEventListener("orders", (event) => fireActions(event));

      eventSource.onerror = function () {
        status = false;
        console.log("Server closed connection");
        eventSource.close();
        console.log(intervalId);
        if (intervalId) clearInterval(intervalId);
        intervalId = setInterval(() => {
          newSSEConnection();
        }, 30000);
        console.log(intervalId);
      };
      window.onbeforeunload = function () {
        status = false;
        console.log("Page Refreshed closing SSE connection");
        if (intervalId) clearInterval(intervalId);

        eventSource.close();
      };
    };
    console.log(`authSSE Registering: USe Effect `);

    newSSEConnection();
  }, []);
  return <></>;
};

export default AuthSSE;
