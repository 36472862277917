import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Modal, Button, InputGroup } from "react-bootstrap";

import {
  update_item_in_cart,
  remove_item_from_cart,
} from "../../../store/cartDux";

import { ReactComponent as EditCart } from "../../../images/edit-black.svg";
import { toast } from "react-toastify";
import { updateItem } from "../../../store/itemsDux";
const EditCartItem = (props) => {
  const [isEditCartVisible, setIsEditCartVisible] = useState(false);
  const [itemUpdatedQTY, setItemUpdatedQTY] = useState(0);

  const showEditCart = () => {
    setIsEditCartVisible(true);
    console.log(props.item);
  };
  const hideEditCart = () => {
    setIsEditCartVisible(false);
  };
  const updateItemQTY = (e) => {
    setItemUpdatedQTY(e.target.value);
  };
  const dispatch = useDispatch();

  const updateCartItem = () => {
    if (itemUpdatedQTY > 0) {
      const updatedItem = { ...props.item, ORDER_QTY: itemUpdatedQTY };
      if (parseInt(itemUpdatedQTY) > parseInt(updatedItem.QTY))
        return toast.error(
          `Not enough Item ${updatedItem.NAME} Left in Stock. Please Add or Select New Batch.`
        );
      dispatch(update_item_in_cart(updatedItem));
      setItemUpdatedQTY(0);
      hideEditCart();
    } else toast.error("Invalid QTY");
  };

  const deleteCartItem = () => {
    const deleteItem = props.item;
    dispatch(remove_item_from_cart(deleteItem));
    setItemUpdatedQTY(0);
    hideEditCart();
  };

  return (
    <div>
      <EditCart
        style={{
          cursor: "pointer",
          onMouseOver: "this.style.color = 'red'",
          height: "1.5rem",
        }}
        width="30"
        onClick={showEditCart}
      />

      <Modal
        show={isEditCartVisible}
        keyboard={true}
        onHide={hideEditCart}
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Cart Item: <b>{props.item.NAME}</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <b>QTY</b>{" "}
          <input
            type="number"
            className="form-control"
            onChange={updateItemQTY}
          ></input>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-danger" onClick={deleteCartItem}>
            Delete
          </Button>
          <Button onClick={updateCartItem}>Update</Button>
          <Button className="btn btn-secondary" onClick={hideEditCart}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EditCartItem;
