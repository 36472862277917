import React from "react";
import _ from "lodash"; // library with many utilities
import PropTypes from "prop-types";

//Stateless Function
const Pagination = (props) => {
  // props has itemsCount and pageSize

  //destructure props
  const { itemsCount, pageSize, onPageChange } = props;
  let { currentPage } = props;
  const pagesCount = Math.ceil(itemsCount / pageSize);
  //generate an Array and map it to have multiple paginations
  let pages = _.range(1, pagesCount + 1);

  if (pagesCount === 1) {
    if (currentPage > 1) {
      onPageChange(1);
      return null;
    } else {
      return null;
    }
  } // show nothing if there is only one page
  if (pagesCount > 3) pages = [1, 2, 3, "...", pagesCount];
  return (
    <nav>
      <ul className="pagination mb-0">
        <li className="page-item">
          <div className="page-link" onClick={() => onPageChange("Prev")}>
            Prev
          </div>
        </li>
        {pages.map((page) => (
          // dynamically render a class
          <li
            key={page}
            className={currentPage === page ? " page-item active" : "page-item"}
            style={{ height: "42px" }}
          >
            {/*Raising an Event*/}
            <div className="page-link" onClick={() => onPageChange(page)}>
              {page}
            </div>
          </li>
        ))}
        <li className="page-item">
          <div className="page-link" onClick={() => onPageChange("Next")}>
            Next
          </div>
        </li>
      </ul>
    </nav>
  );
};

//Type Checking with propTypes: This will raise a warning if the passed propTypes are not as required. Only when in development
Pagination.propTypes = {
  itemsCount: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  currentPage: PropTypes.any.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default Pagination;
