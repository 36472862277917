import http from "./httpService";
import { API_Endpoint } from "../config.json";
//const bcrypt = require("bcrypt");

const API_Endpoint_user = "/users";

export function register(user) {
  //console.log("USER DATA at  User Service" + user.toString() + user.firstname);
  delete user.confirmpassword;

  // // thinking of sending hashed data before sending, but no use. Have to trust ssl/tls for

  // const salt = await bcrypt.genSalt(15);
  // const hashedPWD = await bcrypt.hash(user.password, salt);
  // user.password = hashedPWD;

  return http.post(API_Endpoint_user, user);
}
