import { getAllItems } from "../../store/itemsDux";
import { useDispatch, useSelector } from "react-redux";

export const UnAuthSSE = () => {
  console.log(`unAuthSSE Registered: `);

  //   const eventSource = new EventSource(`${process.env.REACT_APP_API_URL}/unauthSSE`);

  //   function updateMessage(message) {
  //     console.log(`New SSE Message: ${message}`);
  //   }

  //   eventSource.onmessage = function (event) {
  //     updateMessage(event.data);
  //   };

  //   eventSource.onerror = function () {
  //     updateMessage("Server closed connection");
  //     eventSource.close();
  //   };
};

export default UnAuthSSE;
