import React, { Component } from "react";
import { toast } from "react-toastify";
class BLEPrint extends Component {
  constructor() {
    super();

    //this.state.exampleDevice = exampleDevice;
    //setTimeout(() => this.enableBluetooth(), 5000);
  }
  state = {
    connected: false,
    status: "",
    supportsBluetooth: false,
    isDisconnected: true,
    characteristic: {},
    permittedDevices: [],
    device: null,
  };

  componentDidMount() {
    if (navigator.bluetooth) {
      this.setState({ supportsBluetooth: true });
      //setTimeout(() => this.clickButton(), 5000);
    }
  }
  clickButton = () => {
    let click_event = new CustomEvent("click");
    let btn_element = document.getElementById("clickCreateDevice");
    btn_element.dispatchEvent(click_event);
    //btn_element.click();
  };
  createDevice = async () => {
    const primary_service_uuid = 0x18f0;
    let device;
    try {
      if (this.state.device === null)
        device = await navigator.bluetooth.requestDevice({
          filters: [{ name: "BlueTooth Printer" }], //acceptAllDevices: true, //
          optionalServices: [primary_service_uuid],
        });
      else {
        device = this.state.device;
        console.log("Device Exist");
      }
      this.setState({ isDisconnected: false, device });

      // Add an event listener to detect when a device disconnects
      device.addEventListener("gattserverdisconnected", this.onDisconnected);
      this.connectToDeviceAndSubscribeToUpdates(device);
    } catch (error) {
      console.log(`There was an error while creating device: ${error}`);
    }
  };
  getDevices = async () => {
    this.connectToDeviceAndSubscribeToUpdates(this.state.device);
    // let permittedDevices = navigator.bluetooth.getDevices(); //getAvailability(); //getDevices();
    // this.setState({ permittedDevices });
  };

  onDisconnected = (event) => {
    alert(`The device ${event.target} is disconnected`);
    this.setState({ isDisconnected: true });
  };

  /**
   * Attempts to connect to a Bluetooth device and subscribe to
   * battery level readings using the battery service.
   */
  connectToDeviceAndSubscribeToUpdates = async (device) => {
    try {
      // Search for Bluetooth devices that advertise a battery service
      const primary_service_uuid = 0x18f0;
      const char_uuid = 0x2af1;

      const BLUETOOTH_SPP_UUID = "00001101-0000-1000-8000-00805f9b34fb";
      //let device = this.state.device;

      // Try to connect to the remote GATT Server running on the Bluetooth device
      const server = await device.gatt.connect();

      // Get the battery service from the Bluetooth device

      const service = await server.getPrimaryService(primary_service_uuid);
      console.log("service : ");
      console.log(service);

      // Get the battery level characteristic from the Bluetooth device
      const characteristic = await service.getCharacteristic(char_uuid);
      console.log("characteristic : ");
      console.log(characteristic);
      this.setState({ characteristic });
    } catch (error) {
      console.log(`There was an error: ${error}`);
      toast.error(error.message);
    }
  };

  printData = () => {
    let str = `CaseNumber  Date  Time\n___________________\nName |      Amount|\nitem |       $50.0|\n\n`;

    //const str = this.getReceipt;
    console.log(str);
    let buffer = new ArrayBuffer(str.length);
    let dataView = new DataView(buffer);

    for (var i = 0; i < str.length; i++) {
      dataView.setUint8(i, str.charAt(i).charCodeAt());
    }
    console.log(buffer);
    try {
      this.state.characteristic.writeValue(buffer);
    } catch (e) {
      toast.error(e.message);
    }

    // window.print();
  };

  getReceipt = () => {
    return (
      <div>
        <table>
          <thead>
            <tr>
              <td>Name</td>
              <td>Amount</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Name</td>
              <td>Amount</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };
  render() {
    const { supportsBluetooth, isDisconnected, permittedDevices } = this.state;
    return (
      <div>
        <p>Web Bluetooth</p>
        <p>{isDisconnected ? "Not Connected" : "Connected"}</p>
        {this.state.status}
        {supportsBluetooth && isDisconnected && (
          <button
            id="clickCreateDevice"
            onClick={
              this.state.device !== null ? this.getDevices : this.createDevice
            }
          >
            Connect to a Bluetooth device
          </button>
        )}
        <button onClick={this.printData}>Print Data</button>
        <button onClick={this.getDevices}>getDevices</button>
        All Devices: {JSON.stringify(permittedDevices)}
        {!supportsBluetooth && (
          <p>This browser doesn't support the Web Bluetooth API</p>
        )}
      </div>
    );
  }
}

export default BLEPrint;
