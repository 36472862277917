import React, { Component } from "react"; // simple react snippet shortcut: imrc

class Counter extends Component {
  // simple react snippet shortcut to generate class component: cc
  state = {};

  constructor(props) {
    super();
    //console.log(props);
  }

  //Event Handlers
  // handleTotalPatientClick = () => {
  //   // Use Arrow function to avoid binding this

  //   this.setState(state => {
  //     // Alter the state
  //     return {
  //       isShowingExaminedPatients: state.isShowingExaminedPatients
  //         ? false
  //         : true
  //     };
  //   });
  // };

  render() {
    const { count, title, classType, width = "40px" } = this.props;
    return (
      <div className="pr-1">
        {/* style={{ width: "80px" }} */}

        <h6>
          <b>{title}</b>
        </h6>

        <span className={"badge badge-" + classType} style={{ width }}>
          <h5>{count}</h5>
        </span>
      </div>
    );
  }
}

export default Counter;
