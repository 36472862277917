import React, { Component } from 'react';
import { connect } from "react-redux";
import Form from "../../common/form";
import Joi from "joi-browser";
import { toast } from "react-toastify";

import { add_new_row_in_rack } from "../../../store/racksDux";
class AddRackDefinition extends Form  {
    state = { 
    // Initialize properties of state object either to an empty string or some value we get from the server, coz forms are controlled elements
    data: { rackName: "", rowNumber: "", totalColumns: "" },
    errors: {}, // simplified errors object which will map the complex JOI Object
    errorMessage: {
        rackName: "rackName",
        rowNumber: "rowNumber",
        totalColumns: "totalColumns",
      },
      isRequiredFilled: this.state.isRequiredFilled,
     }
     schema = {
        rackName: Joi.string().required(),
        rowNumber: Joi.string().required(),
        totalColumns: Joi.string().required()
      };

    //determine what should happen when the form is submiited
    doSubmit = async () => {
   
        const { data } = this.state;
        this.props.add_new_row_in_rack(data)
    
    };
  

    render() { 
        return ( 
        <div style={{display:"flex", marginBottom:"10px" }}>
            <form className="form-row" onSubmit={this.handleSubmit}>
            {this.renderInput("rackName", "RackName*", "e.g. Tablets")}
            {this.renderInput("rowNumber", "RowNumber*", "e.g. 1")}
            {this.renderInput("totalColumns", "TotalColumns*", "e.g. 5")}
            {this.renderButton("Add New Row", "btn btn-primary btn-lg btn-block")}
            {this.renderErrorMessage(this.state.errorMessage)}
            </form>
        </div>
         );
    }
}

const mapStateToProps = (state) => {
    return {
      rackDefinitions: state.entities.racks.list,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
        add_new_row_in_rack: (row) => dispatch(add_new_row_in_rack(row)),
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(AddRackDefinition);
  