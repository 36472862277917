import { createSlice } from "@reduxjs/toolkit";
import {createSelector} from "reselect"
import { apiCallBegan } from "./api";
import moment from "moment";
import { toast } from "react-toastify";


//using slice is much more easier than creationg actions and reeducers saperately
const slice = createSlice({
  name: "racks",
  initialState: {
    list: [],
    loading: false,
    lastFetch: null,
  },
  reducers: {
    // it uses mmer internally so we can write not worry about immutable coding

    racksRequested: (racks, action) => {
      racks.loading = true;
    },
    racksRequestFailed: (racks, action) => {
      if (moment(racks.lastFetch).date() !== moment().date()) racks.list = [];
      racks.loading = false;
    },

    racksReceived: (racks, action) => {
      racks.list = action.payload;
      racks.loading = false;
      racks.lastFetch = Date.now(); // Current time as a timestamp
    },
    newRowAddedInRack:(racks, action) => {
      console.log("action.payload at racksDux: ")
      console.log(action.payload)
      if(action.payload.length>0)
{      racks.list = action.payload;
      racks.loading = false;
      racks.lastFetch = Date.now(); // Current time as a timestamp
      toast.success("New Row Added in Rack.")}
      else{
        toast.error("New Row Add Failed.")
      }
    },
    newRowAddFailed:(racks, action) => {
      toast.error("New Row Add Failed.")
    },
  },
});
const {
  racksRequested,
  racksReceived,
  racksRequestFailed,
  newRowAddedInRack,
  newRowAddFailed
} = slice.actions;
export default slice.reducer;

const url = "/rackDefinitions";
//Action Creators

export const getRackDefinitions = () =>(dispatch)=> {
  console.log("Inside Get Rack Definitions")
dispatch(
  apiCallBegan({
    url: url,
    onStart: racksRequested.type,
    onSuccess: racksReceived.type,
    onError: racksRequestFailed.type,
  })
)  
};


export const add_new_row_in_rack = (row) =>(dispatch)=> {
  dispatch(
    apiCallBegan({
      url: url,
      method: "post",
      data: row,
      onSuccess: newRowAddedInRack.type, //"items/itemsReceived",
      onError: newRowAddFailed.type,
    })
  )  
  };



//Selectors
export const getRackDefinitionsList=createSelector(
 state=>state.entities.racks.list,
 list=>list
)