//Middleware for async APIs
import axios from "axios";
import * as actions from "../api";

const api = ({ dispatch }) => (next) => async (action) => {
  // console.log("ACTION: ");
  // console.log(action);
  if (action.type !== actions.apiCallBegan.type) return next(action);
  next(action);
  const { url, method, data, onStart, onSuccess, onError } = action.payload;
  if (onStart) dispatch({ type: onStart });

  //Make an API Call
  if (axios.defaults.baseURL !== undefined)
    if (axios.defaults.baseURL.includes("/api"))
      try {
        const response = await axios.request({
          url,
          method,
          data,
        });
        dispatch(actions.apiCallSuccess(response.data));
        if (onSuccess) dispatch({ type: onSuccess, payload: response.data });
      } catch (error) {
        //toast.error(error.message);
        dispatch(actions.apiCallFailed(error.message));
        if (onError) dispatch({ type: onError, payload: error.message });
      }
  //Resolve

  //Reject
};

export default api;
