import { combineReducers } from "redux";
import patientsReducer from "./patientsDux";
import loginReducer from "./loginDux";
import cartReducer from "./cartDux";
import itemsReducer from "./itemsDux";
import ordersReducer from "./ordersDux";
import racksReducer from "./racksDux";
import settingsReducer from "./settingsDux";
import { withReduxStateSync } from "redux-state-sync";
import stockUpdateDuxm from "./stockUpdateDuxm";

export default withReduxStateSync(
  combineReducers({
    patients: patientsReducer,
    cart: cartReducer,
    logIn: loginReducer,
    items: itemsReducer,
    orders: ordersReducer,
    racks: racksReducer,
    settings: settingsReducer,
    stockUpdate: stockUpdateDuxm,
  })
);
