// only way to change the state of a store is Dispatch actions

// import { createStore, applyMiddleware, compose } from "redux";
// import { persistReducer } from "redux-persist";
// import rootReducer from "./rootReducer";
// import { composeWithDevTools } from "redux-devtools-extension";
// ///developmentOnly";
// import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
// import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
// import thunkMiddleware from "redux-thunk";

// const persistConfig = {
//   key: "root",
//   storage, //stateReconciler: autoMergeLevel2 // see "Merge Process" section for details.
// };

// // export default function configureStore(preloadedState) {
// const persistedReducer = persistReducer(persistConfig, rootReducer);

// const enhancers = [{ trace: true }]; //applyMiddleware(thunkMiddleware),

// const composeEnhancers = composeWithDevTools({
//   trace: true,
//   traceLimit: 25,
// });

// export default function configureStore() {
//   const store = createStore(persistedReducer, composeEnhancers()); //,
//   return store;
// }
// //}

import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { connectRouter, routerMiddleware } from "connected-react-router";
import reducer from "./reducer";
import logger from "./middleware/logger";
import toastError from "./middleware/toast";
import api from "./middleware/api";
//import func from "./middleware/func"  No need for this as thunk is there in defaultMiddleware
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { history } from "./reducer";
import {
  createStateSyncMiddleware,
  initStateWithPrevTab,
} from "redux-state-sync";
import { withReduxStateSync } from "redux-state-sync";
const persistConfig = {
  key: "root",
  //blacklist: ["entities.items.lastFetch", "entities.items.loading"],
  storage, //stateReconciler: autoMergeLevel2 // see "Merge Process" section for details.
};

const persistedReducer = persistReducer(persistConfig, reducer);
const config = {};
//const middlewares = [createStateSyncMiddleware(config)];
const middlewares = [];
export default function () {
  return configureStore({
    reducer: persistedReducer, //: withReduxStateSync(reducer),
    middleware: [
      ...middlewares,
      ...getDefaultMiddleware(),
      //createStateSyncMiddleware(config),
      api,
      logger,
      toastError,
    ],
  });
}
