import React from "react";
import { Modal, Button } from "react-bootstrap";
import { confirmable, createConfirmation } from "react-confirm";

class ComplexConfirmation extends React.Component {
  refCallback(ref) {
    this.inputRef = ref;
  }

  handleOnClick(index) {
    const { proceed } = this.props;
    return () => {
      proceed({
        button: index,
      });
    };
  }

  render() {
    const {
      cancel,
      message,
      buttons,
      show,
      enableEscape = true,
      proceed,
    } = this.props;

    return (
      <div className="static-modal">
        <Modal
          show={show}
          backdrop={enableEscape ? true : "static"}
          keyboard={enableEscape}
          onHide={cancel}
          centered
          backdrop="static"
        >
          <Modal.Header>{message}</Modal.Header>
          <Modal.Footer>
            {buttons.map((button) => {
              return (
                <Button
                  className="button-l"
                  bsstyle="default"
                  onClick={this.handleOnClick(button)} //"GenerateRegularBill"
                >
                  {button}
                </Button>
              );
            })}
            <Button onClick={cancel}>Cancel</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export const confirm = createConfirmation(confirmable(ComplexConfirmation));
