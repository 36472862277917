//Action Types
const LOG_IN = "logIn";
const LOGGED_OUT = "loggedOut";
// Action Creators
export const log_in = (patient) => ({
  type: LOG_IN,
  payload: patient,
});

// Action Creators
export const logged_out = (patient) => ({
  type: LOGGED_OUT,
  payload: patient,
});

//Reducers

const loginReducer = (state = false, action) => {
  switch (action.type) {
    case "LOG_IN":
      return !state;

    case "LOGGED_OUT":
      return state;
    default:
      return state;
  }
};

export default loginReducer;
