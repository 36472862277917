import React, { Component } from "react";
import { toast } from "react-toastify"; //toast notifications
class USBPrint extends Component {
  constructor() {
    super();

    //this.state.exampleDevice = exampleDevice;
    //setTimeout(() => this.enableBluetooth(), 5000);
  }
  state = {
    connected: false,
    supportsUSB: false,
    isDisconnected: true,
    device: null,
  };

  componentDidMount() {
    if (navigator.usb) {
      this.setState({ supportsUSB: true });
    }
  }
  createDevice = () => {
    var device;
    navigator.usb.addEventListener("connect", (event) =>
      this.onConnected(event)
    );

    navigator.usb.addEventListener("disconnect", (event) =>
      this.onDisconnected(event)
    );
    if (this.state.device === null) {
      navigator.usb
        .requestDevice({
          filters: [{ vendorId: 0x0456 }],
        })
        .then((selectedDevice) => {
          device = selectedDevice;
          device
            .open()
            .then(() => device.selectConfiguration(1))
            .then(() => this.claimInterface(device))
            .then(() => {
              toast.info("then after open");
              this.setState({ isDisconnected: false, device });
              // this.printOut(device);
            });
        });
    } else {
      device = this.state.device;
      toast.info("Device Exist");
    }
  };

  printOut = (device) => {
    let str = "Hello World HelloWorld Helloworld \n";

    let buffer = new ArrayBuffer(str.length);
    let dataView = new DataView(buffer);

    for (var i = 0; i < str.length; i++) {
      dataView.setUint8(i, str.charAt(i).charCodeAt());
    }
    if (device !== null && device !== undefined)
      device.controlTransferOut({
        requestType: "vendor",
        recipient: "endpoint",
        request: 0x22,
        value: 0x01,
        index: 0x02,
      });
    device
      .transferOut(3, dataView)
      .catch((e) => toast.error("at printOut " + e.message))
      .then((result) => {
        toast.success("printed");
      });
  };

  claimInterface = async (device) => {
    for (const config of device.configurations) {
      for (const iface of config.interfaces) {
        if (!iface.claimed) {
          toast.info("Interface no:" + iface.interfaceNumber);
          await device.claimInterface(iface.interfaceNumber);
          return true;
        }
      }
    }
    return false;
  };

  onDisconnected = (event) => {
    alert(`The device ${JSON.stringify(event)} is disconnected`);
    this.setState({ isDisconnected: true });
  };

  onConnected = (event) => {
    alert(`The device ${JSON.stringify(event)} is Connected`);
    this.setState({ isDisconnected: false });
  };

  render() {
    const { supportsUSB, isDisconnected } = this.state;
    return (
      <div>
        <div>
          <p>USB Web</p>
          <p>{isDisconnected ? "Not Connected" : "Connected"}</p>
          {supportsUSB && isDisconnected && (
            <button id="clickCreateDevice" onClick={() => this.createDevice()}>
              Connect to a USB device
            </button>
          )}
          {supportsUSB && !isDisconnected && (
            <button onClick={() => this.printOut(this.state.device)}>
              Print Data
            </button>
          )}
          {!supportsUSB && <p>This browser doesn't support the USB Web API</p>}
        </div>
      </div>
    );
  }
}

export default USBPrint;
