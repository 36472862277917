import https from "./httpService";
import { API_Endpoint } from "../config.json";
import jwtDecode from "jwt-decode";
import { toast } from "react-toastify";
import { ThemeConsumer } from "react-bootstrap/esm/ThemeProvider";
import AuthSSE from "./SSE/AuthSSE";
const API_Endpoint_auth = "/auth";
const tokenKey = "token";

https.setJWT(getJWT()); // this was a Bi-directional dependency between authService and httpService, got rid of it via a fun call

export async function login(username, password) {
  await https
    .post(API_Endpoint_auth, {
      username,
      password,
    })
    .then(({ data: jwt }) => {
      console.log(jwt);
      if (jwt !== undefined) {
        toast.success("Logged In", {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: false,
        });
        localStorage.setItem(tokenKey, jwt); // storing JWT in local storage to  use it for authentication
      }
    });
}

export function loginWithJWT(jwt) {
  localStorage.setItem(tokenKey, jwt);
}

export function logout() {
  localStorage.removeItem(tokenKey);
  localStorage.removeItem("persist:root");
}

export function getCurrentUser() {
  try {
    const jwt = localStorage.getItem(tokenKey);
    return jwtDecode(jwt);
  } catch (ex) {
    return null;
  }
}

export function getJWT() {
  return localStorage.getItem(tokenKey);
}

export default {
  login,
  loginWithJWT,
  logout,
  getCurrentUser,
  getJWT,
};
