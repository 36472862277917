import React, { Component } from "react";
import { connect } from "react-redux";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { getFormattedDate, extractTime } from "../../../utils/myDateTime";

import {
  updateTotalCollection,
  empty_orders_list,
  getOrderBill,
} from "../../../store/ordersDux";
import USBPrintReceipt from "./USBPrintOrder";
import BLEPrintReceipt from "./BLEPrintOrder";
import _ from "lodash";

let BLEDevice = null;
let BLEDeviceState = true; //BLEDeviceState=isDisconnected
class Orders extends Component {
  constructor(props) {
    super();
  }
  state = {
    pageSize: 15,
    currentPage: 1,
    allOrders: [],
    columns: ["ID", "CASENUMBER", "NAME", "DEBT", "PAID", "TIME", "PRINT"],
    columnWidth: [70, 120, 160, 60, 100, 90, 75],
    orders: [],
    orderToPrint: null,
    printOrderData: null,
  };

  componentWillReceiveProps(props) {
    if (props.orders.list.length > 0) {
      this.setState({ allOrders: props.orders.list });

      //console.log(this.state.allItems);
    }
  }

  async componentDidMount() {
    //EMPTY Orders list on every new day
    const d = new Date();
    if (this.props.orders.list.length > 0)
      if (
        getFormattedDate(this.props.orders.list[0].order_date) !==
        getFormattedDate(d)
      )
        this.props.empty_orders_list();
  }

  setBLEDevice = (device) => {
    console.log(">Set device");
    BLEDevice = device;
    console.log(device);
    console.log(BLEDevice);
    console.log("<Set device");
    if (device) this.setBLEDeviceState(false);
  };
  getBLEDevice = () => {
    console.log("getBLEDevice>>");
    console.log(BLEDevice);
    return BLEDevice;
  };
  setBLEDeviceState = (state) => {
    console.log(">Set device state");
    BLEDeviceState = state;
    console.log(state);
    console.log(BLEDeviceState);
    console.log("<Set device state");
  };
  getBLEDeviceState = () => {
    console.log("getBLEDeviceState>>");
    console.log(BLEDeviceState);
    return BLEDeviceState;
  };
  PRINT = () => (
    // <USBPrintReceipt
    //   printOrderData={this.state.orderToPrint}
    //   orderPrinted={this.orderPrinted}
    //   style={{
    //     cursor: "pointer",
    //     onMouseOver: "this.style.color = 'red'",
    //   }}
    // />
    <BLEPrintReceipt
      printOrderData={this.state.orderToPrint}
      orderPrinted={this.orderPrinted}
      device={this.getBLEDevice}
      setDevice={this.setBLEDevice}
      isDisconnected={this.getBLEDeviceState}
      setIsDisconnected={this.setBLEDeviceState}
      style={{
        cursor: "pointer",
        onMouseOver: "this.style.color = 'red'",
      }}
    />
  );

  orderPrinted = () => {
    this.setState({ orderToPrint: null });
  };

  render() {
    let orders = [];
    Object.assign(orders, this.props.orders.list);
    const len = orders.length;

    if (len === 0 || this.props.patients === undefined)
      return (
        <div style={{ padding: "1em", zoom: 0.66 }}>
          <h5>
            <b>No Orders To Show, Please Create.</b>
          </h5>
        </div>
      );
    let orderList = orders.reverse();

    const PRINT = this.PRINT();
    orderList = orderList.map((d) => {
      const orderOfPatient = this.props.patients.find((p) => p.name === d.name);
      if (orderOfPatient === undefined)
        return {
          ID: d.order_id,
          CASENUMBER: "NOT FOUND",
          NAME: d.name,
          DEBT: `₹`,
          PAID: `₹${d.paid_amount}`,
          TIME: extractTime(d.order_date),
          PRINT,
        };
      d = {
        ID: d.order_id,
        CASENUMBER: orderOfPatient.case_number,
        NAME: d.name,
        DEBT: `₹${orderOfPatient.debt}`,
        PAID: `₹${d.paid_amount}`,
        TIME: extractTime(d.order_date),
      };
      return {
        ...d,
        PRINT,
      };
    });
    //(i.order_date)
    let totalCollection = this.props.orders.list.reduce((cartTotal, order) => {
      return cartTotal + order.paid_amount;
    }, 0);
    let doctorsCollection = this.props.orders.list.reduce((total, order) => {
      return total + order.doctor_fees;
    }, 0);
    // let pharmacyCollection = this.props.orders.list.reduce((pharmacyTotal, order) => {
    //   return pharmacyTotal + order.cart_total;
    // }, 0);
    this.props.updateTotalCollection(totalCollection);
    let columns = [
      ...this.state.columns.map((c, i) => ({
        Header: () => <b>{c}</b>,
        accessor: c,
        show: true,
        style: { textAlign: "center" },
        width: this.state.columnWidth[i], //getColumnWidth(filteredPatients, `${c}`, `${c}`),
      })),
    ];

    return (
      <div style={{ padding: "1em", zoom: 0.66 }}>
        <div>
          <h5>
            <b>Previous Orders.</b>
          </h5>
        </div>
        <ReactTable
          id="OrdersRef"
          data={orderList}
          columns={columns}
          className="-striped -highlight"
          onPageChange={this.onPageChange}
          showPaginationTop={false}
          showPaginationBottom={true}
          defaultPageSize={5}
          defaultSorted={[{ id: "ID", desc: true }]}
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              onClick: (e, handleOriginal) => {
                if (rowInfo !== undefined) {
                  if (column.id === "PRINT") {
                    let orderToPrint = rowInfo.original;
                    //get order bill from server, if not in redux store
                    this.props.getOrderBill(orderToPrint.ID);
                    delete orderToPrint.PRINT;
                    this.setState({ orderToPrint });
                  }
                }
                if (handleOriginal) {
                  handleOriginal();
                }
              },
            };
          }}
        />

        <div>
    
        <table className="table">
        <tbody>


          <tr>
            <td style={{ textAlign: "left" }}><b>Pharmacy Collection:</b></td>
            <td style={{ textAlign: "right" }}><b>₹{totalCollection-doctorsCollection}</b></td>
          </tr>
          <tr>
            <td style={{ textAlign: "left" }}><b>Doctors Collection:</b></td>
            <td style={{ textAlign: "right" }}><b>₹{doctorsCollection}</b></td>
          </tr>
          <tr>
            <td style={{ textAlign: "left" }}><b>Total Collection:</b></td>
            <td style={{ textAlign: "right" }}><b>₹{totalCollection}</b></td>
          </tr>
          </tbody>
        </table>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    orders: state.entities.orders,
    patients: state.entities.patients.list,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateTotalCollection: (total) => dispatch(updateTotalCollection(total)),
    empty_orders_list: () => dispatch(empty_orders_list()),
    getOrderBill: (orderID) => dispatch(getOrderBill(orderID)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Orders);
