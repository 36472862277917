import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { apiCallBegan } from "./api";
import moment from "moment";
import { toast } from "react-toastify";
import { reduceQTYOnOrderCreate } from "./itemsDux";

//For Memorization
import { createSelector } from "reselect";
import BillFormat from "../components/Dashboard/Cart/BillFormat";
import { getCurrentDateWithSlash } from "../utils/myDateTime";
import { loadingStateCheck } from "../components/common/loadingStateCheck";

//using slice is much more easier than creationg actions and reeducers saperately
const slice = createSlice({
  name: "orders",
  initialState: {
    printerDevice: null,
    orderID: 0,
    list: [],
    orderItemsList: [],
    doctorOrderItems: [],
    loading: false,
    lastFetch: null,
    lastOrderPrinted: false,
    lastOrder: {},
    billReceipts: [],
    patientsLastOrder: {
      list: [],
      loading: false,
    },

    loadingOrderCreate: false,
    orderCreateSuccess: false,

    loadingStoreBills: false,
    StoreBillsSuccess: false,

    totalCollection: 0,
    count_orderCreate: 0,
    count_orderCreateCurrentPatient: 0,
    count_patientOrderCreatedStatus: 0,
  },
  reducers: {
    // it uses mmer internally so we can write not worry about immutable coding

    increase_orderCreate: (orders, action) => {
      orders.count_orderCreate = orders.count_orderCreate + 1;
    },
    increase_orderCreateCurrentPatient: (orders, action) => {
      orders.count_orderCreateCurrentPatient =
        orders.count_orderCreateCurrentPatient + 1;
    },
    increase_patientOrderCreatedStatus: (orders, action) => {
      orders.count_patientOrderCreatedStatus =
        orders.count_patientOrderCreatedStatus + 1;
    },

    ordersRequested: (orders, action) => {
      orders.loading = true;
    },
    ordersRequestFailed: (orders, action) => {
      if (moment(orders.lastFetch).date() !== moment().date()) {
        orders.orderID = 0;
        orders.list = [];
        orders.orderItemsList = [];
        orders.billReceipts = [];
        orders.lastOrder = {};
        orders.totalCollection = 0;
        console.log("Clearing orders list: ");
      }
      orders.loading = false;
    },

    ordersReceived: (orders, action) => {
      orders.list = action.payload;
      orders.loading = false;
      orders.lastFetch = Date.now(); // Current time as a timestamp
    },

    billsRequested: (orders, action) => {
      orders.loadingbills = true;
    },
    billsRequestFailed: (orders, action) => {
      orders.loadingbills = false;
    },

    billsReceived: (orders, action) => {
      orders.billReceipts = [...orders.billReceipts, action.payload];
      orders.loadingbills = false;
    },

    patientsLastOrderRequested: (orders, action) => {
      orders.patientsLastOrder.loading = true;
    },
    patientsLastOrderRequestFailed: (orders, action) => {
      orders.patientsLastOrder.loading = false;
    },

    patientsLastOrderReceived: (orders, action) => {
      orders.patientsLastOrder.list = mapCartItems(action.payload);
      orders.patientsLastOrder.loading = false;
      //update cart with previous order
      console.log("LAST ORDER OF PATIENT");
      console.log(orders.patientsLastOrder.list);
    },
    orderCreateStart: (orders, action) => {
      orders.loadingOrderCreate = true;
      orders.orderCreateSuccess = false;
    },

    orderCreateSuccess: (orders, action) => {
      orders.orderID = action.payload.order_id;
      orders.list.push({
        ...action.payload,
      });
      orders.lastOrder = action.payload;
      orders.lastOrderPrinted = false;

      toast.success(
        `Order Created Successfully. ID #${action.payload.order_id}`,
        { autoClose: 2000 }
      );

      orders.loadingOrderCreate = false;
      orders.orderCreateSuccess = true;
    },
    orderCreateFailed: (orders, action) => {
      toast.error("Error While Creating New Order");
      orders.loadingOrderCreate = false;
      orders.orderCreateSuccess = false;
    },

    orderItemsRequestFailed: (orders, action) => {
      toast.error("Error While Fetching Order Data");
    },

    orderItemsReceived: (orders, action) => {
      orders.doctorOrderItems = action.payload;
    },
    orderIsPrinted: (orders, action) => {
      orders.lastOrderPrinted = orders.lastOrderPrinted ? false : true;
    },
    addOrderItems: (orders, action) => {
      orders.loadingOrderCreate = true;
      console.log("addOrderItems >>");
      console.log(action.payload);
      orders.orderItemsList.push(action.payload);
    },
    printerDeviceSet: (orders, action) => {
      orders.printerDevice = action.payload;
    },
    updatedTotalCollection: (orders, action) => {
      orders.totalCollection = action.payload;
    },
    orderListEmptied: (orders, action) => {
      orders.orderID = 0;
      orders.list = [];
      orders.orderItemsList = [];
      orders.billReceipts = [];
      orders.lastOrder = {};
      orders.totalCollection = 0;
      console.log("Clearing orders list: ");
    },
    storeBillsStart: (orders, action) => {
      orders.loadingStoreBills = true;
      orders.StoreBillsSuccess = false;
    },
    storeBillsSuccess: (orders, action) => {
      const billsObject = action.payload;
      orders.billReceipts.push(billsObject);
      orders.loadingStoreBills = false;
      orders.StoreBillsSuccess = true;
    },
    storeBillsError: (orders, action) => {
      orders.loadingStoreBills = false;
      orders.StoreBillsSuccess = false;
    },

    clearOrderErrors: (orders, action) => {
      orders.loadingOrderCreate = false;
      orders.orderCreateSuccess = false;

      orders.loadingStoreBills = false;
      orders.StoreBillsSuccess = false;
    },
  },
});
const {
  increase_orderCreate,
  increase_orderCreateCurrentPatient,
  increase_patientOrderCreatedStatus,
  ordersRequested,
  ordersReceived,
  ordersRequestFailed,
  billsRequested,
  billsReceived,
  billsRequestFailed,
  patientsLastOrderRequested,
  patientsLastOrderReceived,
  patientsLastOrderRequestFailed,
  orderCreateStart,
  orderCreateSuccess,
  orderCreateFailed,
  orderItemsReceived,
  orderItemsRequestFailed,
  orderIsPrinted,
  addOrderItems,
  printerDeviceSet,
  updatedTotalCollection,
  orderListEmptied,
  storeBillsStart,
  storeBillsSuccess,
  storeBillsError,
  clearOrderErrors,
} = slice.actions;
export default slice.reducer;

const url = "/orders";
//Action Creators

export const increaseOrderCreate = () => (dispatch, getState) =>
  dispatch(increase_orderCreate);
export const increaseOrderCreateCurrentPatient = () => (dispatch, getState) =>
  dispatch(increase_orderCreateCurrentPatient);
export const increasePatientOrderCreatedStatus = () => (dispatch, getState) =>
  dispatch(increase_patientOrderCreatedStatus);

export const getTodaysOrders = (date) => (dispatch, getState) => {
  // const { lastFetch } = getState().entities.orders;
  // const diffInMinutes = moment().diff(moment(lastFetch), "minutes");
  // if (diffInMinutes < 1) return;
  dispatch(
    apiCallBegan({
      url: url + "/" + date,
      onStart: ordersRequested.type,
      onSuccess: ordersReceived.type, //"orders/ordersReceived",
      onError: ordersRequestFailed.type,
    })
  );
};
export const getOrderBill = (orderID) => (dispatch, getState) => {
  const bill = getState().entities.orders.billReceipts.find(
    (bill) => bill.orderID === orderID
  );
  console.log("ReduxBill>>");
  console.log(bill);
  if (bill) return;
  // get order bills
  dispatch(
    apiCallBegan({
      url: url + "/bills/" + orderID,
      onStart: billsRequested.type,
      onSuccess: billsReceived.type, //"bills/billsReceived",
      onError: billsRequestFailed.type,
    })
  );
};

export const getOrderItems = (orderID) => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url: url + "/" + orderID,
      onSuccess: orderItemsReceived.type, //"orders/ordersReceived",
      onError: orderItemsRequestFailed.type,
    })
  );
};

export const getpatientsLastOrder = (caseNumber) => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url: url + "/lastOrder/" + caseNumber,
      onStart: patientsLastOrderRequested.type,
      onSuccess: patientsLastOrderReceived.type,
      onError: patientsLastOrderRequestFailed.type,
    })
  );
};

//Action Creators
export const createOrder = (order) => (dispatch, getState) => {
  dispatch(addOrderItems(order));
  let orderWithoutPrevDebt = { ...order };
  delete orderWithoutPrevDebt.previousDebit;
  // setTimeout(() => {
  dispatch(reduceQTYOnOrderCreate(orderWithoutPrevDebt.orderItems));
  dispatch(
    apiCallBegan({
      url,
      method: "post",
      data: orderWithoutPrevDebt,
      onStart: orderCreateStart.type,
      onSuccess: orderCreateSuccess.type, //"items/itemsReceived",
      onError: orderCreateFailed.type,
    })
  );
  // }, 5000);
};

export const printedOrder = () => (dispatch, getState) => {
  dispatch(orderIsPrinted());
};
export const updatePrinterDevice = (device) => (dispatch, getState) => {
  dispatch(printerDeviceSet(device));
};
export const updateTotalCollection = (total) => (dispatch, getState) => {
  dispatch(updatedTotalCollection(total));
};
export const empty_orders_list = () => (dispatch, getState) => {
  dispatch(orderListEmptied());
};

//mapCartItems
const mapCartItems = (lastOrder) => {
  const mappedLastOrder = lastOrder.map((oi) => {
    return {
      ID: oi.item_id,
      NAME: oi.item_name,
      CONTENT: oi.item_content,
      RACK_LOCATION: oi.item_rack_location,
      STOCK_LOCATION: oi.item_stock_location,
      TYPE: oi.item_type,
      MANUFACTURER: oi.item_manufacturer,
      BATCH_NO: oi.item_batch_no,
      EXP: oi.item_exp,
      VENDOR: oi.item_vendor,
      STRIPS_IN_BOX: oi.strips_in_box,
      TABLETS_IN_ONE_STRIP: oi.tablets_in_one_strip,
      QTY: oi.item_qty,
      REORDER_QTY: oi.item_reorder_qty,
      MRP: oi.item_mrp,
      PURCHASE_PRICE: oi.item_purchase_price,
      SELLING_PRICE: oi.item_selling_price,
      CREATION_DATE: oi.item_creation_date,
      ORDER_QTY: oi.order_qty,
    };
  });
  return mappedLastOrder;
};

//getItem
const getItem = (ID, allItemsList) => {
  let index = allItemsList.findIndex((ai) => ai.item_id === parseInt(ID));
  let item = allItemsList[index];
  return item;
};
//get string of all items from order
const getOrderPrintStr = (orderItems, allItemsList) => {
  if (orderItems !== undefined) {
    let orderPrintString = orderItems.reduce((finalStr, oi) => {
      const item = getItem(oi.ID, allItemsList);
      const name = item.item_name.trim();
      const TABLETS_IN_ONE_STRIP = item.tablets_in_one_strip;
      const str = `${name.padEnd(15)}     ${oi.ORDER_QTY.toString().padStart(
        3
      )}  ${(oi.PPU * oi.ORDER_QTY).toFixed(1).padStart(7)}#\n`;
      const stratchedStr = `${name.substr(
        0,
        15
      )}     ${oi.ORDER_QTY.toString().padStart(3)}  ${(oi.PPU * oi.ORDER_QTY)
        .toFixed(1)
        .padStart(7)}#\n${name.substr(15, name.length)}#\n`;
      const moreStratchedStr = `${name.substr(
        0,
        15
      )}     ${oi.ORDER_QTY.toString().padStart(3)}  ${(oi.PPU * oi.ORDER_QTY)
        .toFixed(1)
        .padStart(7)}#\n${name.substr(15, 15)}#\n${name.substr(
        30,
        name.length
      )}#\n`;
      finalStr = finalStr.concat(
        name.length < 16
          ? str
          : name.length < 31
          ? stratchedStr
          : moreStratchedStr
      );
      return finalStr;
    }, "");
    return orderPrintString;
  } else return "UNDEFINED";
};

//once order is created, patient status is updated, create a bill receipts
export const generateBillReceipts = () => (dispatch, getState) => {
  const Store = getState().entities;
  const ordersStore = Store.orders;
  let d = getCurrentDateWithSlash();
  const date = d.substr(8, 2) + d.substr(4, 4) + d.substr(0, 4);
  const orderID = ordersStore.orderID;

  const orderToPrint = ordersStore.list.find((o) => o.order_id === orderID);
  console.log("orderToPrint >>");
  console.log(orderToPrint);
  const patientName = orderToPrint.name;
  const caseNumber = orderToPrint.case_number;
  const doctorsBill = orderToPrint.doctor_fees;
  const orderItemsToPrint = ordersStore.orderItemsList
    .filter((oil) => oil.patientID === caseNumber)
    .slice(-1)[0]; //this is to access last element from the list of orderItems, we just need the latest order for the casenumber

  if (orderItemsToPrint === undefined) return;
  const cartTotal = orderItemsToPrint.cartTotal;
  const previousDebit = orderItemsToPrint.previousDebit;
  const orderOfPatient = Store.patients.list.find(
    (p) => p.case_number === caseNumber
  );
  if (orderOfPatient === undefined)
    return toast.error("Invalid Patient, Select a new Patient");
  const currentDebit = orderOfPatient.debt; //read from store

  const allItemsList = getState().entities.items.list;
  let orderItems = orderItemsToPrint.orderItems;
  let orderPrintString = getOrderPrintStr(orderItems, allItemsList);

  const itemsBill = BillFormat.getItemsBill(
    date,
    orderID,
    patientName,
    orderPrintString,
    orderItems.length,
    cartTotal,
    doctorsBill,
    previousDebit,
    currentDebit
  );
  const regularBill = BillFormat.getRegularBill(
    date,
    orderID,
    patientName,
    caseNumber,
    doctorsBill,
    cartTotal,
    previousDebit,
    currentDebit
  );

  console.log(regularBill);
  console.log(itemsBill);

  const billsObject = {
    orderID,
    regularBill,
    itemsBill,
  };

  dispatch(storeBills(billsObject));
};

// store bills in db
export const storeBills = (billsObject) => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url: url + "/bills/" + billsObject.orderID,
      method: "post",
      data: { billObj: billsObject },
      onStart: storeBillsStart.type,
      onSuccess: storeBillsSuccess.type, //"items/itemsReceived",
      onError: storeBillsError.type,
    })
  );
};

export const clearErrors = () => (dispatch, getState) => {
  dispatch(clearOrderErrors());
};
//Selectors

// export const getSelectedorders = (state, status) =>
//   state.entities.orders.filter((p) => p.state === status);

export const getSelectedorders = (status) =>
  createSelector(
    (state) => state.entities.orders,
    (orders) => orders.filter((p) => p.state === status)
  );

//selector on slice for loading variables
export const Orders = () => useSelector((state) => state.entities.orders);
//sets page loading indicator, for async requests, relies on loading variables in state
export const LoadingOrder = () => loadingStateCheck(Orders() | []);
