import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { apiCallBegan } from "./api";
import { getCurrentDate } from "../utils/myDateTime";
import moment from "moment";
import { loadingStateCheck } from "../components/common/loadingStateCheck";

//For Memorization
import { createSelector } from "reselect";
import { toast } from "react-toastify";
//using slice is much more easier than creationg actions and reeducers saperately
const slice = createSlice({
  name: "patients",
  initialState: {
    list: [],
    filteredList: [],
    loading: false,
    filteredLoading: false,
    lastFetch: null,
    currentPatient: {},
    previousPatient: {},
    patientExist: false,
    existingPatient: {},
    nextCaseNumber:
      getCurrentDate().slice(6, 8) +
      getCurrentDate().slice(4, 6) +
      getCurrentDate().slice(2, 4) +
      (1).toString().padStart(4, "0"),

    loadingUpdatePatient: false,
    updatePatientStatusSuccess: false,
  },
  reducers: {
    // it uses mmer internally so we can write not worry about immutable coding

    patientsRequested: (patients, action) => {
      patients.loading = true;
    },
    patientsRequestFailed: (patients, action) => {
      if (moment(patients.lastFetch).date() !== moment().date()) {
        patients.list = [];
        patients.currentPatient = {};
        patients.previousPatient = {};
        patients.lastFetch = Date.now(); // Current time as a timestamp
      }
      patients.loading = false;
    },

    patientsReceived: (patients, action) => {
      console.log("Inside patients dux: ");
      // console.log(moment(patients.lastFetch).date());
      // console.log(moment().date());
      // if (moment(patients.lastFetch).date() === moment().date()) {
      patients.list = action.payload;
      // } else {
      //   console.log("Clearing patients list: ");
      //   patients.list = [];
      //   patients.list = action.payload;
      // }
      patients.loading = false;
      patients.lastFetch = Date.now(); // Current time as a timestamp

      let lastCNStr = action.payload[action.payload.length - 1].case_number;
      let len = parseInt(lastCNStr) / 1000000000;
      if (
        action.payload[action.payload.length - 1].creation_date ===
        getCurrentDate()
      )
        patients.nextCaseNumber =
          len < 1
            ? "0" + (parseInt(lastCNStr) + 1).toString()
            : (parseInt(lastCNStr) + 1).toString();
    },
    patientAddedToQueue: (patients, action) => {
      let currentDate = getCurrentDate();
      let dateString =
        currentDate.slice(6, 8) +
        currentDate.slice(4, 6) +
        currentDate.slice(2, 4);
      patients.nextCaseNumber =
        patients.nextCaseNumber.slice(0, 6) !== dateString
          ? dateString + (1).toString().padStart(4, "0")
          : patients.nextCaseNumber;

      console.log("date comparision");
      console.log(moment(patients.lastFetch).date());
      console.log(moment().date());

      if (moment(patients.lastFetch).date() === moment().date())
        try {
          console.log("todays date");
          patients.list.push({
            ...action.payload[0][0],
          });
          toast.success("New Patient Add Successful.");
          patients.patientExist = false;

          let lastCNStr = action.payload[0][0].case_number;
          let len = parseInt(lastCNStr) / 1000000000;
          if (action.payload[0][0].creation_date === getCurrentDate())
            patients.nextCaseNumber =
              len < 1
                ? "0" + (parseInt(lastCNStr) + 1).toString()
                : (parseInt(lastCNStr) + 1).toString();

          console.log("action.payload[0][0]");
          console.log(action.payload[0][0]);
        } catch (e) {
          toast.error("New Patient Not added yet");
        }
      else {
        patients.list = [];
        patients.list.push({
          ...action.payload[0][0],
        });

        patients.nextCaseNumber =
          getCurrentDate().slice(6, 8) +
          getCurrentDate().slice(4, 6) +
          getCurrentDate().slice(2, 4) +
          (1).toString().padStart(4, "0");
      }
      // set current patient is OTC
      if (action.payload[0][0].type === "OTC")
        patients.currentPatient = action.payload[0][0];
    },

    patientNotAddedToQueue: (patients, action) => {
      toast.error(`New Patient Add Failed ${JSON.stringify(action.payload)}`);
      patients.patientExist = false;
    },
    patientUpdatedToQueue: (patients, action) => {
      const index = patients.list.findIndex(
        (p) => p.case_number === action.payload.case_number
      );
      //if not in queue then add, if it is then update/replace
      if (index < 0)
        patients.list.push({
          ...action.payload,
        });
      else {
        patients.list[index] = action.payload;
      }
      toast.success(`${action.payload.case_number} updated into queue.`);
    },
    updatePatientStatusStart: (patients, action) => {
      patients.loadingUpdatePatient = true;
      patients.updatePatientStatusSuccess = false;
    },
    updatePatientStatusSuccess: (patients, action) => {
      console.log("at status update :");
      console.log(action.payload);
      if (action.payload.status === "OrderCreated")
        patients.currentPatient = {};

      const index = patients.list.findIndex(
        (p) => p.case_number == action.payload.case_number
      );

      console.log(index);
      console.log(patients.list[index]);
      patients.list[index] = action.payload;
      console.log(patients.list);

      toast.info("Status Changed to " + action.payload.status, {
        autoClose: 2000,
      });
      patients.loadingUpdatePatient = false;
      patients.updatePatientStatusSuccess = true;
    },

    updatePatientStatusError: (patients, action) => {
      toast.error(
        `Patient Status Update Failed ${JSON.stringify(action.payload)}`
      );
      patients.loadingUpdatePatient = false;
      patients.updatePatientStatusSuccess = false;
    },
    patientRemovedFromQueue: (patients, action) => {
      patients.filter((p) => p.case_number !== action.payload.case_number);
    },
    updatedCurrentPatient: (patients, action) => {
      patients.currentPatient = action.payload;
    },
    updatedPreviousPatient: (patients, action) => {
      patients.previousPatient = action.payload;
    },
    checkingIfPatientExist: (patients, action) => {
      patients.patientExist = "checking";
    },
    patientExist: (patients, action) => {
      patients.patientExist = true;
      patients.existingPatient = action.payload[0];
      toast.info(
        `Casenumber ${patients.existingPatient.case_number} Selected`,
        { autoClose: 2000 }
      );
    },
    patientExistError: (patients, action) => {
      patients.patientExist = false;
    },
    filteredPatientsRequested: (patients, action) => {
      patients.filteredLoading = true;
    },
    filteredPatientsRequestFailed: (patients, action) => {
      patients.filteredLoading = false;
      patients.filteredList = [];
    },
    filteredPatientsReceived: (patients, action) => {
      patients.filteredList = action.payload;
      patients.filteredLoading = false;
    },
  },
});
const {
  patientsRequested,
  patientsReceived,
  patientsRequestFailed,
  patientAddedToQueue,
  patientNotAddedToQueue,
  updatePatientStatusStart,
  updatePatientStatusSuccess,
  updatePatientStatusError,
  patientRemovedFromQueue,
  updatedCurrentPatient,
  updatedPreviousPatient,
  patientExist,
  patientExistError,
  checkingIfPatientExist,
  patientUpdatedToQueue,
  filteredPatientsRequested,
  filteredPatientsReceived, //"patients/patientsReceived",
  filteredPatientsRequestFailed,
} = slice.actions;
export default slice.reducer;

//Action Creators
const url = "/patients";

export const checkPatientExist = (caseNumber) => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url: url + "/" + caseNumber,
      onStart: checkingIfPatientExist.type,
      onSuccess: patientExist.type, //"patients/patientsReceived",
      onError: patientExistError.type,
    })
  );
};
export const addExistingPatient = (patient) => (dispatch, getState) => {
  dispatch(patientExist([patient, 0]));
};
export const removeExistedPatient = () => (dispatch, getState) => {
  dispatch(patientExistError());
};

export const getPatientsInQueue = (date) => (dispatch, getState) => {
  // const { lastFetch } = getState().entities.patients;
  // const diffInMinutes = moment().diff(moment(lastFetch), "minutes");
  // if (diffInMinutes < 1) return;
  console.log("Getting Patients list in queue");
  dispatch(
    apiCallBegan({
      url: url + "/" + date,
      onStart: patientsRequested.type,
      onSuccess: patientsReceived.type, //"patients/patientsReceived",
      onError: patientsRequestFailed.type,
    })
  );
};
export const getFilteredPatients = (filter) => (dispatch, getState) => {
  // const { lastFetch } = getState().entities.patients;
  // const diffInMinutes = moment().diff(moment(lastFetch), "minutes");
  // if (diffInMinutes < 1) return;
  dispatch(
    apiCallBegan({
      url,
      method: "post",
      data: filter,
      onStart: filteredPatientsRequested.type,
      onSuccess: filteredPatientsReceived.type, //"patients/patientsReceived",
      onError: filteredPatientsRequestFailed.type,
    })
  );
};
export const addNewPatient = (patient) =>
  apiCallBegan({
    url,
    method: "post",
    data: patient,
    onSuccess: patientAddedToQueue.type, //"items/itemsReceived",
    onError: patientNotAddedToQueue.type,
  });

export const updatePatientStatus = (patient) =>
  apiCallBegan({
    url: url + "/" + patient.case_number,
    method: "put",
    data: patient,
    onStart: updatePatientStatusStart.type, //"items/itemsReceived",
    onSuccess: updatePatientStatusSuccess.type, //"items/itemsReceived",
    onError: updatePatientStatusError.type,
  });

export const updateCurrentPatient = (patient) => (dispatch, getState) => {
  dispatch(updatedCurrentPatient(patient));
};
export const updatePreviousPatient = (patient) => (dispatch, getState) => {
  dispatch(updatedPreviousPatient(patient));
};
export const addPatientToQueue = (patient) => (dispatch, getState) => {
  dispatch(patientUpdatedToQueue(patient));
};

//Selectors

// export const getSelectedPatients = (state, status) =>
//   state.entities.patients.filter((p) => p.state === status);

export const getSelectedPatients = (status) =>
  createSelector(
    (state) => state.entities.patients,
    (patients) => patients.filter((p) => p.state === status)
  );
export const getCurrentPatient = () =>
  createSelector(
    (state) => state.entities.patients,
    (patients) => patients.currentPatient
  );

export const AllPatients = () =>
  useSelector((state) => state.entities.patients.list);

export const CurrentPatient = () =>
  useSelector((state) => state.entities.patients.currentPatient);

//selector on slice for loading variables
export const Patients = () => useSelector((state) => state.entities.patients);
//sets page loading indicator, for async requests, relies on loading variables in state
export const LoadingPatients = () => loadingStateCheck(Patients());
