import React from "react";
import PropTypes from "prop-types";
const Input = ({ name, label, ...rest }) => {
  return (
    <div className="form-group">
      <label htmlFor={name}>
        <b>{label}</b>
      </label>
      <input
        autoFocus
        // we are spreading type,value, onChange here. ...rest includes any property other then name,label, error
        {...rest}
        name={name}
        id={name}
        className="form-control"
      />
    </div>
  );
};

/* Zen Code:   form>(div.form-group>label+input.form-control)*2 */

Input.propTypes = {
  type: PropTypes.string.isRequired, // e.g: text/ password
  name: PropTypes.string.isRequired, // e.g: username/ password
  label: PropTypes.string, // this will be used to display errors
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired, // tracks changes goes with value property
  value: PropTypes.string, //to store data
  error: PropTypes.string, // has all errors if any occurs otherwise its an empty {}
};
export default React.memo(Input);
