import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import RtClock from "../common/RtClock";

const Navbar = ({ user }) => (
  <nav
    className="navbar fixed-top"
    style={{
      flexDirection: "row",
      backgroundColor: "#4169E1",
    }}
  >
    <NavLink
      className="navbar-brand"
      style={{ padding: 0, color: "#000066" }}
      to="/"
    >
      <span>
        <h4>
          {/* Display facility name if registered */}
          <b>Gayatri Hospital</b>
        </h4>
      </span>
    </NavLink>
    {user === null && (
      <React.Fragment>
        {/* Use Link/NavLink instead of anchor tag to avoid unnessesory server requests */}

        <NavLink
          classnames="nav-item nav-link"
          to="/login"
          style={{ padding: 0, color: "#000066" }}
        >
          LogIn
        </NavLink>
        <NavLink
          classnames="nav-item nav-link"
          style={{ padding: 0, color: "#000066" }}
          to="/register"
        >
          Register
        </NavLink>
      </React.Fragment>
    )}
    {user !== null && (
      <React.Fragment>
        <NavLink
          classnames="nav-item nav-link"
          to="/profile"
          style={{ padding: 0, color: "#000066" }}
        >
          {user._id}
        </NavLink>
        <NavLink
          classnames="nav-item nav-link"
          style={{ padding: 0, color: "#000066" }}
          to="/logout"
        >
          LogOut
        </NavLink>
      </React.Fragment>
    )}
    <div className="dateTime">
      <RtClock />
    </div>
    {/* <NavLinkButton /> */}
  </nav>
);

export default Navbar;
