const patients = [
  {
    case_number: "2020030001",
    name: "Terminator",
    type: "Regular",
    creation_date: "",
    arrival_time: "NA",
    status: "",
  },
  {
    case_number: "2020030002",
    name: "Die Hard",
    type: "Regular",
    creation_date: "",
    arrival_time: "NA",
    status: "",
  },
  {
    case_number: "2020030003",
    name: "Get Out",
    type: "Emergency",
    creation_date: "",
    arrival_time: "NA",
    status: "",
  },
  {
    case_number: "2020030004",
    name: "Trip to Italy",
    type: "Regular",
    creation_date: "",
    arrival_time: "NA",
    status: "",
  },
  {
    case_number: "2020030005",
    name: "Airplane",
    type: "Emergency",
    creation_date: "",
    arrival_time: "NA",
    status: "",
  },
  {
    case_number: "2020030006",
    name: "Wedding Crashers",
    type: "Regular",
    creation_date: "",
    arrival_time: "NA",
    status: "",
  },
  {
    case_number: "2020030007",
    name: "Gone Girl",
    type: "Regular",
    creation_date: "",
    arrival_time: "NA",
    status: "",
  },
  {
    case_number: "2020030008",
    name: "The Sixth Sense",
    type: "Emergency",
    creation_date: "",
    arrival_time: "NA",
    status: "",
  },
  {
    case_number: "2020030009",
    name: "The Avengers",
    type: "Regular",
    creation_date: "",
    arrival_time: "NA",
    status: "",
  },

  {
    case_number: "2020030010",
    name: "Airplane",
    type: "Emergency",
    creation_date: "",
    arrival_time: "NA",
    status: "",
  },
  {
    case_number: "2020030011",
    name: "Wedding Crashers",
    type: "Regular",
    creation_date: "",
    arrival_time: "NA",
    status: "",
  },
  {
    case_number: "2020030012",
    name: "Gone Girl",
    type: "Emergency",
    creation_date: "",
    arrival_time: "NA",
    status: "",
  },
  {
    case_number: "2020030013",
    name: "The Sixth Sense",
    type: "Regular",
    creation_date: "",
    arrival_time: "NA",
    status: "",
  },
];

export function mapToViewModelInwards(patient) {
  return {
    case_number: patient.caseNumber,
    name: patient.patientName,
    type: patient.patientType,
    creation_date: patient.creationDate,
    arrival_time: patient.arrivalTime,
    status: patient.status,
    mobile_number: patient.mobileNumber,
    age: patient.age,
    weight: patient.weight,
    doctor_fees: patient.doctorFees,
    debt: patient.debt,
  };
}
export function mapToViewModelOutwards(patient) {
  return {
    caseNumber: patient.case_number,
    patientName: patient.name,
    patientType: patient.type,
    creationDate: patient.creation_date,
    arrivalTime: patient.arrivalime,
    status: patient.status,
    mobileNumber: patient.mobile_number,
    age: patient.age,
    weight: patient.weight,
    doctorFees: patient.doctor_fees,
    debt: patient.debt,
  };
}

export function getPatientsList() {
  patients.map((p) => mapToViewModelOutwards(p));
  return patients;
}

export function getPatient(caseNumber) {
  return patients.find((p) => p.case_number === caseNumber);
}

export function savePatient(patient) {
  let patientInDb =
    patients.find((p) => p.case_number === patient.case_number) || {};
  patientInDb.name = patient.name;
  patientInDb.case_number = patient.case_number; //genresAPI.genres.find(g => g.case_number === patient.genreId);
  patientInDb.type = patient.type;

  if (!patientInDb.case_number) {
    patientInDb.case_number = Date.now().toString();
    patients.push(patientInDb);
  }

  return patientInDb;
}

// export function deleteMovie(id) {
//   let patientInDb = patients.find(p => p.case_number === id);
//   patients.splice(patients.indexOf(patientInDb), 1);
//   return patientInDb;
// }
