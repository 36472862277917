import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { updateCurrentPatient } from "./patientsDux";
const slice = createSlice({
  name: "cart",
  initialState: { items: [], total: 0 },
  reducers: {
    add_item_to_cart: (cart, action) => {
      cart.items.push(action.payload);

      cart.total = calculateCartTotal(cart);

      toast.success(
        `Item ${action.payload.NAME} with QTY:${action.payload.ORDER_QTY} added to cart.`,
        { autoClose: 2000 }
      );
    },
    remove_item_from_cart: (cart, action) => {
      try {
        const cartItems = [
          ...cart.items.filter((c) => c.ID !== action.payload.ID),
        ];
        console.log(cartItems);
        console.log(action.payload.ID);
        cart.items = cartItems;
        cart.total =
          parseInt(cart.total) -
          parseInt(action.payload.SELLING_PRICE) *
            parseInt(action.payload.ORDER_QTY);
        toast.success("Cart Updated", { autoClose: 2000 });
      } catch (e) {
        toast.error("Error Removing Cart Item");
      }
    },
    update_item_in_cart: (cart, action) => {
      try {
        const index = cart.items.findIndex((c) => c.ID === action.payload.ID);
        cart.items[index] = action.payload;
        cart.total =
          parseInt(cart.total) +
          parseInt(action.payload.SELLING_PRICE) *
            parseInt(action.payload.ORDER_QTY) -
          parseInt(cart.items[index].SELLING_PRICE) *
            parseInt(cart.items[index].ORDER_QTY);
        toast.success("Cart Updated", { autoClose: 2000 });
      } catch (e) {
        toast.error("Error Updating Cart Item");
      }
    },
    update_total: (cart, action) => {
      cart.total = action.payload;
    },
    emptyCart: (cart, action) => {
      cart.items = [];
      cart.total = 0;
    },
    update_cart: (cart, action) => {
      try {
        cart.items = action.payload;
        cart.total = calculateCartTotal(cart);
        toast.success("Cart Updated", { autoClose: 2000 });
      } catch (e) {
        toast.error("Cart update failed" + e.message);
      }
    },
  },
});

const calculateCartTotal = (cart) => {
  return parseInt(
    cart.items
      .reduce(function myFunc(cartTotal, num) {
        return (
          cartTotal +
          parseInt(num.SELLING_PRICE * num.ORDER_QTY) /
            parseInt(num.TABLETS_IN_ONE_STRIP)
        );
      }, 0)
      .toFixed(2)
  );
};

export const {
  add_item_to_cart,
  remove_item_from_cart,
  update_item_in_cart,
  update_total,
  update_cart,
  emptyCart,
} = slice.actions;
export default slice.reducer;

export const empty_cart = () => (dispatch, getState) => {
  dispatch(emptyCart());
  let patient = {};
  // dispatch(updateCurrentPatient(patient));
  toast.info("cart cleared", { autoClose: 2000 });
};

export const updateCart = (cartItems) => (dispatch, getState) => {
  console.log("At updateCart dispatch");
  console.log(cartItems);
  dispatch(update_cart(cartItems));
};

export const Cart = () => useSelector((state) => state.entities.cart);
