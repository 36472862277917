import React, { Component } from "react";
import _, { entries } from "lodash";
class TableColumnFilter extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    areaExpended: false,
  };

  handleTableDropdownClick = () => {
    this.setState({ areaExpended: this.state.areaExpended ? false : true });
  };
  onShowColumnItemClick = (c) => {
    let checkedColumns = this.props.checkedColumns;
    if (checkedColumns.includes(c)) {
      _.remove(checkedColumns, (ci) => ci === c);
      this.setState({ checkedColumns });
    } else {
      this.setState({ checkedColumns: _.concat(checkedColumns, c) });
    }
  };

  render() {
    const { columns, checkedColumns, updateCheckedColumns } = this.props;
    return (
      <div
        className={`keep-open btn-group ${
          this.state.areaExpended ? "show" : ""
        } `}
        title="Columns"
        style={{ width:"50px", marginLeft:"40px" }}
      >
        <button
          className="btn btn-secondary dropdown-toggle"
          type="button"
          aria-expanded={this.state.areaExpended}
          onClick={() => this.handleTableDropdownClick()}
        >
          <i className="fa fa-th-list"></i>

          <span className="caret"></span>
        </button>
        <div
          className={`dropdown-menu dropdown-menu-left ${
            this.state.areaExpended ? "show" : ""
          } `}
        >
          {columns.map((c) => (
            <label
              key={c.accessor}
              className="dropdown-item dropdown-item-marker"
            >
              <input
                type="checkbox"
                data-field="name"
                value="0"
                checked={checkedColumns.includes(c.accessor) ? "checked" : ""}
                onClick={() => updateCheckedColumns(c.accessor)}
                onChange={() => {}}
              />
              <span>{" " + c.accessor}</span>
            </label>
          ))}
        </div>
      </div>
    );
  }
}

export default React.memo(TableColumnFilter);
