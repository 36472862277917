import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import { toast } from "react-toastify";

const settingsReducer = createSlice({
  name: "settings",
  initialState: {
    facilityDetails: {
      facilityName: "",
      facilityAddress: "",
      facilityPincode: null,
      facilityEmail: "",
      facilityType: "",
      facilityRegistationNumber: null,
      ownerDetails: {
        firstname: "",
        lastname: "",
        username: "",
        password: "",
        email: "",
        accessType: "Admin",
      },
    },
    loadingFacilityDetails: false,
    lastFetchFacilityDetails: null,
    facilitySettings: {
      showSlidingArrows: false,
      tableColumnFilter: [],
      //add toast notification time setting
      //add toast notification position setting
      printOption: "BLE", //USB
    },
    loadingFacilitySettings: false,
    lastFetchFacilitySettings: null,
  },
  reducers: {
    facilityDetailsRequested: (settings, action) => {
      settings.loadingFacilityDetails = true;
    },
    facilityDetailsReceived: (settings, action) => {
      settings.facilityDetails = action.payload; // should get same format object back from db

      settings.loadingFacilityDetails = false;
      settings.lastFetchFacilityDetails = Date.now(); // Current time as a timestamp
    },
    facilityDetailsRequestFailed: (settings, action) => {
      toast.error("Error While Fetching Facility Data");
    },

    facilitySettingsRequested: (settings, action) => {
      settings.loadingFacilitySettings = true;
    },
    facilitySettingsReceived: (settings, action) => {
      settings.facilitySettings = action.payload; // should get same format object back from db

      settings.loadingFacilitySettings = false;
      settings.lastFetchFacilitySettings = Date.now(); // Current time as a timestamp
    },
    facilitySettingsRequestFailed: (settings, action) => {
      toast.error("Error While Fetching Facility Data");
    },
  },
});

export const {
  facilityDetailsRequested,
  facilityDetailsReceived,
  facilityDetailsRequestFailed,
  facilitySettingsRequested,
  facilitySettingsReceived,
  facilitySettingsRequestFailed,
} = settingsReducer.actions;
export default settingsReducer.reducer;

const url = "/facilityDetails";

export const getFacilityDetails = (facilityID) => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url: url + "/" + facilityID,
      onStart: facilityDetailsRequested.type,
      onSuccess: facilityDetailsReceived.type, //"orders/ordersReceived",
      onError: facilityDetailsRequestFailed.type,
    })
  );
};

export const getFacilitySettings = (facilityID) => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url: url + "/" + facilityID + "/settings",
      onStart: facilitySettingsRequested.type,
      onSuccess: facilitySettingsReceived.type, //"orders/ordersReceived",
      onError: facilitySettingsRequestFailed.type,
    })
  );
};
