import React, { Component } from "react";

const MedGroup = (props) => {
  const { items, onGroupSelect, selectedItem } = props;
  return (
    <ul className="list-group" style={{ flexDirection: "row" }}>
      {items.map((i) => (
        <li
          onClick={() => onGroupSelect(i)}
          key={i}
          className={
            i === selectedItem ? "list-group-item active" : "list-group-item"
          }
        >
          {i}
        </li>
      ))}
    </ul>
  );
};

export default React.memo(MedGroup);
