import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { ReactComponent as DeleteItem } from "../../../images/delete-black-48dp.svg";
import DatePicker from "react-datepicker";
import Select from "react-select";

import {
  clear_stock_list,
  getAddStockList,
  remove_stock_from_list,
  update_stock_items_in_list,
  getAllItems,
} from "../../../store/itemsDux";
import History from "./History";
import {
  getStockUpdateHistory,
  updateStock,
  GetVendorNames,
} from "../../../store/stockUpdateDuxm.js";
import auth from "../../../services/authService";
import { toast } from "react-toastify";
import Input from "../../common/input.jsx";

export default function AddStockToList({ item }) {
  const dispatch = useDispatch();

  const [user, setUser] = useState(auth.getCurrentUser());

  const [showPopup, setShowPopup] = useState(false);

  const [showHistory, setShowHistory] = useState(false);
  const allStockItems = useSelector(getAddStockList);

  const [updatedStockItems, setUpdatedStockItems] = useState(allStockItems);
  const [billNumber, setBillNumber] = useState("");
  const [vendorName, setVendorName] = useState("");
  const [newVendorName, setNewVendorName] = useState("");

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  console.log();
  useEffect(() => {
    // update the model if it remove any elemtnt
    setUpdatedStockItems(allStockItems);
  }, [allStockItems]);

  function convertDateToYYYYMMDD(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const handleSubmitStock = () => {
    if (isSubmitDisabled) return;
    // dispatch(update_stock_items_in_list(updatedStockItems));
    console.log("updatedStockItems>>", updatedStockItems);
    console.log("selectedDate>>", convertDateToYYYYMMDD(selectedDate));

    if (!selectedDate) return toast.error("Please select bill date");
    if (!billNumber) return toast.error("Please enter bill number");
    if (!vendorName && !localStorage.getItem("NewVendorName"))
      return toast.error("Please enter vendor name");
    if (!updatedStockItems || updatedStockItems.length < 1)
      return toast.error("Please select bill items");

    let missingField = false;
    let data = updatedStockItems.map((item) => {
      if (!item.NEW_MRP > 0) {
        missingField = true;
        return toast.error(`Please enter valid MRP for item: ${item.NAME}`);
      }
      if (!item.NEW_PURCHASE_PRICE > 0) {
        missingField = true;
        return toast.error(
          `Please enter valid PURCHASE PRICE for item: ${item.NAME}`
        );
      }
      if (!item.ADDTOSTOCKQTY > 0) {
        missingField = true;
        return toast.error(`Please enter valid QTY for item: ${item.NAME}`);
      }
      return {
        item_id: item.ID,
        item_name: item.NAME,
        vendor_name: vendorName || localStorage.getItem("NewVendorName"),
        bill_number: billNumber,
        bill_date: convertDateToYYYYMMDD(selectedDate),
        item_qty_before: item.QTY,
        item_qty_to_add: item.ADDTOSTOCKQTY,
        item_qty_after: JSON.stringify(item.TOTALQTY),
        item_mrp_before: item.MRP,
        item_mrp_after: item.NEW_MRP,
        item_purchase_price_before: item.PURCHASE_PRICE,
        item_purchase_price_after: item.NEW_PURCHASE_PRICE,
      };
    });
    console.log("data");
    console.log(data);
    if (missingField) return;
    setIsSubmitDisabled(true);
    dispatch(updateStock(data));
    // dispatch(update_stock_items_in_list(data));
    setTimeout(() => {
      setShowPopup(false);
      setUpdatedStockItems([]);
      dispatch(clear_stock_list([]));
      setBillNumber("");
      setVendorName("");
      setIsSubmitDisabled(false);
      // window.location.reload();
      dispatch(getAllItems());
    }, 2000);
  };

  const handleUpdateProperty = (event, key, item) => {
    // Validate input to ensure it's a number
    console.log("event", event.target.value, item);
    const updatedStockList = updatedStockItems.map((i) => {
      if (item.ID == i.ID) {
        let updatedItem = { ...i };
        updatedItem[key] = event.target.value;
        if (key == "ADDTOSTOCKQTY")
          updatedItem.TOTALQTY =
            parseInt(updatedItem.ADDTOSTOCKQTY) + parseInt(updatedItem.QTY);
        dispatch(update_stock_items_in_list(updatedItem));
        return updatedItem;
      } else {
        return i;
      }
    });
    setUpdatedStockItems(updatedStockList);
  };

  const removeStockItem = (e, m) => {
    console.log("DELETEDD-----");

    dispatch(remove_stock_from_list(m.ID));

    // hideEditCart();
  };

  // useEffect(() => {
  //   console.log("updatedStockItems>>", updatedStockItems);
  // }, [updatedStockItems]);
  const [selectedDate, setSelectedDate] = React.useState("");
  const handleChange = (date) => {
    console.log("date", date);
    setSelectedDate(date);
    // dispatch(getStockUpdateHistory(convertDateToYYYYMMDD(date)));
  };

  const venderNames = GetVendorNames() || [];

  const vendorOpts = venderNames?.map((vendor) => ({
    value: vendor,
    label: vendor,
  }));

  const [vendorOptions, setVendorOptions] = useState(vendorOpts);

  const filterWithVendorName = (vendorName) => {
    console.log("vendor filter name>>", vendorName);
    console.log("vendor filter name>>", vendorName?.value);

    setVendorName(vendorName?.value);
    localStorage.setItem("NewVendorName", "");

    // return historyData.filter(
    //   (entry) => entry.vendor_name === vendorName.value
    // )
  };

  const handleVendorNameInputChange = (vendorName) => {
    if (!vendorOptions.map((vo) => vo.name).includes(vendorName)) {
      setNewVendorName(vendorName);
      if (vendorName) localStorage.setItem("NewVendorName", vendorName);
      // const newOption = { value: newVendorName, label: newVendorName };
      // setVendorOptions([...vendorOptions, newOption]);
      console.log("vendor select name>>", vendorName);
    } else {
      localStorage.setItem("NewVendorName", "");
      setNewVendorName(null);
    }
  };

  console.log("allStockItem", allStockItems);
  return (
    <div style={{ display: "flex" }}>
      <button
        style={{ marginLeft: "20px" }}
        name="clearSearchBox"
        className="btn btn-primary btn btn"
        onClick={() => setShowPopup(true)}
      >
        Add to Stock
      </button>
      <div
        style={{
          position: "relative",
          right: "15px",
          bottom: "10px",
        }}
      >
        <div
          style={{
            background: "tomato",
            borderRadius: "50px 50px 50px",
            width: "32px",
            textAlign: "center",
          }}
        >
          {allStockItems?.length}
        </div>
      </div>

      <button
        style={{ marginLeft: "20px" }}
        name="history"
        className="btn btn-primary btn btn"
        onClick={() => setShowHistory(true)}
      >
        Stock History
      </button>

      <div>
        <Modal
          backdrop="static"
          keyboard={false}
          className="modal-dialog modal-dialog-centered"
          style={{ maxWidth: "600px" }}
          show={showPopup}
          onHide={() => setShowPopup(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add bills</Modal.Title>
          </Modal.Header>
          {/* input for add bill number */}
          <Modal.Body
            style={{
              position: "relative",
              zoom: "0.75",
              padding: 0,
              overflow: "scroll",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
              }}
            >
              <div
                style={{
                  width: "230px",
                  alignSelf: "center",
                }}
              >
                <label>
                  <b>Bill Date</b>
                </label>
                <DatePicker
                  onChange={handleChange}
                  selected={selectedDate}
                  // placeholderText={convertDateToYYYYMMDD(recentStockUpdateDate)}
                  placeholderText={selectedDate}
                  dateFormat="dd/MM/yyyy"
                  className="form-control"
                />
              </div>
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <Input
                  type="text"
                  label={"Bill Number"}
                  placeholder="Enter bill number"
                  className="form-control"
                  value={billNumber}
                  onChange={(e) => {
                    setBillNumber(e.target.value);
                  }}
                  // style={{ width: "200px", height: "25px", margin: "auto" }}
                  required
                />

                <div
                  className="form-group"
                  style={{
                    width: "230px",
                    alignSelf: "center",
                  }}
                >
                  <label>
                    <b>Vendor Name</b>
                  </label>

                  <Select
                    options={vendorOptions}
                    onFocus={() => handleVendorNameInputChange(null)}
                    placeholder="select vendor name"
                    onChange={filterWithVendorName}
                    value={
                      localStorage.getItem("NewVendorName")
                        ? {
                            label: localStorage.getItem("NewVendorName"),
                            label: localStorage.getItem("NewVendorName"),
                          }
                        : {
                            label: vendorName,
                            label: vendorName,
                          }
                    }
                    // onKeyDown={filterWithVendorName}
                    onInputChange={handleVendorNameInputChange}
                    isClearable={true}
                    isMulti={false}
                  />
                </div>
              </div>
            </div>
            <table
              className="table"
              id="table"
              data-toggle="table"
              data-url="json/data5.json"
              data-height="460"
              data-show-columns="true"
            >
              <thead>
                <tr>
                  <th data-field="sq">SQ</th>
                  <th data-field="id">ID</th>
                  <th data-field="name">NAME</th>
                  <th data-field="VENDOR">VENDOR</th>
                  <th data-field="price">CURRENT MRP</th>
                  <th data-field="price">NEW MRP</th>
                  <th data-field="price">CURRENT PURCHASE PRICE</th>
                  <th data-field="price">NEW PURCHASE PRICE</th>
                  <th data-field="qty">CURRENT QTY</th>
                  <th data-field="qty">QTY TO ADD</th>
                  <th data-field="qty">TOTAL QTY</th>
                  <th data-field="remove-qty">REMOVE</th>
                </tr>
              </thead>
              <tbody>
                {updatedStockItems?.map((m, index) => (
                  <tr key={m.ID}>
                    <td>{index + 1}</td>
                    <td>{m.ID}</td>
                    <td>{m.NAME}</td>
                    <td>{m.VENDOR}</td>
                    <td>{m.MRP}</td>
                    <td>
                      <input
                        type="number"
                        min={1}
                        max={99}
                        value={
                          updatedStockItems.find((i) => i.ID == m.ID)?.NEW_MRP
                        }
                        onChange={(e) => handleUpdateProperty(e, "NEW_MRP", m)}
                        style={{ width: "80px", height: "25px" }}
                      />
                    </td>
                    <td>{m.PURCHASE_PRICE}</td>
                    <td>
                      <input
                        type="number"
                        min={1}
                        max={99}
                        value={
                          updatedStockItems.find((i) => i.ID == m.ID)
                            ?.NEW_PURCHASE_PRICE
                        }
                        onChange={(e) =>
                          handleUpdateProperty(e, "NEW_PURCHASE_PRICE", m)
                        }
                        style={{ width: "80px", height: "25px" }}
                      />
                    </td>
                    <td>{m.QTY}</td>
                    <td>
                      <input
                        type="number"
                        min={1}
                        max={99}
                        value={
                          updatedStockItems.find((i) => i.ID == m.ID)
                            ?.ADDTOSTOCKQTY
                        }
                        onChange={(e) =>
                          handleUpdateProperty(e, "ADDTOSTOCKQTY", m)
                        }
                        style={{ width: "80px", height: "25px" }}
                      />
                    </td>
                    <td>{m.TOTALQTY}</td>
                    <td>
                      <DeleteItem
                        onClick={(e) => removeStockItem(e, m)}
                        style={{
                          cursor: "pointer",
                          onMouseOver: "this.style.color = 'red'",
                          height: "1.5rem",
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={handleSubmitStock}
              disabled={isSubmitDisabled}
            >
              Submit
            </Button>
            <Button variant="secondary" onClick={() => setShowPopup(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <History showHistory={showHistory} setShowHistory={setShowHistory} />
    </div>
  );
}
