import React from "react";
import Form from "../common/form";
import Joi from "joi-browser";
import auth from "../../services/authService";
import * as userService from "../../services/userService";
import { toast } from "react-toastify";

class RegisterPage extends Form {
  state = {
    data: {
      firstname: "",
      lastname: "",
      username: "",
      password: "",
      confirmpassword: "",
      email: "",
      accessType: "Doctor",
    },
    errors: {},

    errorMessage: {
      firstname: "FirstName",
      lastname: "LastName",
      username: "Username",
      password: "Password",
      confirmpassword: "ConfirmPassword",
      email: "Email",
      accessType: "Access Type",
    },
    accessTypes: [
      {
        _id: 1,
        name: "Receptionist",
      },
      {
        _id: 2,
        name: "X-Ray Operator",
      },
      {
        _id: 3,
        name: "Lab Operator",
      },
      {
        _id: 4,
        name: "Pharmasist",
      },
      {
        _id: 5,
        name: "Admin",
      },
    ],
    defaultType: { _id: 0, name: "Doctor" },
    isRequiredFilled: this.state.isRequiredFilled,
    isUsernameUnique: true,
    isSubmitDisabled: false,
  };

  schema = {
    firstname: Joi.string()
      .regex(/^[a-zA-Z]+$/)
      .required(),
    lastname: Joi.string()
      .regex(/^[a-zA-Z]+$/)
      .required(),
    username: Joi.string().alphanum().required(),
    password: Joi.string().alphanum().min(5).required(),
    confirmpassword: Joi.string().required().valid(Joi.ref("password")),
    email: Joi.string().email().allow(""),
    accessType: Joi.string(),
  };

  doSubmit = async () => {
    // call the server, save the changes
    var data = { ...this.state.data };
    data.confirmpassword = "";
    this.setState({ data });

    try {
      this.setState({ isSubmitDisabled: true });
      let user = this.state.data;
      const response = await userService.register(user);
      // console.log("After awaiting registration");
      // console.log(response);
      auth.loginWithJWT(response.headers["x-auth-token"]); // storing JWT in local storage from response header
    } catch (e) {
      // console.log(
      //   "Error Code" + e.response + "Error while reg form submission:      " + e
      // );
      this.setState({ isSubmitDisabled: false });
      if (e.response && e.response.status === 400) {
        //console.log("User Already Registered:    " + e.response.data); // Need to Implement Already registered functionality
        //getting the Error from server
        this.setState({ isUsernameUnique: false }, () =>
          this.renderUsernameTaken()
        );

        return;
      }
    }
    this.setState({ isUsernameUnique: true }, () => {
      this.renderUsernameTaken();
      window.location = "/patients";
    });

    toast.success("New User Add Successful.", {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
    });
  };
  renderUsernameTaken() {
    if (!this.state.isUsernameUnique)
      return (
        <small>
          <p style={{ color: "#DB2B39" }}>{"Username Taken!!!"}</p>
        </small>
      );
    else return "";
  }
  render() {
    return (
      <div className="container">
        <form onSubmit={this.handleSubmit}>
          {this.renderInput("firstname", "FirstName*", "e.g: Happy")}
          {this.renderInput("lastname", "LastName*", "e.g: Patel")}
          {this.renderInput("username", "Username*", "e.g: HappyPatel123")}
          {this.renderInput(
            "password",
            "Password*",
            "alphanumeric of minimum of 5 letters"
          )}
          {this.renderInput(
            "confirmpassword",
            "ConfirmPassword*",
            "should be same as password",
            "password"
          )}
          {this.renderInput("email", "Email", "e.g: happypatel123@gmail.com")}
          {this.renderSelect(
            "accessType",
            "Access Type*",
            this.state.accessTypes,
            this.state.defaultType
          )}
          {/* <p>
            <small></small>
            {this.renderReqirementField("All Fields with * are Required")}
          </p> */}
          {this.renderErrorMessage(this.state.errorMessage)}
          {this.renderUsernameTaken()}
          {this.renderButton(
            "Register",
            "btn btn-primary btn-lg btn-block",
            this.state.isSubmitDisabled
          )}
        </form>
        <div>
          <p>
            Already Have an Account?
            <span>
              <a href="/login"> Login Here.</a>
            </span>
          </p>
        </div>
      </div>
    );
  }
}

export default RegisterPage;
