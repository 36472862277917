import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { ReactComponent as PrinterError } from "../../../images/printReceiptDisabled.svg";
import { ReactComponent as PrinterOk } from "../../../images/printReceipt.svg";
import { printedOrder, updatePrinterDevice } from "../../../store/ordersDux";
import { getCurrentDateWithSlash } from "../../../utils/myDateTime";
import { confirm } from "../../common/confirmationAlert";

class USBPrintOrder extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    connected: false,
    supportsUSB: false,
    isDisconnected: true,
    device: null,
    currentPatientToPrintDebt: "",
  };

  //check if browser supports web usb chrome v61 or above
  componentDidMount() {
    if (navigator.usb) {
      this.setState({ supportsUSB: true });
      navigator.usb.addEventListener("connect", (event) =>
        this.onConnected(event)
      );

      navigator.usb.addEventListener("disconnect", (event) =>
        this.onDisconnected(event)
      );
    } else {
      this.setState({ supportsUSB: false });
    }
  }

  //searches for printer nearby
  createDevice = () => {
    var device;
    if (this.state.device === null || this.state.device === undefined) {
      navigator.usb
        .requestDevice({
          filters: [{ vendorId: 0x0456 }],
        })
        .then((selectedDevice) => {
          device = selectedDevice;
          device
            .open()
            .then(() => device.selectConfiguration(1))
            .then(() => device.claimInterface(0)) //this.claimInterface(device))
            .then(() => {
              this.setState({ isDisconnected: false, device }, () =>
                this.printData(this.state.device)
              );
            });
        })
        .catch((e) => console.error("create device error: " + e.message));
    } else {
      device = this.state.device;
      // toast.info("Device Exist");
      this.printData(device);
    }
  };

  claimInterface = async (device) => {
    for (const config of device.configurations) {
      for (const iface of config.interfaces) {
        if (!iface.claimed) {
          //toast.info("Interface no:" + iface.interfaceNumber);
          await device.claimInterface(iface.interfaceNumber);
          return true;
        }
      }
    }
    return false;
  };

  onDisconnected = (event) => {
    //alert(`The device ${JSON.stringify(event)} is disconnected`);
    toast.error("printer disconnected");
    this.setState({ isDisconnected: true });
    this.props.printedOrder();
    this.props.orderPrinted();
  };

  onConnected = (event) => {
    toast.success(`printer connected`);
    this.setState({ isDisconnected: false });
  };

  printData = (device) => {
    if (
      device !== null &&
      device !== undefined &&
      this.state.isDisconnected !== true
    ) {
      if (this.props.printOrderData === null)
        return toast.error("No Order to print");
      const orderToPrint = this.props.printOrderData;
      console.log("orderToPrint >>");
      console.log(orderToPrint);
      if (orderToPrint.ID === undefined)
        return toast.error("No Order to print");

      const billObject = this.props.orders.billReceipts.find(
        (br) => br.orderID === orderToPrint.ID
      );
      console.log(billObject);
      this.props.orderPrinted();
      let str = "EMPTY\n\n";
      //check which format to print
      let isCancelled = null;
      confirm({
        message: "Choose Print Options.",
        buttons: ["RegularBill", "ItemsBill"],
      }).then(
        ({ button }) => {
          isCancelled = false;
          console.log("Button Pressed: " + button);
          if (button === "RegularBill") str = billObject.regularBill;
          if (button === "ItemsBill") str = billObject.itemsBill;
          if (isCancelled === false) {
            let buffer = new ArrayBuffer(str.length);
            let dataView = new DataView(buffer);

            for (var i = 0; i < str.length; i++) {
              dataView.setUint8(i, str.charAt(i).charCodeAt());
            }
            console.log(str);
            //print usb data here
            //toast.info("printing...");
            // device.controlTransferOut({
            //   requestType: "vendor",
            //   recipient: "endpoint",
            //   request: 0x22,
            //   value: 0x01,
            //   index: 0x02,
            // });
            device
              .transferOut(3, dataView)
              .catch(() => {
                toast.error("printer disconnected");
                this.setState({ isDisconnected: true, device: null });
                navigator.usb.removeEventListener("connect");
                navigator.usb.removeEventListener("disconnect");
                this.props.printedOrder();
                this.props.orderPrinted();
              })
              .then(() => {
                console.log("Printed");
                navigator.usb.removeEventListener("connect");
                navigator.usb.removeEventListener("disconnect");
                this.props.printedOrder();
                this.props.orderPrinted();
              });
          }
        },
        () => {
          //cancel pressed
          isCancelled = true;
          toast.info("print cancelled");
          this.props.printedOrder();
          this.props.orderPrinted();
        }
      );
    } else {
      toast.error("printer disconnected");
      this.setState({ isDisconnected: true, device: null });
      navigator.usb.removeEventListener("connect");
      navigator.usb.removeEventListener("disconnect");
      this.props.printedOrder();
      this.props.orderPrinted();
    }
  };
  getDevices = () => {
    if (this.props.printOrderData === null) {
      console.log("No order to print");
      setTimeout(() => this.getDevices(), 2000);
    } else {
      console.log("Got order to print Object");
      console.log(this.props.printOrderData);
      const ID = this.props.printOrderData.ID;
      let ldevice = this.state.device;
      let isDisconnected = this.state.isDisconnected;
      if (
        ldevice === null ||
        ldevice === undefined ||
        isDisconnected === true
      ) {
        this.props.orderPrinted();
        // const billObject = this.props.orders.billReceipts.find(
        //   (br) => br.orderID === ID
        // );
        // console.log(billObject.itemsBill);
        // console.log(billObject.regularBill);
        this.createDevice();
      } else this.printData(ldevice);
    }
  };
  handleNotSupported = () => {
    toast.error("This Browser does not support USB");
  };

  render() {
    let ldevice = this.state.device; //JSON.parse(localStorage.getItem("printerDevice"));

    return (
      <div style={{ textAlign: "right" }}>
        {this.state.supportsUSB ? (
          this.state.isDisconnected ? (
            <PrinterError className="printIcon" onClick={this.getDevices} />
          ) : (
            <PrinterOk className="printIcon" onClick={this.getDevices} />
          )
        ) : (
          <PrinterError
            onClick={(e) => this.handleNotSupported()}
            className="printIcon unsupported"
          />
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    //device: state.entities.orders.printerDevice,
    orders: state.entities.orders,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    printedOrder: () => dispatch(printedOrder()),
    //updatePrinterDevice: (device) => dispatch(updatePrinterDevice(device)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(USBPrintOrder);
